import React from 'react'
import { connect } from 'react-redux'

import { get, on } from '../../core/clients/restClient'

const connectCharge = Charge => {

	class ConnectCharge extends React.Component {
		state = {
			isLoading: true,
			charge: null,
			error: null
		}

		onCharge = id => {
			if (this.chargeRef) this.chargeRef.off()
			this.chargeRef = on(`/charges/${id}`, charge => {
				this.setState({charge: charge})
			})
		}

		getCharge = id => {
			this.setState({ isLoading: true })
			return get(id, '/charges').then(charge => {
				this.setState({isLoading: false, charge: charge, error: null}, () => {
					this.onCharge(id)
				})
			}).catch(err => {
				this.setState({isLoading: false, charge: null , error: {statusCode: 505} })
			})
		}

		getOrder = id => {
			return get(id, '/orders', ["items", "deliveries", "charges"])
		}

		componentWillUnmount = () => {
			if (this.chargeRef) this.chargeRef.off()
		}

		render = () => <Charge {...this.state} getCharge={this.getCharge} getOrder={this.getOrder} {...this.props} />
	}

	const mapStateToProps = state => ({
		user: state.auth.user,
	})

	return connect(mapStateToProps)(ConnectCharge)
}

export default connectCharge