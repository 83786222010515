import firebase from 'firebase/app'
import 'firebase/functions'

import axios from 'axios'
import { get, on, update, create, remove } from '../../core/clients/restClient'

import settings from '../../settings'

const getPriceUrl = settings.getPriceUrl



export const getCustomer = customerId => {
	return get(customerId, '/customers', ["addresses", "sources"])
}

export const updateCustomer = (customerId, customer) => {
	return update(customerId, '/customers', customer, ["addresses", "sources"])
}

export const getCart = cartId => {
	return get(cartId, '/carts', ["items"])
}

export const getOrder = orderId => {
	return get(orderId, '/orders', ["items", "charges"])
}

//Listen cart change
export const onCart = (cartId, callback) => {
	return on(`/carts/${cartId}`, callback, ["items"])
}

//Listen order change
export const onOrder = (orderId, callback) => {
	return on(`/orders/${orderId}`, callback, ["items", "charges"])
}

//Get the price and check if the item is availlable
export const isAvaillable = (rentItemId, startDate, endDate, quantity, type=null) => {	
	const url=`${getPriceUrl}?rentItemId=${rentItemId}&startDate=${startDate}&endDate=${endDate}&quantity=${quantity}&type=${type}`
	console.log(url)
	return axios.get(url)
}

//Get the Choose price
export const isAvaillableChoose = (rentItemId, startDate, endDate, quantity) => {
	const getPriceChooseFunc = firebase.functions().httpsCallable('cart-getPriceChoose')

	return getPriceChooseFunc({
		rentItemId,
		startDate,
		endDate,
		quantity
	})

}

//Add item in the cart
export const addToCart = (cartId=null, userId, item, storeId=null) => {
	if (cartId) {
		return update(cartId, '/carts', {userId, storeId}).then(cart => create(`/carts/${cartId}/items`, item))
	} else {
		return create(`/carts`, {userId, storeId}).then(cart => {
			return update(userId, '/users', {cartId: cart.id}).then(user => {
				return create(`/carts/${cart.id}/items`, item)
			})
		})
	}
}

//Remove item in the cart
export const removeToCart = (cartId, itemId) => {
	return remove(itemId, `/carts/${cartId}/items/`)
}

//Get delivery amount
export const getDeliveryAmount = (type='pickup', origin=null, destination=null) => {
	
	const getDeliveryAmountFunc = firebase.functions().httpsCallable('order-getDeliveryAmount')

	return getDeliveryAmountFunc({type, origin, destination}).then(result => result.data.amount)
}

//Get discount
export const getDiscount = code => {
	const getDiscountFunc = firebase.functions().httpsCallable('order-getDiscount')

	return getDiscountFunc({code}).then(result => {
		return result.data
	})
}

