import React from 'react'
import { Link } from 'react-router-dom'
import recombee from 'recombee-js-api-client'


import settings from '../../settings'


const recommendations = [
  {
    "title": "Maison",
    "products": [
      {
        "id": "-Kx2K1FMUrpzRZHfOQAy",
        "category": "Maison",
        "slug": "/maison/location--Kx2K1FMUrpzRZHfOQAy",
        "price": 5.5,
        "title": "Destructeur de documents",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-ef6e02e7-7920-4f96-819d-ebbb8da3e7c7%2FDestructeur%20Fellowes%20Powershred%20W-1C.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=QhQ8hIDJEPA8JSBMx8hDh86Aq0Pduxs%2FgEuOr5nbUoM1efkcn86Pq9ZecqD5hgfpZQVpd%2FbkS2AdmluHlKfa027lJH2tlabCBS3QCoX9u04Qkx0cwcJTb%2B27okmbwf3zs9pUdAxgy9Gm3Fz1cBKRzUX7h0Jdq%2B333xtC58Xx7p1y1o%2F50zXFwl877OxYpyRNjnGdAB5sT22WPRTMQJGQ76IxqDHNO5tPdQndvq30WBAEnsscOAytgAJfSts1WivFlrP6kG4fEMYycvdb6DTdDYqFe2g%2FBa08Pn5D568ANqL0sya7VTEybfbYYwRBwacfKMYDJEGDUS9imSjOgkwXtg%3D%3D"
      },
      {
        "id": "-KyAR7LbgFH7BC7a9Dcm",
        "category": "Maison",
        "slug": "/maison/location--KyAR7LbgFH7BC7a9Dcm",
        "price": 29.99,
        "title": "Cimex Eradicator Polti - nettoyeur vapeur",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F1-632568a3-dbcd-4a06-9f49-ec791f1da800%2Fcimex_eradicator_2016.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=UeR1o8JsKgX%2FXoFrgRk3ITQa6e4%2FWu6snK6Cnoj2qoZ4LSCPg5m3fVlW6vjj5LlPFdskHUYm8NLkRyPFzXNJ65x0z5qf9PLCPquTuNP4po%2BgQb7aDPLuZjiEcvDshOVqSlDGtYnsI%2F7zmNHBtN63TOevxU86sV2WPedvQXlz4qYCVoLMb9CYaBKwBrsd3Y4JZDnjJEdeolR%2FbzI5br%2F55xG8uRfH4DpKgFEZTGPbkyWTE15zGDQVb0Nwn0Mbd9NjDopQFziRDnm8u2VdamVAQHDs4giwBEUbkZyBVL0X5LCM5o8KQN6YN8c2yGj2SlDUMTy%2BVj6i1WtEq3T7BcrIfA%3D%3D"
      },
      {
        "id": "-KlY7HuwIgJH_iC3bPui",
        "category": "Maison",
        "slug": "/maison/location--KlY7HuwIgJH_iC3bPui",
        "price": 12.5,
        "title": "Nettoyeur Vapeur 901-1600 Watts",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-91994f5d-bd36-4713-8518-77d606f5d044%2Fkarch.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=UV%2FWe4nWh%2BEeE9andeQkwaQMC%2F2YHO%2BPA1Xfbr0oFs2cUigbF9Ame56t9SJLjlzeQJjYYvxblxWOtMCd2A6q5xrmaARjlxbEu6O0SD5wy2szu9p1eE1%2FbpvvYF3zsNGmx%2F0BRxhSSNYPiRBRwWAjaL%2FYMnXwxIMurLsEyjzZxLqIDNtorl28H%2Fye1gRRp18YtyQunFkUWDn1qagYsiXUZAhr9SIR1AgHf%2BgvuAWl0i60HlJagyOn%2FoGZBloBV9rT1yV7dk9h6%2F8hWEZJQt%2FaUGCk8YRWeLhAb1nF3D8eI%2F1bvXn%2BBhoO7uI38VYT5v6JsrJwMTleTrWW9o0YvR%2Bq9Q%3D%3D"
      },
      {
        "id": "-L2KP_UsIYV5f-CPXq2n",
        "category": "Maison",
        "slug": "/maison/location--L2KP_UsIYV5f-CPXq2n",
        "price": 7.5,
        "title": "Déshumidificateur De'longhi AriaDry Compact 20L - 410W",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0--L2KP_UsIYV5f-CPXq2o%2F9788e564-8060-4913-a0f6-1f5badc184e8.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=BjKWieIZFO6DmbxApO9L9lcztX6v%2Fun4m%2FfF9cc5296uFDRPKIr9L4KfHdM6NkLboHXA5pdPfsd6PPlt6OH0m6%2FmtX6ITMYrqG8YWe1RXfjfvNr60fKw68tUmj1C9oFKyXQhxNU1TybejKDXn5%2BiAnuwRMsOosIRQb1CEcuiQtoZj2M2oC1o2hssRku004%2BU46VJPrrc0ed0tXHhSpFJLa85LICCKhNwTrnGR2rHIoMeb1d%2FUvO%2Fc7MwVhf%2BfyYmtWHy6QyZ6p7ryv9J%2FHj90NczAGytjzWI7YjLqU8QRuCHJ3l7iXMKEHwkabT2LyaapP1aro9Loq0xC7LX63UdEg%3D%3D"
      },
      {
        "id": "-KograEtiXs-Zw4KVELy",
        "category": "Maison",
        "slug": "/maison/location--KograEtiXs-Zw4KVELy",
        "price": 8.2,
        "title": "Machine à coudre Singer",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-24048dd4-c5d2-465b-ac87-1098a89b2f51%2Fsewing-machine.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=Jq4hO7lL6qiEPYyzl0MCcPENxVZbFvxtmlQ1FJi49xWOcGpXmWcg9mhrokO1vPpQBMHfF7thOZtUu8DE%2FsGWFIEhY2lctjTtn8fT%2F%2FczbhsJnNP5VG6te%2B0wK2SnVometTdnY%2B1yLYy15t1JXi7TepwZ3AAeevHHC74CEmCVEdtVYXmpFLz%2BXdHpqFfr2HAEI9EosPS%2FZj4Gi7qv%2BnRbPUbguKHVDTS%2BCGh%2FcftbhnarP0xl42e1QcbV1j1xtrl8cXLNfMLvXdY0TaXj7%2FWuBNim7ypwVeRLgdjYDFghTV8kVrL6jjscENeww0kjsYKTE%2B%2BgsB6dcjr2taGo%2BswOJA%3D%3D"
      }
    ]
  },
  {
    "title": "Photographie & Vidéo",
    "products": [
      {
        "id": "-KmaYpPcItimW3vSNS4q",
        "category": "Photographie & Vidéo",
        "price": 44.99,
        "title": "Drone DJI Mavic pro",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-686b9883-34e0-46d7-8ca0-2b0144a5b914%2Fmavic%202.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=igTCj3%2FEDsLWyeN23DeodWl4fpKOF1davcp69J7GUdPQjpBhfewI37tBMouMrrKZmPD%2FXy%2FTESUzzI%2FPYPrbN%2B6dJE0KV%2BU5EesZ%2FtpYppme04vD47vSYQm4pR1%2BWzk98ibIUqGGlRXl4oh2URUDxUTeFBlVIao9G7WEH86LeqopXzf32AimOW9hWQVNih6LjQqA3nvBMejIJOOAl9QOvg1fhOUkDcqQwG3ClzMHgAtBVxwOJYl6u%2FinKdpguLMdc5jlDS6e%2BGtvFnNYD%2FabhCR39LXkUxfTpVtnyHVkF03GDwaKomkNILB2Taz4Knm4GSRDzUljmlWvZLEqJ0MIKQ%3D%3D",
        "slug": "/photographie-and-video/location--KmaYpPcItimW3vSNS4q"
      },
      {
        "id": "-KtpEZvnkGY17x1Bp30d",
        "category": "Photographie & Vidéo",
        "price": 69.99,
        "title": "Drone DJI Phantom 4 Pro ",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-e00f2bf7-9441-4d43-807c-124544572395%2Fdrone.png?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=T7MGEfxjz2Tc4sYowZGI7Ia%2BGHYSsfuf0LJt3WQpcLGrBCMKYYAKmfckirhwKTNTzd6VjPlSmfv8tyEE%2ByaFg2lZ5zKpRsx%2FHYb7mT4CGGNHUCnwNKnxlYZ0uRWiDA5ia97iKyc1%2F8tOqzBKSfCGWSkbi0LumcoRGdzmBrZxEAXXLmeM32X9863iOGPzTbLESX%2FZApYKZC6zm2KMc8oWWbW1hisi5DZbL7imhgCBHO9wwP2I0FheHT6emh4efRglTIk8HpWgJ%2F0pJgZMo%2FIdstMt2mlA2falPZiYmLQcpCZ4NC8w3Dg1V9OV1kst%2BsZkodZyO3zllp28MZT7NMx%2F8g%3D%3D",
        "slug": "/photographie-and-video/location--KtpEZvnkGY17x1Bp30d"
      },
      {
        "id": "-Km_lbr0a0WGfpLRdNtJ",
        "category": "Photographie & Vidéo",
        "price": 34.99,
        "title": "Objectif Canon 24-70mm f/2.8 L USM",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-3e1e25bf-5f0f-407f-ad60-59b1103779db%2F27-70mm.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=YV3VnJg1lYTcg3NMO7eHJrbRe8ERdDrINAb5bSN9Fv81DX1OBQjQER%2BhM6C5ahtmI0qyCM%2BD6FS7Cd3I78OMw8Qqj5FV5RrfZ1jeLePKo%2FB5lGdqpOh4VkupUd27Hac59ybo31X5r2yqB5bl6I9oywUIfoKwLAFZolLFiDDqZn2jgdlMnztzSL3EN6BGuLo8Vz5ZzmASdsmBnl0xdTrBZ%2F%2FdXJ3MMJ9CWFxRkJhCeeDUWD%2BaMPJgUBV%2BPzEQvwmHmqgc1vqT2zx7qL0afBMVHHBfCxcVbwG5JM%2Bb1TZPrpZ4m%2BmF26bgM4a6AxfdPTMqzADFdHN72qkgLas7%2BQvztA%3D%3D",
        "slug": "/photographie-and-video/location--Km_lbr0a0WGfpLRdNtJ"
      },
      {
        "id": "-KtzGxrXkKffRljMdQE_",
        "category": "Photographie & Vidéo",
        "price": 48,
        "title": "Stabilisateur Dji Ronin MX",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-b411b4d1-7a43-4605-bef7-9014f2ef5c8a%2Froninmx1-46.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=X%2FdWTOIIlA86cN1atiUJPjw6v9S9ATKlID0ViNZuvzNYTQ47oMOoYn%2FRy9O7iNXvOOtX94kTyqVSLKZuFGbwqhKTjEqclc3OqdoADzvAVnL2XWn2f2lBvcGYYJA17Mle58mFpNuB7UIbOk%2Bc%2B6RVZvMgRIaxzNHDhFt%2ByrnXp9%2BSCXbBqEh9k83m1B958u73hnmpSSfCYVs18v2T%2F2F6vNU2uPiX4v8Cm%2Bc%2FiHVER6bhdE5aqX97c6hln9IEYqTxGn26mqU6UZGacOUG9fMBlgbIiew%2B1LXwDye4ymdpdhHbQEtpRXRDHmhbC1F%2BFNgrMGE30sBx%2BqDILRcyjd4NUA%3D%3D",
        "slug": "/photographie-and-video/location--KtzGxrXkKffRljMdQE_"
      },
      {
        "id": "-KmVlHfYUO0C14pSVl_F",
        "category": "Photographie & Vidéo",
        "price": 25.99,
        "title": "Appareil photo Canon 5D mark II - (boitier nu)",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-5b71961f-4024-4924-92d1-d78e498c4456%2Fcanon-5d-mkII-dos.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=BYDQPeCmgLtgKkly%2FA9VwAgcHpUdd31apou5jrYorO%2Bo5qjcb3BOiT4zE%2FvP6mCA3GMwnFGRNRXVny0s661L3dviQzEpYp13TA2IHjT%2FWrkyv01Yd6k12DZAxQTFzeYuDHMSZzcPUHeFzkyf5N8Hjm%2B7xGBdkM4o9%2BRa5mO5X1OIaqaeN1U5IjOJkJLjpDnLVcZohe%2Bj93P5UWakSmTJjTo4qMuB9a22Abk2k44ij7w8qJLK2yblhwtgUcyhrM%2F%2Bu5e%2BSE%2BMpNzTiFSXSUTwtZ6fMsx9jDrjI%2BXHZzj76G4pU1Tq0ewPJMyLVt5J%2BEBmmuQux1tVc2ty2bESsR3Rdw%3D%3D",
        "slug": "/photographie-and-video/location--KmVlHfYUO0C14pSVl_F"
      }
    ]
  },
  {
    "title": "Bricolage",
    "products": [
      {
        "id": "-KmVY-TV2lA6f_jLJyjg",
        "category": "Bricolage",
        "price": 11.5,
        "title": "Aspirateur multifonctions",
        "slug": "/bricolage/location--KmVY-TV2lA6f_jLJyjg",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-34893177-c3af-416a-8a7a-7720c1a902f1%2Flarge%252F0-be3e2f39-2ed0-457a-8611-d339adff7d39%252FAspirateur%20multifonctions.png?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=nUIgBMGf7NHtMyxTZtbtvAJtPcQ%2FWWDJIL05SJkZBTJIEIR5IgyZdWvvDRPvGFXpB883vkF0TGOBiSlQP31b%2FoUvOi9y91LuvwkcM5aJ29mQEuvZ1OwI0q1WY%2FlCeoi0y6aTOQjrpSYcLQqNFnDZNwIFEQ9trpWNF1Aide9vDTwQO1P0acYIol%2FbUhyGGzHCe0x3sgbQYYvQWvCWuBEPQba5AA0fokpV93F0Se6CU2Q06rgcOKL7D%2FDOydH3XSCPPwzykUC6ThJBqyjNyE8Fh7s%2B16XvOufg7xbXqVyn9gdpO8OD%2B%2B3LFQUicLGqgdSXGGEAThrkEin7cEIkJxZYOw%3D%3D"
      },
      {
        "id": "-KmQcqME3F1yt_w_D9yI",
        "category": "Bricolage",
        "price": 15.5,
        "title": "Karcher haute pression 110 bars ",
        "slug": "/bricolage/location--KmQcqME3F1yt_w_D9yI",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0--LFM-iSe7PR5NVWt3A5f%2F547188a5-052e-4e5d-bc1d-a2703d167e04.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=CabiBTGi2D5HYmNKI2Z5%2F5p9%2BxGvYbWwJ4qZcjtmU1G7XeYE2ZY2vdk3Wk0CfBa%2FwQfzF5UEkQ%2Fm3Vkc3Ux%2Fq671JqxCYas3WQ8moZA9KZxoEZGItewnqLv1gR9aWEzxOj%2FvJXHavicVRFshivN2LWCWaLhIIeZEPKaBcIfko9SMSF9YrPIPecxB5OCJAWatsuBR6S0lMWUsNn2t%2BuiNsBh7Vwoj63itO2wux5CuDQpJW0pPXTIW%2FWDZpqUNb6FDz%2FUuDjPUy7WMnEEx%2B7jDZ2tB9kYbSsfHSKpKFx7pDqi%2BgEVdcSyC%2F73fGUG3rPafv8UTWfuXmnS8QYLxdWdIjg%3D%3D"
      },
      {
        "id": "-KmS-GtEkVm_oRC7qrdf",
        "category": "Bricolage",
        "price": 25.99,
        "title": "Diable monte escalier charge maximale 330Kg",
        "slug": "/bricolage/location--KmS-GtEkVm_oRC7qrdf",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-81143f5e-d000-4df9-ab09-6cdc0ad0f6d7%2FFold-L-Liftkar%20(1).jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=c%2BhgMizMVnya%2FGLssRESBCBqDD4OvOQ6WPvuxsxNh9swEElAu%2B%2FEUyK%2FXc2dWI85aNkV78OV6FSU7XohqhciQcB15Me2haHqwIeMCxMNdYXjRvaIYIZMDDYRRUppb8SX%2BxjviznVeYg4eJ6YJpX89CfooDw1KwHV9OW8DEjoTfubZpuzSBFM64%2BiiZDCnm7hnl2WTWbfAq4jHC%2F7%2B%2B6y8Fqhy1yeUFTSz33aNzz05WRp3LrP7shj2ay9EU4ixBuUHsrlWuX6VG4Tf4J8g4C1%2FRiOieDk7y5GFKqYOEjHEANI6UeaZcM0K%2F37Ks4R6neipMTjmXfUid7HHJ6kXibVbA%3D%3D"
      },
      {
        "id": "-KxwEjONIOJd448xZe3-",
        "category": "Bricolage",
        "price": 21.99,
        "title": "Diable monte escaliers charge maximale 110Kg",
        "slug": "/bricolage/location--KxwEjONIOJd448xZe3-",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-a0b24621-323d-4e71-a482-27aed2f0f5df%2Fsal_fold%20(1).png?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=LC2dMJRybm6MMMjUa7aLputPzo9ZXEHi2SW5h77bGJSfyKckoGfxvbp092O6u5Khu4VBPM%2BHKeZDHntk8IppCVBFBO%2B85xAzmTyJpNIeVv0iS%2F0TQDH9bnSkkgqMY1ap3%2FKb%2B3KculQSYxErc5UkBsna9bFwzWkKkGTPUVtMRiOfhEKnnewOO5pzqhPETmzbms2Mw5NKz9%2FRAFTfx4H6oTG4HKVq8ESwVjOnALafUZL9pNWoSXgAIsDmhzyrqQLt%2FHuWzh9cNRCfm1X7Xva5isoZ%2FiJrgRd%2B3kP4pyG1%2Bi6lwN0zKTayQLhg72%2FMme24ZvA43GWkCgTA1nQeTn9VzQ%3D%3D"
      },
      {
        "id": "-LHiJQNiVp1oalTV5bCi",
        "category": "Bricolage",
        "price": 12.99,
        "title": "Perforateur Bosch Pro Filaire 880W",
        "slug": "/bricolage/location--LHiJQNiVp1oalTV5bCi",
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0--LHiJQNmV38aBo0HYmXL%2F41c36762-2c76-463d-9571-39b96e6b1fb5.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=XLnkwQvTqEsZPLJ7TZtisCbI7DO8L%2FF4gCcrWlaj3BdHUjfug5%2F9DJSFbUvcYj4P%2B9syjHwg9bCUmPZt7ZNBiLvrHj4Lwhwd%2FlMC53VWzVgT3VzA40qSUGPVI8e2dgtzhIhhJhJTf8mxvFjCYOybfiYJZcMIHC664OiZex5FfwIFPXA4prsqD7XbRZ5GQqE1T48sS6hesNmWOtr2uo%2B0EjOo6xvB%2BrWb%2Bec59Cc34KrMHbT%2FdgefLgeh14Ex3LWAStiC5cTb4DcmKV5CZhJSATU2%2F3WJwuDWI0jXtmVmNms7377qChl%2B2pDfPG8hNSDCa97e8Dqa3zHFp6hde5sjng%3D%3D"
      }
    ]
  },
  {
    "title": "Puériculture",
    "products": [
      {
        "id": "-KqcSaP8Mf4fuIuoT5q4",
        "title": "Réhausseur Groupe 2/3",
        "category": "Puériculture",
        "price": 3.5,
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-cff7dca8-5d8c-4fc6-adc8-8be52c46ddc1%2Fsans1_md.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=IBWZaY1Edoz42jdmSKz%2BDBD7R2DLL4ECVG9xGLqfi%2BWSUZOUu0S7mHJG8p%2Bc%2FoJdYuObpYsrUPCFg5ZYpaOTyZVyL0FA0DvGO26VqbFlrmZphKv12kQM1iKkY9ofVO1U23%2Bm2IVKXxC2WfcEh%2BYhWBW8ZnO9tdfLdMJyzsuATbK85lhCBpBBX3F5cINTgzvQrVltd0OLe4eDlOA8lFmcHbayFc2zev0AHRVsXLMz9QbQM1icZ3odo6EQQmtHKfA%2BxTkpqsE9OMtFEWcwTKEuect6L4aGcVquUfFfLoP3UNWPs9Y363bd%2BdaiQjP4uxj9%2FNaSCwUZKrXHL4r%2Fsy5mvQ%3D%3D",
        "slug": "/puericulture/location--KqcSaP8Mf4fuIuoT5q4"
      },
      {
        "id": "-KqhvuvL12IG2pDNbwrd",
        "title": "Poussette YoYo",
        "category": "Puériculture",
        "price": 8.4,
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-7926c383-4143-4525-9a5d-7d086d9a852f%2Fpoussette-yoyo-plus-6-mois-chassis-noir-babyzen-noir.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=VOmywgdxRsF9csEaUzDLpNIinbmfeCffBgM6YVfo0r8Y5fvFhZ9IsKjek6h6iLQGV93UjMc%2BRl%2FNY%2F5TGfJa7Gqs1W8r3kFcUK3wLDcvkHicyqQaD3ltIPHvkskFWRHQh6czqSMmJhFgjOkmhapvJ1ZILpEzTREmO5U9x5AE2z6N5APWxBy60NuObpiPVJh3Ze7Ttp%2Fx6b4mcmZQKwi19I1zqHjgZ6rxXwH5xG3LZ4tlYsxg9CrqS5nKitgLRlXAZ3t9glIa8yimihJ4RcvxfKd%2BV9weEgJoWQOJL5TeDcioHuTHiE3swVDjD%2FFbeqL0aedERNZAm9ggBL%2B7pLhq5w%3D%3D",
        "slug": "/puericulture/location--KqhvuvL12IG2pDNbwrd"
      },
      {
        "id": "-KmV_xkOWFqNHAZGdowB",
        "title": "Lit parapluie ",
        "category": "Puériculture",
        "price": 4.5,
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-90336e6c-e068-43fe-a302-0704a8af69e7%2Fbabymoov-lit-parapluie-sweet-night-taupe-amande.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=elbagMHGHgeFMDoW9Fn9l%2BVdaGRBRdeFmi%2B%2BHVzlSP8XSYPIVxMopPxQI8tywspEdo9DsZwUy7AuQlwnHKEfCAiFjRz9dnnVtv8Br1YipD7fiqfYgJFNiJvl%2Bd1B72XoRG5JWPs6wKdg8%2BfCJTTnt1okq2aGxjDm19G9e9fM3Fim3RqFBC6IgKRv%2B6J%2BEmrgQovv2c4kr6A8Av6hb02krodt0s4PTH5EzuFQ6bc%2Fw3seatarcAYa6LMuNhxB8vwyVWqmEpoPjlKMnaCFaCnoHPCab%2BgivHUrcWHP%2FA29mTMLKn1lS2MZlY5v7DX2a5u5H0tigKffQsUqtTEd1cOVQw%3D%3D",
        "slug": "/puericulture/location--KmV_xkOWFqNHAZGdowB"
      },
      {
        "id": "-Kq7ZTqzSQzfNZFmDwHU",
        "title": "Siège Auto R44/04 Groupe 0 + (0  à 13kg) ISOFIX",
        "category": "Puériculture",
        "price": 4,
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-beaa5621-60b5-409b-aa89-d9f4b20e17c4%2FV-FBB-370032.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=jeAMCV22XGNRo9JBlEv1t32fTuQyvIGwjWDga34rypIq2OKpchTrNtXAYG%2F4MjxKbUP2jfKeYwkzp%2F4tbtXTCsbHgCY%2FeAp%2FwNL7wDo91B8ubsKcBfooooVxRPmSLRFyLpw1emGBKUW6tpULevzLIvWJG5nYUywbiRvcq6PKSOzz9AJHuK3IqQrsTOa%2B4Cre6ikuD23lSIVPclAxaVMwDJKL448Kwse6YddoW0%2FOrJNfda9BVUqhCkkKwKywZHNqKmdpA2FfPXVcU9VehNDgOMeSr%2FsWsYO9KeXaNvrpynA8ayTwTXUISF0xS5rIFAOG%2BZH1uCf2pCtRESCfX6XoZw%3D%3D",
        "slug": "/puericulture/location--Kq7ZTqzSQzfNZFmDwHU"
      },
      {
        "id": "-KntcFh_DV6I0nsob5aC",
        "title": "Siège Auto R44/04 Groupe 0 Siège Coque (0 à 10kg)",
        "category": "Puériculture",
        "price": 4,
        "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-48d16bf8-3f50-400c-8de7-11259d9ac418%2Fsiege%20auto%20bleu%20trio%20chicco.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=d6uXcnEQEPMxOCZVJg8iswuns7Jtyid6Ymi7Vd23eOjShuIYSJsqybG7BlShZaDr28a39FrTZSr%2Fd6pKPYQdTjwk1FYAGC1PPpOUzyGN%2BixutnHrleqGh0nprtJSEVSkjfPpzrpsXvo9B6cCRK4SVRuhm7vKAWzv%2Bl37Ro5QzWBbQKsnI13EAti6OZYaQUzK7e1WNj6XMbtDeROkJvhYNJklnX0Z8TNlNaMf2Vqa4k8IB2tErtsazXxJ4lweTUBXnclAzvvffGrhi%2BNXF4dIxgDVokBSQFTkG7UdkDVXHNUi9bQwZOwB8XyA7y%2FHTTbhNa%2Brcj7Gjt0wJNU4UefuzA%3D%3D",
        "slug": "/puericulture/location--KntcFh_DV6I0nsob5aC"
      }
    ]
  }
]

const Product = ({product}) => (
	<li className="clearfix">
		<Link to={product.slug}>
			<img src={product.picture} alt="" />
			<span className="product-title">{product.title}</span>
		</Link>
		<span className="price">
			<ins>
				<span className="amount">{product.price}€ /jour</span>
			</ins>
		</span>
	</li>
)

const ProductList = ({ title, products }) => (
	<ul className="product-list-widget">
		{
			products.map((product, index) => <Product key={index} product={product} />)
		}
	</ul>
)

const ProductWidget = ({ title, products }) => (
	<div className="col-md-3 col-sm-6 col-xs-12 mb-40 products-widget">
		<h3 className="widget-title bottom-line full-grey"><Link to={`/recherche?menu%5Bcategory.name%5D=${encodeURIComponent(title)}`}>{title}</Link></h3>
		<ProductList title={title} products={products} />
	</div>
)

class ProductWidgetList extends React.Component {
	state = {}

	getRequest = categoryName => {
		return this.recombeeClient.send(new recombee.RecommendItemsToUser(
			this.props.user.id,
			5,
			{
				returnProperties: true,
				includedProperties: ['title', 'category', 'price', 'picture', 'slug'],
				filter: `'category' == "${categoryName}" AND 'online' AND "${this.props.user.store.id}" in 'stores'`,
				scenario: 'homepage'
			}
		))
	}

	getRecommendations = () => {
		const categories = ["Maison", "Photographie & Vidéo", "Bricolage", "Puériculture"]

		const requests = categories.map(category => this.getRequest(category))

		Promise.all(requests).then(results => {
			const recommendations = results.map((result, index) => {
				return {
					title: categories[index],
					products: result.recomms.map(recomm => Object.assign({id: recomm.id}, recomm.values))
				}
			})
			this.setState({recommendations})
		}).catch(err => { console.log(err, 'error')})
	}

	componentDidMount = () => {
		//Init recombee
		if (settings.recombee) {
			this.recombeeClient = new recombee.ApiClient(settings.recombee.id, settings.recombee.token)
			if (this.props.user) {
				this.getRecommendations()
			}
		} else {
			this.setState({recommendations})
		}
	}

	componentDidUpdate = prevProps => {
		if (!this.state.recommendations && this.props.user && ((this.props.user && !prevProps.user) || (this.props.user.id !== prevProps.user && prevProps.user.id))) {
			if (settings.recombee) {
        this.getRecommendations()
      } else {
        this.setState({recommendations})
      }
      
		}
	}

	render = () => (
		<section className="section-wrap products-list" style={{paddingTop: "0px", paddingBottom: "0px"}}>
      {
        this.state.recommendations && (
          <div className="container">
            <div className="row heading-row">
              <div className="col-md-12">
                <h2 className="heading">
                  Nos produits à l'honneur <small style={{fontSize: "50%", fontWeigth: "normal", textTransform: "none"}}><Link to="/recherche">Voir tous les produits</Link></small>
                </h2>
              </div>
            </div>
            <div className="row">
              { this.state.recommendations.map((recommendation, index) => <ProductWidget key={index} title={recommendation.title} products={recommendation.products} />) }
            </div>
          </div>
        )
      }
		</section>
	)
}

export default ProductWidgetList