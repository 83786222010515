import React from 'react'
import { Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import moment from 'moment'
import * as Sentry from '@sentry/browser';


import Main from './core/Main'
import MainRoute from './core/MainRoute'
import MainPrivateRoute from './core/MainPrivateRoute'
import settings from './settings'

import { update, get } from './core/clients/restClient'
import { Loading } from './theme/components'
import { 
	Cart, 
	Checkout, 
	ContactUs, 
	ErrorPage, 
	ForgotPassword,
	ResetPassword,
	Login, 
	Signup, 
	Home,
	Insurance,
	NotMatchPage, 
	Orders, 
	Order, 
	Search, 
	Single, 
	Page ,
	Payment,
	PayOrder,
	Store,
	Maintenance,
	YourAccount,
	OrderList,
	Subscription,
	UserInfo,
	CreateOrder,
	Invoice,
	UpdatePassword
} from './theme/views'



//Google analytics config
ReactGA.initialize(settings.ganalytics.id)
ReactGA.plugin.require('ecommerce')

// //Raven config
Sentry.init({ 
	dsn: settings.sentry.dsn,
	release: settings.release ? settings.release : null
})


const getParams = (params) => {
	if (params) {
		//delete ? character
		params = params.substring(1)

		//convert array in object
		params = params.split("&").reduce((acc, item) => {
			const arr = item.split('=')
			acc[arr[0]] = arr[1]
			return acc
		}, {})

		return params
	} else {
		return
	}
}

class App extends React.Component {

	handleHistory = history => {
		this.params = getParams(history.location.search)
		const url = history.location.search ? `${history.location.pathname}${history.location.search}` : history.location.pathname

		ReactGA.set({ page: url, userId: this.user ? this.user.uid : null})
		ReactGA.pageview(url)

		if (settings.intercom) {
			window.Intercom("update")
		}
	}

	onCurrentUser = user => {
		if (user) {
			if (settings.intercom) {
				if (this.user === undefined || (this.user && this.user.isAnonymous !== user.isAnonymous)) {
					this.user = user
					window.Intercom("shutdown")
					if (user.isAnonymous === false) {
						window.Intercom("boot", {
	  						app_id: settings.intercom.id,
	  						name: `${user.firstName} ${user.lastName}`,
	  						user_id: user.id,
	  						email: user.email,
	  						created_at: `${moment(user.createdAt).unix()}`
						})
					} else {
						window.Intercom("boot", {
	  						app_id: settings.intercom.id
						})
					}
				}
			}
		}
	}

	setStore = store => next => action => {
		const result = next(action)

		this.user = store.getState().auth.user
		
		// const storeId = this.params && this.params.storeId

		// if ((this.user && this.user.store === undefined)) {
		// 	get(storeId ? storeId : settings.storeCities.paris, '/stores', ["openHours"]).then(store => {
		// 		update(this.user.id, '/users', {store})
		// 	})

		// } else if (this.user && storeId && storeId !== this.user.store.id) {
		// 	get(storeId, '/stores', ["openHours"]).then(store => {
		// 		if (store) {
		// 			update(this.user.id, '/users', {store})
		// 		}
		// 	})
		// } else if (this.user && this.user.store.openHours === undefined) {
		// 	get(storeId ? storeId : settings.storeCities.paris, '/stores', ["openHours"]).then(store => {
		// 		update(this.user.id, '/users', {store})
		// 	})
		// }

		return result
	}

	render = () => (
		<Main 
			loginPage={Login}
			forgotPasswordPage={ForgotPassword}
			resetPasswordPage={ResetPassword}
			signupPage={Signup} 
			notMatchPage={NotMatchPage} 
			errorPage={ErrorPage} 
			loading={Loading}
			settings={settings}
			handleHistory={this.handleHistory}
			middlewares={[this.setStore]}
			onCurrentUser={this.onCurrentUser} >

			<MainPrivateRoute exact path="/" component={Home} />
			<MainPrivateRoute exact path="/store" component={Store} />
			<MainPrivateRoute exact path="/recherche" component={Search} />
			<MainPrivateRoute exact path="/panier" component={Cart} />
			<MainPrivateRoute exact path="/checkout" component={Checkout} />
			<MainPrivateRoute exact path="/checkout/:step" component={Checkout} />
			<MainPrivateRoute exact path="/orders/:orderId/create" component={CreateOrder} />
			<MainPrivateRoute exact path="/orders/:orderId" component={Order} />
			<MainPrivateRoute exact path="/orders/:orderId/pay" component={PayOrder} />
			<MainPrivateRoute exact path="/payments/:paymentId" component={Payment} />
			<MainPrivateRoute exact path="/account" component={YourAccount} />
			<MainPrivateRoute exact path="/account/orders" component={OrderList} />
			<MainPrivateRoute exact path="/account/orders/:orderId" component={Order} />
			<MainPrivateRoute exact path="/account/subscription" component={Subscription} />
			<MainPrivateRoute exact path="/account/billings/:billingId" component={Invoice} />
			<MainPrivateRoute exact path="/account/user-info" component={UserInfo} />
			<MainPrivateRoute path="/:category/location-:rentItemId" component={Single} />
			<MainPrivateRoute path="/contactez-nous" component={ContactUs} />
			<MainPrivateRoute path="/assurance" component={Insurance} />
			<MainPrivateRoute path="/update-password" component={UpdatePassword} />

			{settings.pages.map((page, index) => <MainPrivateRoute key={index} exact path={page.permalink} render={props => <Page {...props} pageId={page.pageId} />} />)}
		</Main>
	)
}

export default App
