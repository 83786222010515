import React from 'react'

const PageTitle = ({title, children}) => {
	return (
		<section className="page-title text-center bg-light">
			<div className="container relative clearfix">
				<div className="title-holder">
					<div className="title-text">
						{title ? <h1 className="uppercase">{title}</h1> : <React.Fragment>{children}</React.Fragment>}
					</div>
				</div>
			</div>
		</section>
	)
}

export default PageTitle