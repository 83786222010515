import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { SubmissionError } from 'redux-form'
import moment from 'moment'
import { on } from './restClient'

//TODO: improve the authorization process. Create a function hasPerm

//Get user info
const _getUserInfo = user => {
	return firebase.database().ref('/users').child(user.uid).once('value').then(snap => {
		let userInfo = snap.val()
		userInfo.uid = snap.key
		userInfo.hasPerm = () => { return user.isAnonymous ? false : true }
		userInfo.isAnonymous = user.isAnonymous
		return userInfo
	})
}

const onUserInfo = (user, callback) => {
	return on(`/users/${user.uid}`, userInfo => {
		if (userInfo) {
			userInfo.uid = userInfo.id
			userInfo.hasPerm = () => { return user.isAnonymous ? false : true }
			userInfo.isAnonymous = user.isAnonymous
			callback(userInfo)
		} else {
			callback(null)
		}
		
	})
}


//Login with email password on Firebase Authentication
export const login = (email, password) => {
	return firebase.auth().signInWithEmailAndPassword(email, password).then(auth => {
		return _getUserInfo(auth.user)
	}).catch(error => {
		if (error.code === "auth/invalid-email") {
			throw new SubmissionError({
				email: error.message
			})
		} else if (error.code === "auth/weak-password") {
			throw new SubmissionError({
				password: error.message
			})
		} else {
			throw new SubmissionError({
				_error: error.message
			})
		}
	})
}


//Create an anymous user session
export const createAnonymousUser = () => {
	return firebase.auth().signInAnonymously().then(auth => {
		return firebase.database().ref('/users').child(auth.user.uid).set({
			createdAt: moment().valueOf(),
			provider: "anonymous",
		})
	})
}


//Create user with email and password
export const createUserWithEmailPassword = (email, password, userData) => {
	//Get current user
	const currentUser = firebase.auth().currentUser

	//Prepare user data save
	userData.isActive = true
	userData.provider = "password"
	userData.createdAt = moment().valueOf()


	if (currentUser) {
		const credential = firebase.auth.EmailAuthProvider.credential(email, password)

		return currentUser.linkWithCredential(credential).then(user => {

			return firebase.database().ref('/users').child(user.uid).update(userData).then(snap => {
				return _getUserInfo(user)
			})

		}, error => {
			if (error.code === "auth/invalid-email") {
				throw new SubmissionError({
					email: error.message
				})
			} else if (error.code === "auth/weak-password") {
				throw new SubmissionError({
	        		password: error.message
	      		})
			} else {
				throw new SubmissionError({
	        		_error: error.message
	      		})
			}
		})
	} else {
		return firebase.auth().createUserWithEmailAndPassword(email, password).then(user => {
			
			return firebase.database().ref('/users').child(user.uid).set(userData).then(snap => {
				return _getUserInfo(user)
			})
		}, error => {
			if (error.code === "auth/invalid-email") {
				throw new SubmissionError({
					email: error.message
				})
			} else if (error.code === "auth/weak-password") {
				throw new SubmissionError({
	        		password: error.message
	      		})
			} else {
				throw new SubmissionError({
	        		_error: error.message
	      		})
			}
		})
	}
 
}


//Get current user
export const currentUser = () => {
	return new Promise((resolve, reject) => {
		const user = firebase.auth().currentUser
		if (user) {
			_getUserInfo(user)
		} else {
			resolve(null)
		}
	})
}


//Observer on current user
export const onCurrentUser = cb => {
	firebase.auth().onAuthStateChanged(user => {
		if (user) {
			onUserInfo(user, cb)
		} else {
			cb(null)
		}
  	})
}


//Logout on Firebase Authentication
export const logout = () => {
	return firebase.auth().signOut()
}


//Update the password of the 
export const updatePassword = (newPassword) => {
	const user = firebase.auth().currentUser

	return user.updatePassword(newPassword)
}


//Check the old password between update the with the newpassword
export const editPassword = (currentPasword, newPassword) => {
	const user = firebase.auth().currentUser

	const credential = firebase.auth.EmailAuthProvider.credential(
		firebase.auth().currentUser.email,
		currentPasword
	)

	return user.reauthenticateAndRetrieveDataWithCredential(credential).then(() => {
		return user.updatePassword(newPassword)
	})
}


//Send password reset email
export const sendPasswordResetEmail = email => {
	return firebase.auth().sendPasswordResetEmail(email)
}


//Verify password reset code
export const verifyPasswordResetCode = code => {
	return firebase.auth().verifyPasswordResetCode(code)
}


//Complete the password reset process, given a confirmation code and new password
export const confirmPasswordReset = (code, newPassword) => {
	return firebase.auth().confirmPasswordReset(code, newPassword)
}