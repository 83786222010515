import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError, change, reset } from 'redux-form'
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements'

import { Form, Modal, ModalBody, ModalHeader, ModalFooter } from '../components'
import { CardElementField, TextField } from '../fields'

import settings from '../../settings'
const stripeApiKey = settings.stripe.apiKey


let PaymentMethodForm = props => (
	<Form {...props} saveButton={false}>
		<ModalBody>
			<fieldset className={`be-loading ${props.submitting ? "be-loading-active" : ""}`}>
				<TextField label="Nom sur la carte" name="name" required />
				<CardElementField name="card" label="Autres informations sur la carte" required />
			</fieldset>
		</ModalBody>
		<ModalFooter>
			<button type="submit" className="btn btn-primary" disabled={props.pristine || props.submitting}>{props.submitting ? "Enregistrement en cours..." : "Enregistrer"}</button>
		</ModalFooter>
	</Form>
)

PaymentMethodForm = reduxForm({
	form: 'paymentMethodForm'
})(PaymentMethodForm)

class PaymentMethodFormModal extends React.Component {
	handleSubmit = values => {
		return this.props.stripe.createSource({owner: {name: values.name}}).then(({source}) => {
			if (source) {
				if (source.error) {
					throw new SubmissionError({_error: source.error})
				} else {
					return this.props.createSource(source.id).then(source => {
						this.props.change('paymentMethod', source.id)
						this.props.toggle()
						this.props.resetForm()
					}).catch(error => {
						throw new SubmissionError({_error: error})
					})
				}
			} else {
				throw new SubmissionError({_error: "Impossible d'enregistrer la carte"})
			}
			
		})
	}

	toggle = () => {
		this.props.resetForm()
		this.props.toggle('paymentMethodModal')
	}
	render = () => (
		<Modal show={this.props.show}>
			<ModalHeader title="Ajouter une carte de paiement" toggle={this.props.toggle} />
			<PaymentMethodForm onSubmit={this.handleSubmit} />
		</Modal>
	)
}

PaymentMethodFormModal = connect(null, (dispatch, ownProps) => ({
	change: (field, value) => {
		dispatch(change(ownProps.paymentForm, field, value))
	}
}))(PaymentMethodFormModal)

const InjectedPaymentMethodFormModal = injectStripe(connect(null, {
	resetForm: () => reset('paymentMethodForm')
})(PaymentMethodFormModal))


const PaymentMethodModal = ({ show, toggle, createSource, paymentForm }) => (
	<StripeProvider apiKey={stripeApiKey}>
		<Elements>
			<InjectedPaymentMethodFormModal show={show} toggle={() => toggle('paymentMethod')} createSource={createSource} paymentForm={paymentForm} />
		</Elements>
	</StripeProvider>
)


export default PaymentMethodModal