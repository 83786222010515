import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'

import settings from '../settings'

axios.defaults.baseURL = settings.loxamApiBaseUrl

export const getCustomer = () => {
	return firebase.auth().currentUser.getIdToken().then(token => {

		return axios.get("/customer", {
			headers: {'Authorization': "Bearer " + token}
		}).then(response => {
			return response.data
		})

	})
}


export const getItems = (startDate, endDate) => {
	return firebase.auth().currentUser.getIdToken().then(token => {

		return axios.get(`/items?startDate=${startDate}&endDate=${endDate}`, {
			headers: {'Authorization': "Bearer " + token}
		}).then(response => {
			return response.data
		})

	})
}


export const getInvoices = (startDate, endDate) => {
	return firebase.auth().currentUser.getIdToken().then(token => {
		return axios.get(`/invoices?startDate=${startDate}&endDate=${endDate}`, {
			headers: {'Authorization': "Bearer " + token}
		}).then(response => {
			return response.data
		})
	})
}