import React from 'react'

import { onOrder } from '../clients/restClient'

const connectCreateOrder = CreateOrder => {

	class ConnectCreateOrder extends React.Component {
		state = {
			isLoading: true,
			order: null,
			error: null
		}

		onOrder = id => {
			this.orderRef = onOrder(id, order => {
				this.setState({
					isLoading: false,
					order: order
				})
			})
		}

		componentWillUnmount = () => {
			if (this.orderRef) this.orderRef.off()
		}

		render = () => <CreateOrder {...this.state} onOrder={this.onOrder} {...this.props} />
	}


	return ConnectCreateOrder

}


export default connectCreateOrder