import React from 'react'
import { connect } from 'react-redux'
import { onCart, removeToCart } from '../'

const connectCart = Cart => {
	class ConnectCart extends React.Component {
		state = {
			cart: null,
		}

		getTotalAmount = () => {
			const total = Object.keys(this.state.cart.items).reduce((sum, key) => {
				return sum + this.state.cart.items[key].totalAmount
			}, 0)

			return parseFloat(Math.round(total * 100) / 100)

		}

		//Remove item in the cart
		removeItem = id => {
			removeToCart(this.props.user.cartId, id)
		}

		//When props changed, update cart listener
		componentWillReceiveProps = nextProps => {
			if (nextProps.user && nextProps.user.cartId) {
				if (!this.onCart) {
					this.onCart = onCart(nextProps.user.cartId, cart => {
						this.setState({ cart })
					})
				}
			}
		}

		//Listen the cart if exist
		componentDidMount = () => {
			if (this.props.user && this.props.user.cartId) {
				this.onCart = onCart(this.props.user.cartId, cart => {
					this.setState({ cart })
				})
			}
		}

		componentWillUnmount = () => {
			if (this.onCart) {
				this.onCart.off()
			}
		}

		render = () => <Cart cart={this.state.cart} removeItem={this.removeItem} quantity={this.state.cart && this.state.cart.items ? this.state.cart.items.length : 0} getTotalAmount={this.getTotalAmount} {...this.props} />
	}

	//Get user information from the store
	const mapStateToProps = state => {
	  return {
	    user: state.auth.user
	  }
	}

	return connect(mapStateToProps)(ConnectCart)
}

export default connectCart