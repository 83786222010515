import React from 'react'
import { reduxForm, hasSubmitSucceeded, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { connectAuth } from '../../core/connectors'
import { Form, GdprBanner, Loading } from '../components'
import { TextField } from '../fields'

import logoWhite from '../assets/img/logo_choose_white.svg'


const headerStyle = {
    position: "relative",
    padding: "2rem 1.25rem 0.5rem",
    width: "100%",
    backgroundColor: "#00a483",
    color: "#fff",
    textAlign: "center",
    backgroundSize: "cover"
}

let ResetPasswordForm = props => (
	<Form {...props}>
		<fieldset>
			<TextField label="Mot de passe" type="password" name="password" required />
		</fieldset>
	</Form>
)

ResetPasswordForm = reduxForm({
	form: 'resetPasswordForm'
})(ResetPasswordForm)


class ResetPassword extends React.Component {
	state = {
		isLoading: true,
		codeIsValid: false,
		submitSucceeded: false,
		email: null,
		error: null
	}

	componentDidMount = () => {	
		//Check if the code in the reset link is valid	
		if (this.state.isLoading === true) {
			this.props.verifyPasswordResetCode().then(email => {
				this.setState({
					isLoading: false,
					codeIsValid: true,
					email: email
				})
			}).catch(error => {
				this.setState({
					isLoading: false,
					error: "Le lien n'est plus valide."
				})
			})
		}
	}

	componentDidUpdate = prevProps => {
		//Reset password succeeded
		if (prevProps.submitSucceeded === false && this.props.submitSucceeded === true) {
			this.setState({
				submitSucceeded: true
			})
		}
	}

	handleSubmit = values => {
		return this.props.confirmPasswordReset(values.password).catch(error => {
			throw new SubmissionError({_error: error.message})
		})
	}

	render = () => {
		this.props.setTitle("Réinitialisation du mot de passe")
		this.props.setDescription()

		return (
			<div>
				<GdprBanner />
				<header id="auth-header" style={headerStyle}>
					<h1 style={{color: "#fff"}}>
						<img src={logoWhite} alt="logo" style={{width: "300px"}}/>
					</h1>
				</header>
				{
					this.state.isLoading ? <Loading /> : (
						<div className="container mt-40 mb-40">
							<div className="row">
								<div className="col-sm-offset-3 col-sm-6 col-xs-12 mb-20">
									<h4>Mise à jour de votre mot de passe</h4>
									<div className="login-form" style={{padding: "10px", border: "1px solid #e5e5e5", width: "100%"}}>
										{
											this.state.codeIsValid ? (
												this.state.submitSucceeded ? (
													<div className="alert alert-success" style={{marginBottom: "0px"}}>
														<p>Merci ! Votre nouveau mot de passe a bien été enregistré.</p>
														<p><Link to="/login">Se connecter</Link></p>
													</div>
												) : (
													<React.Fragment>
														<p>Identifiant : {this.state.email}</p>
														<ResetPasswordForm onSubmit={this.handleSubmit} />
													</React.Fragment>
												)
											) : (
												<div className="alert alert-error" style={{marginBottom: "0px"}}>
													<p>{this.state.error}</p>
													<p><Link to="/forgot-password">Cliquez ici pour demander une nouvelle réinitialisation.</Link></p>
												</div>
											)
										}
									</div>
								</div>
							</div>
						</div>
					)
				}	
			</div>
		)
	}
}

const mapStateToProps = state => ({
	submitSucceeded: hasSubmitSucceeded('resetPasswordForm')(state)
})


export default connectAuth(connect(mapStateToProps)(ResetPassword))