import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { update, get } from '../../core/clients/restClient'
import settings from '../../settings'

import axaLogo from '../assets/img/axa_logo_blue.svg'


var currentYear = (new Date()).getFullYear()

class Footer extends React.Component {
	handleStoreLink = (e, storeId) => {
		e.preventDefault()
		get(storeId, '/stores', ["openHours"]).then(store => {
			update(this.props.user.uid, '/users', {store}).then(user => {
				this.props.history.push("/recherche")
			})
		})

	}


	render = () => (
		<footer className="footer footer-type-1">
			<div className="bottom-footer">
				<div className="container">
					<div className="row">
						<div className="col-xs-4 col-xxs-12">
							<ul>
								<li><a href={settings.statics.condition} target="_blank">Contrat d'abonnement</a></li>
							</ul>
						</div>
						<div className="col-xs-4 col-xxs-12">
							<ul>
								<li><a href={settings.statics.contract} target="_blank">Condition de l'offre</a></li>
							</ul>
						</div>
						<div className="col-xs-4 col-xxs-12">
							<ul>
								<li><Link to="/mentions-legales">Informations légales</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default withRouter(connect(mapStateToProps)(Footer))