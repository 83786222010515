import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { 
	login, 
	logout, 
	createUserWithEmailPassword, 
	sendPasswordResetEmail, 
	verifyPasswordResetCode, 
	confirmPasswordReset,
	updatePassword
} from '../clients/authClient'

import { addCurrenUser } from '../actions' 

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCurrenUser: user => {
      dispatch(addCurrenUser(user))
    }
  }
}

const connectAuth = Auth => {

	class ConnectAuth extends React.Component {

		componentWillMount = () => {
			this.query = queryString.parse(this.props.location.search)
		}

		//Login the user
		login = (email, password) => {
			return login(email, password).then(user => {
				//Add the in the store then push the new location
				this.props.addCurrenUser(user)
				return user
			})
		}

		signUpWithEmailPassword = (email, password, userData={}) => {
			return createUserWithEmailPassword(email, password, userData).then(user => {
				this.props.addCurrenUser(user)
				return user
			})
		}

		sendPasswordResetEmail = (email) => {
			return sendPasswordResetEmail(email)
		}

		//Logout the user
		logout = () => {
			return logout().then(() => {
				//Remove the user in the store then push to the login page
		    	this.props.addCurrenUser(null)
		    	this.props.history.push('/login')
		    })
		}

		verifyPasswordResetCode = () => {
			return verifyPasswordResetCode(this.query.oobCode)
		}

		confirmPasswordReset = newPassword => {
			return confirmPasswordReset(this.query.oobCode, newPassword)
		}

		updatePassword = newPassword => {
			return updatePassword(newPassword)
		}

		render = () => (
			<Auth 
				login={this.login} 
				logout={this.logout} 
				signUpWithEmailPassword={this.signUpWithEmailPassword} 
				sendPasswordResetEmail={this.sendPasswordResetEmail} 
				verifyPasswordResetCode={this.verifyPasswordResetCode}
				confirmPasswordReset={this.confirmPasswordReset}
				updatePassword={this.updatePassword}
				{...this.props} />
		)

	}

	return connect(
    	mapStateToProps,
    	mapDispatchToProps
  	)(withRouter(ConnectAuth))
}

export default connectAuth