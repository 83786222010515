import React from 'react'

import '../assets/css/bootstrap.min.css'
import '../assets/css/magnific-popup.css'
import '../assets/css/font-icons.css'
import '../assets/css/sliders.css'
import '../assets/css/react-instantsearch-theme-algolia.css'
import 'react-dates/lib/css/_datepicker.css'
import '../assets/css/react-dates.css'
import '../assets/css/style.css'


 const Wrapper = props => (
	<main className="main-wrapper">
		{props.children}
	</main>
)

 export default Wrapper