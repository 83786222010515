import React from 'react'
import { CardElement } from 'react-stripe-elements'
import { Field } from '../../core/fields'

import '../assets/css/stripe-element.css'


class CardElementFieldComponent extends React.Component {
	onChange = change => {
		//console.log(change)


		this.props.input.onChange(change)

	}

	render = () => {
		const { input, label, helpText, meta: { touched, error }, ...custom } = this.props

		//console.log(touched, 'touched')

		if (input.value === "" && this.stripeElement) {
			this.stripeElement.clear()
		}

		return (
			<div className={"form-group" + (touched && error ? " has-error" : "")}>
				{label && <label className="control-label">{label} <span className="required">*</span></label>}
				<CardElement 
					className="form-control"
					onReady={stripeElement => this.stripeElement = stripeElement}
					hidePostalCode
					onBlur={input.onBlur}
					onFocus={input.onFocus}
					onChange={this.onChange}
					{...custom}
					disabled={custom.disabled} />
				<span className="help-block">{touched && error}</span>
			</div>
		)
	}
}


class CardElementField extends React.Component {
	empty = true
	complete = false
	error = undefined

	cardValidation = value => {
		if (value) {
			const keys = Object.keys(value)

			if (keys.includes('complete') && keys.includes('error') && keys.includes('empty')) {
				this.complete = value.complete
				this.error = value.error
				this.empty = value.empty
			}
		}

		if (this.props.required) {
			if (this.complete === false && this.error) {
				return this.error.message
			} else if (this.empty === true) {
				return "Le champ est obligatoire"
			} else {
				return undefined
			}
		} else {
			if (this.complete === false && this.error) {
				return this.error.message
			} else {
				return undefined
			}
		}
	}

	render = () => <Field component={CardElementFieldComponent} validate={[this.cardValidation]} {...this.props} />
}

export default CardElementField