import React from 'react'
import moment from 'moment'


const deliveryTypes = {
	'togo': {
		pickup: 'Retrait en agence',
		shipping: 'Livraison aller'
	},
	'back': {
		pickup: 'Remise en agence',
		shipping: 'Livraison retour'
	}	
}


const OrderItem = ({item}) => {
	const startDate = moment(item.startDate).format('D/MM/YYYY à HH:mm')
	const endDate = moment(item.endDate).format('D/MM/YYYY à HH:mm')

	return (
		<tr>
			<th style={{padingRight: "20px"}}>
				{item.name}<span className="count"> x {item.quantity}</span>
				<div>Du {startDate}<br />Au {endDate}</div>
			</th>
			<td>
				<span className="amount">{item.totalAmount.toFixed(2)}€</span>
			</td>
		</tr>
	)
}

const DeliveryItem = ({delivery}) => {
		return (
			delivery.amount >= 0 && delivery.amount !== null ? (
				<tr className="shipping">
					<th>{`${deliveryTypes[delivery.code][delivery.type]}`}</th>
					<td>
						<span>{delivery.amount === 0 ? "Gratuit" : `${delivery.amount.toFixed(2)}€`}</span>
					</td>
				</tr>
			) : (
				<tr><th colSpan={2}>Calcul de la livraison en cours</th></tr>
			)
			                    
		)
	}

const DiscountItem = ({discount}) => {
	return (
		<tr>
			<th style={{padingRight: "20px"}}>
				Coupon <small>(code : {discount.code})</small>
			</th>
			<td>
				{discount.valueType === 'fixedAmount' && (
					<span className="amount">-{discount.value.toFixed(2)}€</span>
				)}
				{discount.valueType === 'percentage' && (
					<span className="amount">-{discount.value}%</span>
				)}
			</td>
		</tr>
	)
}

const OrderItems = ({ items, deliveryToGo, deliveryBack, discounts, total }) => (
	<React.Fragment>
		<h2 className="heading uppercase bottom-line full-grey">Votre commande</h2>
		<table className="table shop_table ecommerce-checkout-review-order-table">
			<tbody>
				{items.map((item, index) => <OrderItem key={index} item={item} />)}
				<DeliveryItem delivery={deliveryToGo} />
				<DeliveryItem delivery={deliveryBack} />
				{discounts && discounts.map((discount, index) => <DiscountItem key={index} discount={discount} />)}
				<tr className="order-total">
					<th><strong>Total</strong></th>
					<td>
						<strong><span className="amount">{`${total.toFixed(2)}€`}</span></strong>
					</td>
				</tr>    
			</tbody>
		</table>
	</React.Fragment>
)


export default OrderItems

