import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { Link } from 'react-router-dom'

import { Layout } from '../containers'
import { PageTitle, Loading } from '../components'
import { connectOrder } from '../../order'
import { connectAuth } from '../../core/connectors'

moment.locale('fr')


const Table = ({ children }) => (
	<table className="table">
		{ children }
	</table>
)

const TableItem = ({ item }) => (
	<tr>
		<th scope="row">{item.id}</th>
		<td style={{textAlign: "center"}}>{moment(item.createdAt).format('DD/MM/YYYY à HH:mm')}</td>
		<td style={{textAlign: "center"}}>Paris</td>
		<td style={{textAlign: "center"}}>En attente</td>
		<td style={{textAlign: "center"}}><Link to={`/orders/${item.id}`}>Voir</Link></td>
	</tr>
)

const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/account">Votre compte</Link>
		</li>
		<li className="active">
			Vos commandes en ligne
		</li>
	</ol>
)

class OrderList extends React.Component {
	componentDidMount = () => {
		this.props.getAllOrders(this.props.user.customerId)
	}

	render = () => {
		this.props.setTitle("Vos commandes en ligne - Choose")
		this.props.setDescription()

		return (
			<Layout>
				<PageTitle title="Vos commandes en ligne" />
				{
					this.props.isLoading ? <Loading /> : (
						<div className="content-wrapper oh my-account">
							<div className="container mt-10">
								<Breadcrumb />
							</div>
							<section className="section-wrap checkout pb-70">
								{
									this.props.orders ? (
										<div className="container" style={{color: "#000"}}>
											<div className="row mb-20">
												<div className="col-md-12">
													<h2 className="heading uppercase">
														Commandes effectuées sur le portail
													</h2>
												</div>
											</div>
											<div className="table-wrap">
												<Table>
													<thead> 
														<tr>
															<th style={{fontWeight: "bold"}}>Numéro de commande</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Date</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Agence</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Stutut</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															this.props.orders.map((order, index) => <TableItem key={index} item={order} /> )
														}
													</tbody>
												</Table>
											</div>
										</div>
									) : <div className="container" style={{color: "#000"}}>Pas encore de commande</div>
								}
								
							</section>
						</div>
					)
				}
			</Layout>
		)
	}
}

export default connectOrder(connectAuth(OrderList))