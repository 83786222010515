import React from 'react'
import { connectPage } from '../../page'
import { Layout } from '../containers'
import { Loading, PageTitle } from '../components'

class Page extends React.Component {

	componentWillReceiveProps = nextProps => {
		if (this.props.location !== nextProps.location) {
			this.props.getPage(this.props.pageId)
		}
	}

	componentDidMount = () => {
		this.props.getPage(this.props.pageId)
	}

	render = () => {
		this.props.setTitle(this.props.page && this.props.page.titleTag || null)
		this.props.setDescription()
		return this.props.isLoading ? 
			<Layout><Loading /></Layout> : 
			<Layout>
				<PageTitle title={this.props.page.titleTag} />
				<div className="content-wrapper oh" style={{margin: "40px 0 40px 0"}}>
					<div className="container relative" dangerouslySetInnerHTML={{__html: this.props.page.content}} />
				</div>
			</Layout>
	}	
}

export default connectPage(Page)