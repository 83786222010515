
// const settings = {
//     algolia: {
//         appId: "2ZBYC0JZOO",
//         apikey: "e669f3861b4b5637ac86c2c509a46139"
//     },
//     getPriceUrl: "https://us-central1-rexpress-free-dev.cloudfunctions.net/cart-getPrice",
//     //loxamApiBaseUrl: "http://localhost:5000/rexpress-free-dev/us-central1/api-v1",
//     loxamApiBaseUrl: 'https://us-central1-rexpress-free-dev.cloudfunctions.net/api-v1',
//     stripe: {
//         apiKey: "pk_test_UxtEO8sFI1ou3rYf2NE08OkH"
//     },
//     firebase: {
//         apiKey: "AIzaSyAnAZLxgYBg0kZWR0AwNCkCNayOssRyhZo",
//         authDomain: "rexpress-free-dev.firebaseapp.com",
//         databaseURL: "https://rexpress-free-dev.firebaseio.com",
//         projectId: "rexpress-free-dev",
//         storageBucket: "",
//         messagingSenderId: "586208829613",
//         appId: "1:586208829613:web:029b01950a39bd4b"
//     },
//     ganalytics: {
//         id: "UA-39792893-7"
//     },
//     storeCities: {
//         paris: "-Lnpye4MQjOkFpCnHsCd"
//     },
//     sentry: {
//         dsn: "https://7dbcae58975c41358f1b2ea136c6cfa5@sentry.io/1105596"
//     },
//     stores: {
//         "-Lnpye4MQjOkFpCnHsCd": {
//             shippingArea: "dans toute l’Île-de-France",
//             address: "89 avenue de la Grande-Armée, 75016 Paris",
//             city: "Paris"
//         }
//     },
//     // intercom: {
//     //    id: 'xxsuc36z'
//     // },
//     // recombee: {
//     //     id: 'e-loue-express',
//     //     token: '0rQR0lpr7VbWDSB0S3j0NcZvrzl2gXcElL3gRCCX6MiPb5k0hQ1cM4forZsUfMDR'
//     // },
//     pages: [
//         {pageId: "-L9eaT-hMciiflXTPIj-", permalink: "/cgv"},
//         {pageId: "-L9fYsnypKgQ8AqqwRIM", permalink: "/notre-concept"},
//         {pageId: "-L9fZoM7VjQQsBI6P1ap", permalink: "/mentions-legales"},
//         {pageId: "-L9f_USGqwI4VWxQOaEW", permalink: "/faq"},
//         {pageId: "-Lqoxnxuta0bS3HCbUSC", permalink: "/cgu"}
//     ],
//     statics: {
//         condition: "https://firebasestorage.googleapis.com/v0/b/rexpress-free-dev.appspot.com/o/static%2Fcondition_offre_v1.pdf?alt=media&token=38ec6f88-5747-4857-92d1-b97b72d3c6dc",
//         contract: "https://firebasestorage.googleapis.com/v0/b/rexpress-free-dev.appspot.com/o/static%2Fcontract_abo_v1.pdf?alt=media&token=7bb84834-3d8a-4c14-a6d8-96e70fa37e7a"
//     },
//     release: "1.0.0"
// }

const settings = {
    algolia: {
        appId: "1AS78EHRET",
        apikey: "c9b7a80740de4c6fa1d2a540485ec9a2"
    },
    getPriceUrl: "https://us-central1-rexpress-free.cloudfunctions.net/cart-getPrice",
    //loxamApiBaseUrl: "http://localhost:5000/rexpress-free-dev/us-central1/api-v1",
    loxamApiBaseUrl: 'https://us-central1-rexpress-free.cloudfunctions.net/api-v1',
    stripe: {
        apiKey: "pk_test_UxtEO8sFI1ou3rYf2NE08OkH"
    },
    firebase: {
        apiKey: "AIzaSyAsBUW_7PxySfxQBBr7Q4zBCYio2tcdSE0",
        authDomain: "rexpress-free.firebaseapp.com",
        databaseURL: "https://rexpress-free.firebaseio.com",
        projectId: "rexpress-free",
        storageBucket: "",
        messagingSenderId: "380779688279",
        appId: "1:380779688279:web:d7e65ca30b9c08d4c48e12"
    },
    ganalytics: {
        id: "UA-39792893-7"
    },
    storeCities: {
        paris: "-Lnpye4MQjOkFpCnHsCd"
    },
    sentry: {
        dsn: "https://7dbcae58975c41358f1b2ea136c6cfa5@sentry.io/1105596"
    },
    stores: {
        "-Lnpye4MQjOkFpCnHsCd": {
            shippingArea: "dans toute l’Île-de-France",
            address: "89 avenue de la Grande-Armée, 75016 Paris",
            city: "Paris"
        }
    },
    // intercom: {
    //    id: 'xxsuc36z'
    // },
    // recombee: {
    //     id: 'e-loue-express',
    //     token: '0rQR0lpr7VbWDSB0S3j0NcZvrzl2gXcElL3gRCCX6MiPb5k0hQ1cM4forZsUfMDR'
    // },
    pages: [
        {pageId: "-L9eaT-hMciiflXTPIj-", permalink: "/cgv"},
        {pageId: "-L9fYsnypKgQ8AqqwRIM", permalink: "/notre-concept"},
        {pageId: "-L9fZoM7VjQQsBI6P1ap", permalink: "/mentions-legales"},
        {pageId: "-L9f_USGqwI4VWxQOaEW", permalink: "/faq"},
        {pageId: "-Lqoxnxuta0bS3HCbUSC", permalink: "/cgu"}
    ],
    statics: {
        condition: "https://firebasestorage.googleapis.com/v0/b/rexpress-free.appspot.com/o/statics%2Fcondition_offre_v1.pdf?alt=media&token=eaf26317-42f9-48b6-8359-ccf7ab6e573d",
        contract: "https://firebasestorage.googleapis.com/v0/b/rexpress-free.appspot.com/o/statics%2Fcontract_abo_v1.pdf?alt=media&token=18ae8d66-84dc-425a-affc-1486d74ed23e"
    },
    release: "1.0.0"
}



//old one
// const settings = {
//     algolia: {
//         appId: "RT5OZDEAB8",
//         apikey: "46ff6c7171c49bd445526f09d1ac3393"
//     },
//     getPriceUrl: "https://us-central1-rexpress-prod.cloudfunctions.net/cart-getPrice",
//     stripe: {
//         apiKey: "pk_live_YL8DZmqXc8N0VXnSSG11RCDe"
//     },
//     firebase: {
//         apiKey: "AIzaSyB27Ejuy0F_v5STRTMEuX60c8P9INnAT5c",
//         authDomain: "rexpress-prod.firebaseapp.com",
//         databaseURL: "https://rexpress-prod.firebaseio.com",
//         projectId: "rexpress-prod",
//         storageBucket: "rexpress-prod.appspot.com",
//         messagingSenderId: "746894847838"
//     },
//     ganalytics: {
//         id: "UA-8258979-11"
//     },
//     storeCities: {
//         paris: "-L8X1lwadcJx8LIGxbKK",
//         marseille: "-L8faddYuxuSswWc98_d",
//         lyon: "-L8geN84QIYABRdDKA8U",
//         hammel: "-LLmwMTZ8nLP9W7IKTU9"

//     },
//     sentry: {
//         dsn: "https://3a34b64f80a4432fb29f098ca8434aaf@sentry.io/1107009"
//     },
//     stores: {
//         "-L8X1lwadcJx8LIGxbKK": {
//             shippingArea: "dans toute l’Île-de-France",
//             address: "16 avenue Emile Zola, 75015 Paris",
//             city: "Paris"
//         },
//         "-L8faddYuxuSswWc98_d": {
//             shippingArea: "dans toute l'agglomération de Marseille",
//             address: "33 rue neuve sainte catherine, 13007 Marseille",
//             city: "Marseille"
//         },
//         "-L8geN84QIYABRdDKA8U": {
//             shippingArea: "dans l'agglomération de Lyon",
//             address: "6 rue notre dame, 69006 Lyon",
//             city: "Lyon"
//         },
//         "-LLmwMTZ8nLP9W7IKTU9": {
//             shippingArea: "dans toute l’Île-de-France",
//             address: "16 avenue Emile Zola, 75015 Paris",
//             city: "Paris"
//         }  
//     },
//     release: "3.0.0",
//     intercom: {
//         id: 'xxsuc36z'
//     },
//     recombee: {
//         id: 'e-loue-express',
//         token: '0rQR0lpr7VbWDSB0S3j0NcZvrzl2gXcElL3gRCCX6MiPb5k0hQ1cM4forZsUfMDR'
//     },
//     pages: [
//         {pageId: "-L9eaT-hMciiflXTPIj-", permalink: "/cgv"},
//         {pageId: "-L9fYsnypKgQ8AqqwRIM", permalink: "/notre-concept"},
//         {pageId: "-L9fZoM7VjQQsBI6P1ap", permalink: "/mentions-legales"},
//         {pageId: "-L9f_USGqwI4VWxQOaEW", permalink: "/faq"},
//     ]
// }


export default Object.freeze(settings)
