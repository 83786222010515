import React from 'react'
import { reduxForm } from 'redux-form'
import { Layout } from '../containers'
import { PageTitle, Loading, Form, OrderItems, PaymentMethodModal } from '../components'
import { RadioField } from '../fields'
import { connectOrder } from '../../order'


class PayOrderForm extends React.Component {
	state = {
		show: false
	}

	toggle = () => {
		this.setState({show: !this.state.show})
	}

	getCardInfo = source => `${source.card.brand} xxxx xxxx xxxx ${source.card.last4} ${source.card.exp_month}/${source.card.exp_year}`

	render = () => {
		const sources = this.props.customer && this.props.customer.sources ? this.props.customer.sources.filter(source => source.status === "chargeable" && source.error === undefined) : null

		return (
			<React.Fragment>
				<Form {...this.props} saveButton={false} >
					<div className="checkout ecommerce-checkout row">
						<div className="col-md-8">
							<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-credit-card"></i> Paiement sécurisé par carte bancaire</h2>
							{
								this.props.customer ?
									sources ? (
										<React.Fragment>
											<ul className="radio-buttons mb-40">
												{
													sources.map((source, index) => <li key={index}><RadioField name="paymentMethod" value={source.id} label={this.getCardInfo(source)} /></li>)
												}
											</ul>
											<a href="#paymentMethodModal" onClick={e => {e.preventDefault(); this.toggle();}}>{sources ? "Ajouter une nouvelle carte de paiement" : "Ajouter une carte de paiement"}</a>
										</React.Fragment>
									) : (
										<p>
											Afin de procéder au paiement merci d'ajouter une carte de paiement<br />
											<a href="#paymentMethodModal" onClick={e => {e.preventDefault(); this.toggle();}}>Ajouter une carte de paiement</a>
										</p>
									) : <p>Chargement...</p>
							}
						</div>
						<div className="col-md-4">
							<div className="order-review-wrap ecommerce-checkout-review-order" id="order_review">
								<OrderItems items={this.props.order.items} deliveryToGo={this.props.order.deliveries[0]} deliveryBack={this.props.order.deliveries[1]} total={this.props.order.total} />
								<div id="payment" className="ecommerce-checkout-payment">
									<div className="form-row place-order">
										<button className="btn btn-lg btn-dark" style={{width: "100%"}}><span>Procéder au paiement</span></button>
										<p style={{textAlign: "center", fontSize: "12px", marginTop: "20px"}}><i className="fa fa-lock"></i> Paiement 100% sécurisé</p>
			                  		</div>
			                	</div>
							</div>
						</div>
					</div>
				</Form>
				<PaymentMethodModal show={this.state.show} toggle={this.toggle} createSource={this.props.createSource} paymentForm="paymentForm" />
			</React.Fragment>
		)
	}
}

PayOrderForm = reduxForm({
	form: 'paymentForm',
})(PayOrderForm)


class PayOrder extends React.Component {
	state = {
		isLoading: true,
		sources: null,
		initialValues: null
	}

	handleSubmit = values => {
		const source = this.props.customer.sources.filter(source => source.id === values.paymentMethod)[0]

		const data = { paymentMethod: source }

		return this.props.updateOrder(this.props.order.id, data).then(order => {
			return this.props.createCharge(order).then(charge => {
				this.props.history.push(`/payments/${charge.id}`)
			})
		})
	}

	componentDidMount = () => {
		this.props.getOrder(this.props.match.params.orderId).then(order => {
			this.props.getCustomer(order.customerId).then(customer => {
				const defaultPaymentMethod = customer.sources ? customer.sources.filter(source => source.status === "chargeable")[0] : null

				this.setState({
					isLoading: false,
					sources: customer.sources || null,
					initialValues: {paymentMethod: defaultPaymentMethod ? defaultPaymentMethod.id : null}
				})
			})
		})
	} 

	render = () => {
		this.props.setTitle("Procéder au paiement - Choose by Loxam")
		this.props.setDescription()
		return (
			this.state.isLoading ? <Loading /> : (
				<Layout navLeftComponent={<div></div>}>
					<PageTitle title="Payer la commande" />
					<div className="content-wrapper oh">
						<section className="section-wrap checkout pb-70">
							<div className="container relative">
								<div className="row">
									<div className="ecommerce col-xs-12">
										<PayOrderForm onSubmit={this.handleSubmit} initialValues={this.state.initialValues} order={this.props.order} customer={this.props.customer} createSource={this.props.createSource} />
									</div>
								</div>
							</div>
						</section>
					</div>
				</Layout>
			)
		)
	}
}


export default connectOrder(PayOrder)