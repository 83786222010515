import React from 'react'
import moment from 'moment'
import { Link, Redirect } from 'react-router-dom'

import { Layout } from '../containers'
import { PageTitle } from '../components'
import { connectCart } from '../../order'

const CartItem = ({item, removeItem}) => {
	const startDate = moment(item.startDate).format('D/MM/YYYY à HH:mm')
	const endDate = moment (item.endDate).format('D/MM/YYYY à HH:mm')
	return (
		<tr className="cart_item">
			<td className="product-thumbnail">
				<Link to={item.slug} >
					<img src={item.picture} alt="" />
				</Link>
			</td>
			<td className="product-name">
				<Link to={item.slug} >{item.name}</Link>
				<ul>
					<li>Du {startDate}<br />Au {endDate}</li>
				</ul>
			</td>
			<td className="product-quantity">
				<span>{item.quantity}</span>
			</td>
			<td className="product-subtotal">
				<span className="amount">{item.totalAmount === 0 ? "Inclus dans le forfait" : `${item.totalAmount.toFixed(2)}€`}</span>
			</td>
			<td className="product-remove">
				<a href="#removeItem" onClick={e => {e.preventDefault(); removeItem(item.id); }} className="remove" title="Remove this item">
					<i className="ui-close"></i>
				</a>
			</td>
		</tr>
	)
}

const ActionsLinks = ({ handleCheckoutRedirection }) => (
	<div className="row cart-actions">
		<div className="col-xs-6">
			<Link  to="/recherche" className="btn btn-lg btn-dark"><span>Ajouter un article</span></Link>
		</div>
		<div className="col-xs-6">
			<div className="wc-proceed-to-checkout pull-right">
				<a href="#checkout" onClick={handleCheckoutRedirection} className="btn btn-lg btn-color"><span>Passer la commande</span></a>
			</div>
		</div>
	</div>
)

const CartItems = ({cart, removeItem, handleCheckoutRedirection}) => (
	<React.Fragment>
		<ActionsLinks handleCheckoutRedirection={handleCheckoutRedirection} />
		<div className="row">
			<div className="col-md-12">
				<div className="table-wrap mb-30">
					<table className="shop_table cart table">
						<thead>
							<tr>
								<th className="product-name" colSpan="2">Annonce de location</th>
								<th className="product-quantity">Quantité</th>
								<th className="product-subtotal" colSpan="2">Total</th>
							</tr>
						</thead>
						<tbody>
							{ cart.items.map((item, index) => <CartItem key={index} item={item} removeItem={removeItem} />) }
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-md-offset-6 col-md-6">
				<div className="cart_totals">
					<h2 className="heading relative bottom-line full-grey uppercase mb-30">Total du panier</h2>
					<table className="table shop_table">
						<tbody>
							<tr className="order-total">
								<th>Total HT</th>
								<td>
									<strong><span className="amount">{cart.total && (cart.total/1.2).toFixed(2)}€</span></strong>
								</td>
                            </tr>
                            <tr className="order-total">
								<th>Total</th>
								<td>
									<strong><span className="amount">{cart.total && cart.total.toFixed(2)}€</span></strong>
								</td>
                            </tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<ActionsLinks handleCheckoutRedirection={handleCheckoutRedirection} />
	</React.Fragment>
)

const EmptyCart = () => (
	<div className="row">
		<div className="col-xs-12" style={{textAlign: "center"}}>
			<p>Votre panier est vide.</p>
			<Link  to="/recherche" className="btn btn-lg btn-color"><span>Ajouter un article</span></Link>
		</div>
	</div>
)

class Cart extends React.Component {
	state = {
		redirectToCheckout: false
	}

	handleCheckoutRedirection = e => {
		e.preventDefault()

		this.setState({ redirectToCheckout: true })
	}

	render = () => {
		const { cart, removeItem, quantity } = this.props
		const amountUpdated = cart && cart.items && cart.items.length > 0 ? this.props.getTotalAmount() === cart.total : false

		this.props.setTitle('Panier - Choose by Loxam')
		this.props.setDescription()
		
		return (
			this.state.redirectToCheckout === true && amountUpdated === true ? <Redirect to="/checkout/1" /> : (
				<Layout>
					<PageTitle title="Panier" />
					<div className="content-wrapper oh">
						<section className="section-wrap shopping-cart">
							<div className={`container relative be-loading ${this.state.redirectToCheckout ? "be-loading-active" : ""}`}>
								{quantity > 0 ? <CartItems cart={cart} removeItem={removeItem} handleCheckoutRedirection={this.handleCheckoutRedirection} /> : <EmptyCart />}
							</div>
						</section>
					</div>
				</Layout>
			)
		)
	}
}

export default connectCart(Cart)
