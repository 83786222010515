import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { Layout } from '../containers'
import { Loading, PageTitle, OrderItems } from '../components'
import { ErrorPage, NotMatchPage } from '../views'

import { connectOrder } from '../../order'


const Address = ({address}) => (
	<React.Fragment>
		{address.firstName} {address.lastName}<br />
		{address.company && (<React.Fragment>{address.company}<br/></React.Fragment>)}
		{address.address.address1}<br />
		{address.address.address2 && (<React.Fragment>{address.address.address2}<br/></React.Fragment>)}
		{address.address.zipcode} {address.address.city}
	</React.Fragment>
)


const OrderState = ({state, customer}) => {
	let orderState = (
		<div className="mb-30">Chargement...</div>
	)
	
	if (customer) {
		switch (state) {
			case 'created':
				orderState = (
					<div className="mb-30">
						<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-check"></i> Merci {customer.firstName}, votre commande a été passé</h2>
						<p>Nous vous confirmons que nous avons accepté votre commande.<br />Un email de confirmation a été envoyé à l'adresse {customer.email}.</p>
					</div>
				)
				break
			default:
				orderState = null
		}
	}

	return orderState
}


const Contact = ({ customer }) => (
	customer ? (
		<React.Fragment>
    		{customer.firstName} {customer.lastName}<br />
    		{customer.email}<br />
    		{customer.phone}
		</React.Fragment>
	) : (<React.Fragment>Chargement...</React.Fragment>)
)


const BillingAddress = ({billingAddress}) => (
	<React.Fragment>
        <p><strong>Adresse de facturation</strong></p>
        <address>
          <Address address={billingAddress} />
        </address>
	</React.Fragment>
)




const PaymentMethod = ({paymentMethod, charges, orderId}) => {
	const capturedCharge = (charges && charges.filter(charge => charge.captured === true)) || null
	const charge = capturedCharge ? capturedCharge[0] : null
	return (
		<div className="col-sm-4">
			<p><strong>Mode de paiement</strong></p>
			<p>{`${paymentMethod.card.brand} xxxx xxxx xxxx ${paymentMethod.card.last4} ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</p>
			{
				charges ? 
				charge ?
					<p>Paiement effectué {moment.unix(charge.created).format('D/MM/YYYY à HH:mm')}</p> : 
					<p>
						Pas encore de paiement<br />
						<Link to={`/orders/${orderId}/pay`}>Effectuer le paiement</Link>
					</p> :
				<p>Chargement...</p>
			}
		</div>
	)
}


const CustomerInfo = ({customer, billingAddress, paymentMethod, charges, orderId}) => (
	<div className="mb-30">
		<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-user"></i> Informations client</h2>
		<div className="row">
			<div className="col-sm-6">
				<Contact customer={customer} />
			</div>
		</div>
	</div>
)


class StoreAddress extends React.Component {
	state = {
		address: null
	}

	componentDidMount = () => {
		this.props.getStore(this.props.storeId).then(store => {
			const address = {
				firstName: store.name,
				address: store.address
			}
			this.setState({ address })
		})
	}

	render = () => {
		return (
			this.state.address ? (
				<address>
					<Address address={this.state.address} />
				</address>
			) : <p>Chargement...</p>
		)
	}
}


const ShippingAddress = ({ address }) => (
	<address>
		<Address address={address} />
	</address>
)


class Deliveries extends React.Component {

	render = () => {
		const deliveryToGo = this.props.deliveries[0]
		const deliveryBack = this.props.deliveries[1]

		const content = {
			deliveryToGo: {
				pickup: {
					icon: 'fa fa-map-marker',
					label: "Retrait en agence",
					description: "Vous pouvez récupérer votre matériel loué dans notre agence à partir de l’heure de début de location. Afin de retirer le matériel, une pièce d’identité vous sera demandée, c’est tout."
				},
				shipping: {
					icon: 'fa fa-truck',
					label: "Adresse de livraison"
				}
			},
			deliveryBack: {
				pickup: {
					icon: 'fa fa-map-marker',
					label: "Remise en agence",
					description: "Vous pouvez rendre votre matériel loué dans notre agence avant l’heure de fin de location."
				},
				shipping: {
					icon: 'fa fa-truck',
					label: "Adresse de retrait"
				}
			}
		}

		return (
			<div className="mb-30">
				<div className="row">
					<div className="col-sm-6 mb-40">
						<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-arrow-right"></i> Livraison aller</h2>
						<p><strong><i className={content.deliveryToGo[deliveryToGo.type].icon}></i> {content.deliveryToGo[deliveryToGo.type].label}</strong></p>
						{deliveryToGo.type === 'pickup' && <StoreAddress getStore={this.props.getStore} storeId={deliveryToGo.storeId} />}
						{deliveryToGo.type === 'shipping' && <ShippingAddress address={deliveryToGo.destination} />}
						{
							deliveryToGo.type === 'shipping' && (
								<ul className="mt-20">
									<li>Date de livraison : <strong>{moment(deliveryToGo.dateTime).format('DD/MM/YYYY')}</strong></li>
									<li>Tranche horraire de livraison : <strong>{deliveryToGo.timeRange}</strong></li>
								</ul>
							)
						}
					</div>
					<div className="col-sm-6 mb-40">
						<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-arrow-left"></i> Livraison retour</h2>
						<p><strong><i className={content.deliveryBack[deliveryBack.type].icon}></i> {content.deliveryBack[deliveryBack.type].label}</strong></p>
						{deliveryBack.type === 'pickup' && <StoreAddress getStore={this.props.getStore} storeId={deliveryBack.storeId} />}
						{deliveryBack.type === 'shipping' && <ShippingAddress address={deliveryBack.origin} />}
						{
							deliveryBack.type === 'shipping' && (
								<ul className="mt-20">
									<li>Date de retrait : <strong>{moment(deliveryBack.dateTime).format('DD/MM/YYYY')}</strong></li>
									<li>Tranche horraire de livraison : <strong>{deliveryBack.timeRange}</strong></li>
								</ul>
							)
						}
					</div>
				</div>
			</div>
		)
	}
}


const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/account">Votre compte</Link>
		</li>
		<li>
			<Link to="/account/orders">Vos commandes en lignes</Link>
		</li>
		<li className="active">
			Récapitulatif de la commande
		</li>
	</ol>
)


class SingleOrder extends React.Component {
	componentDidUpdate = (prevProps, prevState, snapshot) => {
		if (prevProps.match.params.orderId !== this.props.match.params.orderId) {
			this.props.getOrder(this.props.match.params.orderId)
		}
	}

	componentDidMount = () => {
		this.props.getOrder(this.props.match.params.orderId)
	}

	renderError = () => this.props.error.statusCode === 404 ? <NotMatchPage /> : <ErrorPage />

	render = () => {
		return (
			this.props.isLoading ? <Loading /> : this.props.error ? this.renderError() :
			<Layout hideBanner={true}>
				<PageTitle title="Récapitulatif de la commande" />
				<div className="content-wrapper oh my-account">
					<div className="container mt-10">
						<Breadcrumb />
					</div>
					<section className="section-wrap checkout pb-70">
						<div className="container relative">
							<div className="checkout ecommerce-checkout row">
								<div className="ecommerce col-md-8">
									<OrderState state={this.props.order.status} customer={this.props.customer} />
									<CustomerInfo customer={this.props.customer} orderId={this.props.order.id} billingAddress={this.props.order.billingAddress} paymentMethod={this.props.order.paymentMethod} charges={this.props.charges} />
									<Deliveries deliveries={this.props.order.deliveries} getStore={this.props.getStore} />
								</div>
								<div className="col-md-4">
									<div className="order-review-wrap ecommerce-checkout-review-order" id="order_review">
										<OrderItems items={this.props.order.items} deliveryToGo={this.props.order.deliveries[0]} deliveryBack={this.props.order.deliveries[1]} discounts={this.props.order.discounts} total={this.props.order.total} />
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

export default connectOrder(SingleOrder)