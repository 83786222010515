import React from 'react'

const PressAboutUs = () => (
	<div className="el-panel parlent-de-nous">
		<img src="/img/medias/tf1.png" alt="logo TF1" />
		<img src="/img/medias/france2.png" alt="logo France 2" />
		<img src="/img/medias/france3.png" alt="logo France 3" />
		<img src="/img/medias/m6.png" alt="logo M6" />
		<img src="/img/medias/rtl.png" alt="logo RTL" />
		<img src="/img/medias/rmc.png" alt="logo RMC" />
		<img src="/img/medias/europe1.png" alt="logo Europe 1" />
		<img src="/img/medias/leparisien.png" alt="logo LeParisien" />
		<img src="/img/medias/lemonde.png" alt="logo Le monde" />
		<img src="/img/medias/lefigaro.png" alt="logo LeFigaro" />
	</div>
)

export default PressAboutUs