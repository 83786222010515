import React from 'react'

import { getCustomer } from '../'
import { getAll, get, on, update, create } from '../../core/clients/restClient'

 const connectOrder = Order => {

 	class ConnectOrder extends React.Component {
 		state = {
 			isLoading: true,
 			order: null,
 			error: null,
 			customer: null,
 			charges: null,
 			orders: []
 		}

 		getStore = storeId => {
 			return get(storeId, "/stores")
 		}

 		componentDidUpdate = (prevProps, prevState, snapshot) => {
 			//Listen customer
 			const customerId = (this.state.order && this.state.order.customerId) || null
 			const prevCustomerId = (prevState.order && prevState.order.customerId) || null
 			if ( customerId !== prevCustomerId ) {
 				if (this.customerRef) {
 					this.customerRef.off()
 				}

 				this.customerRef = on(`/customers/${customerId}`, customer => {
 					this.setState({ customer })
 				}, ["addresses", "sources"])
 			}

 			//Listen charges with pending status
 			const charges = (this.state.order && this.state.order.charges) || []
 			const prevCharges = (prevState.order && prevState.order.charges) || []
 			if ( charges.length !== prevCharges.length ) {
 				
 				const chargePromises = charges.map(charge => get(charge.id, `/charges`))

 				Promise.all(chargePromises).then(result => {
 					this.setState({ charges: result })
 				})
 			}

 		}

 		componentWillUnmount = () => {
 			if (this.customerRef) {
 				this.customerRef.off()
 			}
 		}

 		getCustomer = id => {
 			return get(id, '/customers', ["addresses", "sources"]).then(customer => {
 				this.setState({ customer })
 				return customer
 			})
 		}

 		getAllOrders = userId => {
 			this.setState({isLoading: true, orders: null})
 			return getAll("/orders", {
 				filters: [{field: "customerId", operator: "==", value: userId}]
 			}).then(result => {
 				this.setState({ orders: result.docs, isLoading: false})
 				return result.docs
 			}).catch(err => {
 				this.setState({error: {statusCode: 404}, isLoading: false})
 				return err
 			})
 		}

 		getOrder = id => {
 			this.setState({isLoading: true, order: null})
 			return get(id, '/orders', ["items", "charges", "deliveries", "discounts"]).then(order => {
 				this.setState({order: order, error: null, isLoading: false })
 				return order
 			}).catch(err => { 
 				this.setState({error: {statusCode: 404}, isLoading: false}) 
 				return err
 			})
 		}

 		updateOrder = (id, data) => {
 			return update(id, '/orders', data, ["items", "charges", "deliveries"])
 		}

 		createCharge = order => {
			return create('/charges', {orderId: order.id, amount: parseInt(order.total*100, 10)}).then(charge => {
				const data = {
					source: order.paymentMethod,
					returnUrl: `${window.location.protocol}//${window.location.host}/payments/${charge.id}`
				}

				return update(charge.id, `/charges`, data).then(charge => charge)

			}) 
		}

		createSource = token => {
			return create(`/customers/${this.state.order.customerId}/sources`, { token }).then(source => {
				//Waitin Stripe source creation
				return new Promise((resolve, reject) => {
					this.sourceRef = on(`/customers/${this.state.order.customerId}/sources/${source.id}`, source => {
						
						//return the source if the source is chargeable
						if (source.status === "chargeable") {
							return getCustomer(this.state.order.customerId).then(customer => {
								this.setState({customer: customer}, () => {
									this.sourceRef.off()
									resolve(source)
								})
							}) 
						} 

						if (source.error) {
							return getCustomer(this.state.order.customerId).then(customer => {
								this.setState({customer: customer}, () => {
									this.sourceRef.off()
									reject(source.error)
								})
							})
						}
					})
				})
			})
		}

 		render = () => <Order 
 							isLoading={this.state.isLoading}
 							orders={this.state.orders}
 							order={this.state.order} 
 							error={this.state.error}
 							customer={this.state.customer} 
 							charges={this.state.charges} 
 							getOrder={this.getOrder}
 							getCustomer={this.getCustomer}
 							getStore={this.getStore}
 							updateOrder={this.updateOrder}
 							createCharge={this.createCharge}
 							createSource={this.createSource}
 							getAllOrders={this.getAllOrders}
 							{...this.props} />
 	}

 	return ConnectOrder
 }

 export default connectOrder