import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
])

class MainRoute extends React.Component {
	setTitle = title => {
		document.title = title || "Location de matériels illimités - Choose by Loxam"
	}

	setDescription = description => {
		document.description = description || "Artisans, louez vos matériels en illimités chez Loxam pour 199€/mois - Option livraison express"
	}

	render = () => {
		const { component: Component, render, children, ...props } = this.props

		return (
			<Route {...props} render={props => {
				if (Component) {
					return <Component setTitle={this.setTitle} setDescription={this.setDescription} {...props} /> 
				}
				if (render) {
					return render({setTitle: this.setTitle, setDescription: this.setDescription, ...props})
				}
				if (children) {
					return children({setTitle: this.setTitle, setDescription: this.setDescription, ...props})
				}
			}
		} />
		)
	}
}

MainRoute.propTypes = {
	component: componentPropType,
	render: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node
	])
}

export default MainRoute