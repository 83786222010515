import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import settings from '../../settings'
import { update, get } from '../../core/clients/restClient'
import  { Layout } from '../containers'
import { PressAboutUs, HowToDo, Advantage } from '../components'

//icons
import security from '../assets/img/security.svg'
import drill from '../assets/img/drill.svg'
import ladder from '../assets/img/ladder.svg'
import energy from '../assets/img/energy.svg'
import transport from '../assets/img/transport.svg'
import mower from '../assets/img/mower.svg'
import jackhammer from '../assets/img/jackhammer.svg'
import road from '../assets/img/road.svg'

class TopContent extends React.Component {
	componentDidMount = () => {
		//Init top content slider
        window.$("#owl-hero").owlCarousel({
            autoPlay: 3000,
            navigation: true,
            navigationText: ["<i className='ui-left-arrow'></i>", "<i className='ui-right-arrow'></i>"],
            slideSpeed: 300,
            pagination: true,
            paginationSpeed: 400,
            singleItem: true,
            stopOnHover: true,
            addClassActive: true
        })
	}

	render = () => (
		<section className="hero-wrap text-center relative">
			<div id="owl-hero" className="owl-carousel owl-theme light-arrows slider-animated">
				<div className="hero-slide overlay" style={{backgroundImage: "url(img/slide-choose.jpg)"}}>
					<div className="container">
						<div className="hero-holder">
							<div className="hero-message hero-search">
								<h1 className="hero-title nocaps"><small>Artisans, louez vos matériels en illimité*, et bénéficiez d'une remise de 30% sur le reste de la gamme Loxam</small></h1>
								<Link to="/recherche" className="see-more-annoces">Rechercher un matériel</Link>
								<div className="insurance" style={{color: "#fff", position: "absolute", bottom: "10px", left: "0", textAlign: "center", width: "100%", fontSize: "12px"}} >
									* Limité 3 machines simultanées au sein de la Gamme « indispensables artisans »
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

const TopContentContainer = connect(mapStateToProps)(TopContent)

const ChristmasGift = () => (
	<section className="newsletter">
		<div className="container">
			<div className="row">
				<div className="col-sm-12 text-center">
					<h4>Du matériel dort dans votre placard ?</h4>
					<p>Confiez-le nous en dépôt-location</p>
				</div>
			</div>
		</div>
	</section>
)

const How = () => (
	<section className="el-panel panel-first home-blocs">
		<div className="container">
			<div className="row-fluid row-same-height">
				<div className="col-sm-6 col-xs-12">
					<div className="home-bloc">
						<h2>Choose s'engage pour vous</h2>
		            	<p>
		            		Une disponibilité garantie des machines sous un délai de 48h<br />
		            		L’assistance et la réparation incluses en cas de panne<br />
		            		La livraison du matériel sur votre chantier<br />
		            		Des tarifs de location préférentiels pour les matériels hors de la gamme artisans<br />
		            	</p>
		          	</div>
		        </div>
		        <div className="col-sm-6 col-xs-12">
					<div className="home-bloc">
						<h2>La garantie sérénité</h2>
			            <p>
			            	Louez autant de machines que vous le souhaitez dans la limite de 3 machines simultanées<br />
							Au-delà de la 3eme machine simultanée vous serez facturé 30€/mois supplémentaires par machine<br />
							Gardez les machines autant que vous le souhaitez<br />
			            </p>
		          	</div>
		        </div>
			</div>
		</div>
  	</section>
)

const Faq = () => (
	<section className="el-panel faq-blocs">
		<div className="container">
	      	<h2>Questions fréquentes</h2>
	      	<div className="row">
	        	<div className="col-sm-6 col-xs-12">
	          		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-wifi"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Comment fonctionne E-loue Express ?</h3>
	              			<p>Vous réservez un ou plusieurs produits à louer sur le site et vous payez en ligne. Soit vous venez récupérer vos produits dans notre magasin, soit vous vous faîtes livrer.</p>
	            		</div>
	          		</div>
	        	</div>
	        	<div className="col-sm-6 col-xs-12">
	          		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-pin"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Où est disponible E-loue Express ?</h3>
	              			<p>E-Loue Express est disponible dans toute l’île de France.</p>
	            		</div>
	          		</div>
	        	</div>
	      	</div>
	      	<div className="row">
	        	<div className="col-sm-6 col-xs-12">
	         		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-cart"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Que puis-je louer E-loue Express ?</h3>
	              			<p>E-loue express propose des centaines de produits différents à louer : photo et vidéo, beauté, high-tech, puériculture, accessoire auto, évènementiel et bien plus encore.</p>
	            		</div>
	          		</div>
	        	</div>
	        	<div className="col-sm-6 col-xs-12">
					<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-time"></i>
	           			</div>
	            		<div className="faq-bloc-info">
	              			<h3>Quand livre E-loue Express ?</h3>
	              			<p>E-loue express livre tous les jours, week-end compris. De même notre point retrait est ouvert 6j/7.</p>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)

const CategorieLinks = () => (
	<section className="el-panel home-top-categories">
		<div className="container">
			<div className="row row-same-height">
				<div className="col-sm-3 col-xs-6 col-xxs-12">
					<h3>Top high tech</h3>
					<ul>
						<li><Link to="/photographie-and-video/location--Km_tr2JYMRXRV59tbUd">Location canon 5d mark 4</Link></li>
						<li><Link to="/photographie-and-video/location--Km_rV-YzFXiFRRhiW9j">Location canon 5d mark 3</Link></li>
						<li><Link to="/photographie-and-video/location--KtpEZvnkGY17x1Bp30d">Location drone Phantom4</Link></li>
						<li><Link to="/photographie-and-video/location--KmaYpPcItimW3vSNS4q">Location drone Mavic Pro</Link></li>
						<li><Link to="/photographie-and-video/location--KncdYJLb5hKPeMFUoFm">Location objectif 24 105</Link></li>
						<li><Link to="/photographie-and-video/location--KmVgXJJ17l5NbgHkhiJ">Location objectif 70 200</Link></li>
						<li><Link to="/recherche?query=polaroid&page=1&configure%5BhitsPerPage%5D=21">Location polaroid</Link></li>
						<li><Link to="/recherche?query=videoprojecteur&page=1&configure%5BhitsPerPage%5D=21">Location vidéoprojecteur</Link></li>
						<li><Link to="/recherche?query=enceintes&page=1&configure%5BhitsPerPage%5D=21">Location enceintes</Link></li>
						<li><Link to="/recherche?query=ordinateur&page=1&configure%5BhitsPerPage%5D=21">Location ordinateur</Link></li>
					</ul>
				</div>
				<div className="col-sm-3 col-xs-6 col-xxs-12">
					<h3>Top bricolage</h3>
					<ul>
						<li><Link to="/recherche?query=perforateur&page=1&configure%5BhitsPerPage%5D=21">Location perforateur</Link></li>
						<li><Link to="/recherche?query=ponceuse&page=1&configure%5BhitsPerPage%5D=21">Location ponceuse</Link></li>
						<li><Link to="/recherche?query=pistolet&page=1&configure%5BhitsPerPage%5D=21">Location pistolet à peinture</Link></li>
						<li><Link to="/maison/location--KmRgVKbUmp6ig1ZjCbK">Location shampouineuse</Link></li>
						<li><Link to="/recherche?query=nettoyeur%20vapeur&page=1&configure%5BhitsPerPage%5D=21">Location nettoyeur vapeur</Link></li>
						<li><Link to="/recherche?query=decolleuse&page=1&configure%5BhitsPerPage%5D=21">Location décolleuse à papier peint</Link></li>
						<li><Link to="/recherche?query=perceuse%20sans%20fil&page=1&configure%5BhitsPerPage%5D=21">Location perceuse sans fil</Link></li>
						<li><Link to="/bricolage/location--Kw9jPpMS2vVgKCm3bs3">Location rainureuse</Link></li>
					</ul>
				</div>
				<div className="col-sm-3 col-xs-6 col-xxs-12">
					<h3>top puériculture</h3>
					<ul>
						<li><Link to="/puericulture/location--KqhvuvL12IG2pDNbwrd">Location poussette Yoyo</Link></li>
						<li><Link to="/puericulture/location--KmV_xkOWFqNHAZGdowB">Location lit Parapluie</Link></li>
						<li><Link to="/recherche?query=siege%20auto&page=1&configure%5BhitsPerPage%5D=21">Location siège auto</Link></li>
					</ul>
					<h3>top food</h3>
					<ul>
						<li><Link to="/food-and-snack/location--KucUwAO5sePXWTDqb4o">Location crêpière</Link></li>
						<li><Link to="/food-and-snack/location--Kw5hwYfMIUIhoZZcL2k">Location machine barbe à papa</Link></li>
						<li><Link to="/food-and-snack/location--KpnvZN9pZ2_6-F5gvUK">Location machine à hot dog</Link></li>
						<li><Link to="/food-and-snack/location--KwnpMMH_-e4_vTRMAta">Location chafing dish</Link></li>
					</ul>
				</div>
				<div className="col-sm-3 col-xs-6 col-xxs-12">
					<h3>Top voyage</h3>
					<ul>
						<li><Link to="/recherche?query=tente&page=1&configure%5BhitsPerPage%5D=21">Location tente de camping</Link></li>
						<li><Link to="/road-trip/location--KqhmKwTxTh_-XusrlWW">Location housse de vélo</Link></li>
						<li><Link to="/road-trip/location--KmW_nvObKhm1VuRc_B5">Location matelas gonflable</Link></li>
						<li><Link to="/bricolage/location--Km_gqMSGJcKcaf1YvPL">Location table à tappisser</Link></li>
					</ul>
					<h3>Top auto</h3>
					<ul>
						<li><Link to="/recherche?query=coffre%20toit&page=1&configure%5BhitsPerPage%5D=21">Location coffre de toit</Link></li>
						<li><Link to="/recherche?query=barres&page=1&configure%5BhitsPerPage%5D=21">Location barres de toit</Link></li>
						<li><Link to="/automobile/location--KuxTA-8EB-3uodEtXFD">Location chaines de neige</Link></li>
						<li><Link to="/recherche?query=GPS&page=1&configure%5BhitsPerPage%5D=21">Location GPS</Link></li>
					</ul>
				</div>
			</div>
		</div>
	</section>
)

const MobileApp = () => (
	<section className="el-panel call-to-action bg-light">
		<div className="container">
			<div className="row">
				<div className="col-sm-offset-2  col-sm-4 col-xs-12 sm-text-center">
            		<img src="img/demo/responsive.png" alt="" />
				</div>
				<div className="col-sm-4 col-xs-12 sm-text-center">
					<h3>Vos locations<br/>dans la poche</h3>
					<p>Réserver votre matériels quand vous voulez.</p>
					<Link to="/recherche" className="btn btn-md btn-color">
						<span>Voir Les annonces</span>
					</Link>
				</div>
			</div>
		</div>
	</section>
)


const TrendyItem = ({title, subtitle, link, icon}) => (
	<div className="col-md-6 col-xs-12">
		<div className="product-item">
			<div className="product-img" style={{maxHeight: "281px"}}>
				<a href="shop-single.html">
					<img src="img/shop/shop_item_1.jpg" alt="" />
				</a>
				<div className="hover-overlay" style={{opacity: "1", visibility: "visible"}}>                    
					<div className="product-details valign" style={{padding: "20px"}}>
						<h3 className="product-title" style={{color: "#fff"}}>
							<img src={icon} alt="" width="35" style={{minWidth: "35px", marginBottom: "20px"}}/><br />{title}
						</h3>
						<span className="category" style={{color: "#fff"}}>
                			{subtitle}
						</span>
						<div className="btn-quickview">
							<Link to={link ? link : '/recherche'} className="btn btn-md btn-color">
								<span>Voir Les annonces</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
)


const trendyItems = [
	{	
		title: "Installation du chantier", 
		subtitle: "Projecteurs, mâts d'éclairages, armoires de comptage et distribution",
		link: '/recherche?menu%5Bcategory.name%5D=Installations%20provisoires%20et%20sécurité&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: security
	},
	{
		title: "Outillage professionnel", 
		subtitle: "Outillage à main, Carroteuses et foreuses, outils de découpe, ponceuses, malaxeurs, outils de plomberie, nettoyeurs, shampouineuses et monobrosse",
		link: '/recherche?menu%5Bcategory.name%5D=Outillage%20professionnel&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: drill
	},
	{
		title: "Elévation et travail en hauteur", 
		subtitle: "Echafaudages et plateformes individuelles",
		link: '/recherche?menu%5Bcategory.name%5D=Elévation%20et%20travail%20en%20hauteur&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: ladder
	},
	{
		title: "Energie électrique et fluides", 
		subtitle: "Groupes électrogènes, chauffages et pompes",
		link: '/recherche?menu%5Bcategory.name%5D=Energie%20%C3%A9l%C3%A9ctrique%20et%20fluides&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A%22LOXAM%20RENTAL%20STRASBOURG%20MEINAU%22',
		icon: energy
	},
	{
		title: "Transport et manutention", 
		subtitle: "Transpalette, élévateurs mécaniques, diable, lève-panneau et  remorques",
		link: '/recherche?menu%5Bcategory.name%5D=Transport%20et%20manutention&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: transport
	},
	{
		title: "Espaces verts", 
		subtitle: "Outils de préparation des sols, debroussaillage , taille et souffleur des feuilles",
		link: '/recherche?menu%5Bcategory.name%5D=Espaces%20verts&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: mower
	},
	{
		title: "Démolition et gros œuvre", 
		subtitle: "Piqueur électrique 6kg et 10kg, bétonnières et aiguille vibrante, Goulotte et ligatureur d’armatures",
		link: '/recherche?menu%5Bcategory.name%5D=Démolition%20et%20gros%20œuvre&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A"LOXAM%20RENTAL%20STRASBOURG%20MEINAU"',
		icon: jackhammer
	},
	{
		title: "Terrassement et routes", 
		subtitle: "Pioches à air, plaques vibrantes, pilonneuses, lasers tournants",
		link: '/recherche?menu%5Bcategory.name%5D=Terrassement%20et%20routes&menu%5Binclude%5D=Gamme%20Choose&page=1&configure%5BhitsPerPage%5D=21&configure%5Bfilters%5D=stores.name%3A%22LOXAM%20RENTAL%20STRASBOURG%20MEINAU%22',
		icon: road
	}
]

const TrendyItems = () => (
	<section id="trendy-items" className="section-wrap-sm new-arrivals pb-50">
		<div className="container">

			<div className="row heading-row">
				<div className="col-md-12 text-center">
					<h2 className="heading bottom-line">
                		Une gamme complète de matériels incluse dans votre forfait
              		</h2>
				</div>
			</div>

			<div className="row items-grid">
				{
					trendyItems.map((item, index) => <TrendyItem key={index} {...item} />)
				}
			</div>
		</div>
	</section>
)


const Home = props => {
	props.setTitle()
	props.setDescription()
	
	return (
		<Layout>
			<TopContentContainer history={props.history} />
			<HowToDo />
			<TrendyItems />
			<Advantage />
			<MobileApp />
		</Layout>
	)
}

export default Home
