import React from 'react'
import PropTypes from 'prop-types'

import { InstantSearch } from 'react-instantsearch/dom'
import qs from 'qs'

const updateAfter = 400

const createURL = state => `?${qs.stringify(state)}`

const searchStateToUrl = (props, searchState) => searchState ? `/recherche${createURL(searchState)}` : ''

const urlToSearchState = location => qs.parse(location.search.slice(1))

class InstantSearchRoute extends React.Component {
	state = {
		searchState: urlToSearchState(this.props.location)
	}

	componentDidMount = () => {
		if (this.props.searchStateChange) {
			this.props.searchStateChange(urlToSearchState(this.props.location))
		}
	}

	componentWillReceiveProps(props) {
		if (props.location !== this.props.location) {
			this.setState({ searchState: urlToSearchState(props.location) })
		}
	}

	onSearchStateChange = searchState => {
		if (this.props.searchAsYouType) {
			clearTimeout(this.debouncedSetState)

			this.debouncedSetState = setTimeout(() => {
				this.props.history.push(
					searchStateToUrl(this.props, searchState),
					searchState
				)
			}, updateAfter)
		} else {
			this.props.history.push(
				searchStateToUrl(this.props, searchState),
				searchState
			)
		}

		this.setState({ searchState })

		if (this.props.searchStateChange) {
			this.props.searchStateChange(searchState)
		}
	}

	render = () => {
		const {children, searchStateChange, searchAsYouType, ...custom} = this.props
		return (
			<InstantSearch 
				searchState={this.state.searchState}
				onSearchStateChange={this.onSearchStateChange}
				createURL={createURL}
				{...custom} >
				{children}
			</InstantSearch>
		)
	}
}

InstantSearchRoute.defaultProps = {
	searchAsYouType: true
}


InstantSearchRoute.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	location: PropTypes.object.isRequired,
	searchStateChange: PropTypes.func,
	searchAsYouType: PropTypes.bool
}

export default InstantSearchRoute