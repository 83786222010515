import React from 'react'
import { Link } from 'react-router-dom'


import { PageTitle, Loading } from '../components'
import { Layout } from '../containers'
import { ErrorPage, NotMatchPage } from '../views'

import { connectCreateOrder } from '../../order'


class OrderInfo extends React.Component {
	getOrderStatus = () => {
		let state =  {
			title: "Creation de la commande en cours",
			description: "Merci de patientez votre commande est en cours de création",
			OrderLink: false
		}

		if (this.props.order.status === "created") {
			state = {
				title: "Création de la commande avec succès",
				description: "Votre commande a bien été crée. Cliquez sur le lien ci-dessous pour voir le détail de la commande",
				redirect: false,
				orderLink: true
			}
		}

		return state
	}


	render = () => {
		const content = this.getOrderStatus()

		return (
			<div className="content-wrapper oh">
				<section className="section-wrap checkout pb-70 mt-40 mb-40">
					<div className="container relative">
						<div className="row">

							<div className="ecommerce col-xs-12">

	              				<div className="checkout ecommerce-checkout row">

	                				<div className="col-md-6 col-md-offset-3">
	                  					<div className="ecommerce-checkout-payment">
	                   		 				<h2 className="heading uppercase bottom-line full-grey mb-20 text-center">{content.title} {content.error && `(${content.error})`}</h2>
	                    					<p>{content.description}</p>
	                    					{content.orderLink && <Link to={`/orders/${this.props.order.id}`}>Accéder aux détails de la commande</Link>}
	                  					</div>
	                				</div>
	              				</div>
	            			</div>
	         			</div>
	        		</div>
	      		</section>
			</div>
		)
	}
}


class CreateOrder extends React.Component {
	componentDidMount = () => {
		this.props.onOrder(this.props.match.params.orderId)
	}

	render = () => {
		this.props.setTitle("Creation de la commande - Loxma")
		this.props.setDescription()

		return (
			this.props.isLoading ? <Loading /> : (
				<Layout hideBanner={true} navLeftComponent={<div></div>}>
					<OrderInfo order={this.props.order}/>
				</Layout>
			)
		)
	}
}

export default connectCreateOrder(CreateOrder)