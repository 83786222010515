import React from 'react'
import { Layout } from '../containers'
import { PageTitle } from '../components'

import axaLogo from '../assets/img/axa-logo-solid.svg'


const Insurance = () => (
	<Layout>
		<PageTitle title="L'assurance e-loue express" />
		<section className="section-wrap intro pb-0">
			<div className="container">
				<div className="row">
					<div className="col-sm-8 mb-40">
						<h2 className="intro-heading">Une assurance incluse à chaque location</h2>
						<ul className="mt-20">
							<li style={{padding: "10px 0"}}><i className="fa fa-check"></i> Vous louez une poussette et cassez accidentellement une roue qui doit être remplacée.</li>
							<li style={{padding: "10px 0"}}><i className="fa fa-check"></i> Vous faites tomber l’appareil à raclette loué, il se brise et est irréparable.</li>
							<li style={{padding: "10px 0"}}><i className="fa fa-check"></i> Vous partez en vacances avec une Go Pro louée, on vous la vole votre chambre d’hôtel</li>
						</ul>
					</div>
					<div className="col-sm-4 mb-40">
						<div className="text-center" style={{padding: "10px", border: "1px solid #e5e5e5"}}>
							<p className="mt-10 mb-20">E-loue Express vous apporte plus de protection et vous permet de louer du matériel en toute sérénité.</p> 
							<p>En partenariat avec <img width="53" height="53" src={axaLogo} alt="Réservations assurées par AXA" style={{width: "53px", marginLeft: "5px"}}/></p>
						</div>
					</div>
				</div>
			</div>
			<hr className="mb-0" />
		</section>
		<section className="section-wrap">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h2 className="mb-20"><small>Que garantit la couverture AXA ?</small></h2>
						<p>La garantie couvre le matériel loué par le locataire :</p>
						<ul style={{listStyle: "disc", marginLeft: "40px"}}>
							<li>détérioration</li>
							<li>casse accidentelle</li>
							<li>vol</li>
						</ul>
						<p>Pour toute information concernant les montants de garantie et exclusions, vous pouvez consulter <a href="https://firebasestorage.googleapis.com/v0/b/rexpress-prod.appspot.com/o/insurance%2Fnotice-assurance.pdf?alt=media&token=70e67832-a8d1-402d-a6b4-7eca4287368a" target="_blank">la notice d’information</a>.</p>
						<h2 className="mb-20 mt-60"><small>Que dois-je faire pour bénéficier de la couverture ?</small></h2>
						<p>En faisant votre réservation via E-loue Express, les objets loués sont automatiquement assurés (sauf exclusions prévues au contrat) jusqu’à hauteur de 5 000€ !</p>
						<h2 className="mb-20 mt-60"><small>Qu’est-ce qu’une franchise ?</small></h2>
						<p>C’est le montant qui reste à la charge de l’assuré en cas de sinistre.</p>
						<p>L’assurance est incluse dans la prestation E-loue Express  mais elle prévoit une franchise de 10%.</p>
						<p>Par exemple vous louez une machine à barbe à papa. Vous la faîtes tomber et l’endommagez. La valeur des réparations est estimée à 300 euros. Afin d’activer l’assurance, vous devrez payer 30 euros de franchise.</p>
						<h2 className="mb-20 mt-60"><small>Quand suis-je couvert ?</small></h2>
						<p>La garantie est automatiquement incluse dans la prestation E-loue Express (sauf exclusions prévues au contrat) et est accordée pendant la durée de la location.</p>
						<h2 className="mb-20 mt-60"><small>Qu’est-ce qui n’est pas couvert ?</small></h2>
						<p>Il existe un certain nombre d’exclusions concernant les biens assurés, le vol, certains dommages... reportez-vous à <a href="https://firebasestorage.googleapis.com/v0/b/rexpress-prod.appspot.com/o/insurance%2Fnotice-assurance.pdf?alt=media&token=70e67832-a8d1-402d-a6b4-7eca4287368a" target="_blank">la notice d’information</a> pour en prendre connaissance.</p>
						<h2 className="mb-20 mt-60"><small>Que faire en cas d’incident ?</small></h2>
						<p>En cas d’incident, il vous appartient de le déclarer à votre Agent Général AXA en remplissant ce formulaire en ligne (<a href="https://corso3.typeform.com/to/OZyPTB" target="_blank">cliquez ici</a>).</p>
						<p>Attention ! Vos déclarations devront être soumises dans les délais impartis :</p>
						<ul style={{listStyle: "disc", marginLeft: "40px"}}>
							<li>dans les 2 jours ouvrés pour le vol.</li>
							<li>dans les 5 jours ouvrés dans les autres cas.</li>
						</ul>
						<p>Merci d’inclure :</p>
						<ul style={{listStyle: "disc", marginLeft: "40px"}}>
							<li>vos coordonnées, la nature du sinistre (vol, casse, ..) et ses circonstances (date, lieu,…).</li>
							<li>Le dépôt de plainte en cas de vol.</li>
						</ul>
						<p>Pour toute information concernant les montants de garantie et les exclusions, vous pouvez consulter <a href="https://firebasestorage.googleapis.com/v0/b/rexpress-prod.appspot.com/o/insurance%2Fnotice-assurance.pdf?alt=media&token=70e67832-a8d1-402d-a6b4-7eca4287368a" target="_blank">la notice d’information</a>.</p>
					</div>
				</div>
			</div>
		</section>
	</Layout>
)

export default Insurance