import React from 'react'
import { get } from '../../core/clients/restClient'


const connectPage = Component => {
	class ConnectPage extends React.Component {
		state = {
			isLoading: true,
			page: null,
		}

		getPage = id => {
			this.setState({ isLoading: true, page: null })

			get(id, "/pages").then(page => {
				this.setState({isLoading: false, page: page})
			})
		}

		render = () => <Component getPage={this.getPage} isLoading={this.state.isLoading} page={this.state.page} {...this.props} />

	}

	return ConnectPage
}

export default connectPage