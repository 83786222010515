import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Layout } from '../containers'
import { PressAboutUs, ProductWidgetList, HowToDo } from '../components'


class TopContent extends React.Component {
	componentDidMount = () => {
		//Init top content slider
        window.$("#owl-hero").owlCarousel({
            autoPlay: 3000,
            navigation: true,
            navigationText: ["<i class='ui-left-arrow'></i>", "<i class='ui-right-arrow'></i>"],
            slideSpeed: 300,
            pagination: true,
            paginationSpeed: 400,
            singleItem: true,
            stopOnHover: true,
            addClassActive: true
        })
	}

	render = () => {
		const store = this.props.store
		return (
			<section className="hero-wrap text-center relative">
				<div id="owl-hero" className="owl-carousel owl-theme light-arrows slider-animated">
					<div className="hero-slide overlay" style={{backgroundImage: "url(img/multi-images.jpg)"}}>
						<div className="container">
							<div className="hero-holder">
								<div className="hero-message hero-search">
									<h1 className="hero-title nocaps">Boutique {store.name}</h1>
									<p className="text-hero-magasin">Adresse : {`${store.address.address1}, ${store.address.zipcode} ${store.address.city}`}</p>
									<p className="text-hero-magasin">Horraire : {store.openDescription}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}


const How = ({ store }) => (
	<section className="el-panel panel-first home-blocs">
		<div className="container">
			<div className="row-fluid row-same-height">
				<div className="col-sm-4 col-xs-12">
					<div className="home-bloc">
						<div className="home-bloc-image">
							<img src="img/icons/cart.svg" alt="" />
						</div>
		            	<h2>Vos locations préférées</h2>
		            	<p>E-loue a sélectionné pour vous les meilleurs objets à louer: bricolage, high-tech, puériculture, loisirs, etc.</p>
		          	</div>
		        </div>
		        <div className="col-sm-4 col-xs-12">
					<div className="home-bloc">
						<div className="home-bloc-image">
							<img src="img/icons/camion.svg" alt="" />
						</div>
						<h2>Livrées dans {store.shippingArea}</h2>
			            <p>
			            	6J/7<br />
							Ou à retirer dans notre magasin:<br />
							{`${store.address.address1}, ${store.address.zipcode} ${store.address.city}`}<br />
							Horraires : {store.openDescription}
			            </p>
		          	</div>
		        </div>
		        <div className="col-sm-4 col-xs-12">
					<div className="home-bloc">
						<div className="home-bloc-image">
							<img src="img/icons/clock.svg" alt="" />
		            	</div>
		            	<h2>Quand vous voulez</h2>
		            	<p>Dans 1 heure? Ce soir? Dans 2 semaines? Choisissez votre créneau et suivez la livraison en temps réel.</p>
					</div>
		        </div>
			</div>
		</div>
  	</section>
)

const Faq = () => (
	<section className="el-panel faq-blocs">
		<div className="container">
	      	<h2>Questions fréquentes</h2>
	      	<div className="row">
	        	<div className="col-sm-6 col-xs-12">
	          		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-wifi"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Comment fonctionne E-loue Express ?</h3>
	              			<p>Vous réservez un ou plusieurs produits à louer sur le site et vous payez en ligne. Soit vous venez récupérer vos produits dans notre magasin, soit vous vous faîtes livrer.</p>
	            		</div>
	          		</div>
	        	</div>
	        	<div className="col-sm-6 col-xs-12">
	          		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-pin"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Où est disponible E-loue Express ?</h3>
	              			<p>E-Loue Express est disponible dans toute l’île de France à Lyon et à Marseille.</p>
	            		</div>
	          		</div>
	        	</div>
	      	</div>
	      	<div className="row">
	        	<div className="col-sm-6 col-xs-12">
	         		<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-cart"></i>
	            		</div>
	            		<div className="faq-bloc-info">
	              			<h3>Que puis-je louer E-loue Express ?</h3>
	              			<p>E-loue express propose des centaines de produits différents à louer : photo et vidéo, beauté, high-tech, puériculture, accessoire auto, évènementiel et bien plus encore.</p>
	            		</div>
	          		</div>
	        	</div>
	        	<div className="col-sm-6 col-xs-12">
					<div className="faq-bloc">
	            		<div className="faq-bloc-image">
	              			<i className="ion-android-time"></i>
	           			</div>
	            		<div className="faq-bloc-info">
	              			<h3>Quand livre E-loue Express ?</h3>
	              			<p>E-loue express livre tous les jours, week-end compris. De même notre point retrait est ouvert 6j/7.</p>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)

//<TopContent store={user.store} />

const Store = ({user, setTitle, setDescription}) => {
	setTitle()
	setDescription()
	return (
		<Layout>
			<HowToDo />
			{user && <ProductWidgetList user={user}/>}
			<section className="newsletter" id="subscribe">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 text-center">
							<Link to="/recherche" className="btn btn-lg btn-color"><span>Voir toutes les annonces de location</span></Link>
						</div>
					</div>
				</div>       
			</section>
			{user && <How store={user.store} />}
			<Faq />
			<PressAboutUs />
		</Layout>
	)
}

export default connect(state => ({
	user: state.auth.user
}))(Store)