import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getAll } from '../../core/clients/restClient'
import { connectAuth } from '../../core/connectors'

import { getItems } from '../../loxamapi'

import items from '../../fixtures/contractsResponse'

import 'moment/locale/fr'


import { Layout } from '../containers'
import { PageTitle, Loading } from '../components'
moment.locale('fr')

const Address = ({address}) => (
	<React.Fragment>
		{address.name && (<React.Fragment>{address.name}<br/></React.Fragment>)}
		{address.address1}<br />
		{address.address2 && (<React.Fragment>{address.address2}<br/></React.Fragment>)}
		{address.postaCode} {address.city}
	</React.Fragment>
)


const getBookingStatus = (type, isClose) => {
	if (type === 'VR' && isClose === false) {
		return "A venir"
	} else if (type === 'RI' && isClose === false) {
		return "En cours"
	} else if (type === 'RI' && isClose === true) {
		return "Terminée"
	} else {
		return "-"
	}
}


const Table = ({ children }) => (
	<table className="table">
		{ children }
	</table>
)

const BookingTableItem = ({ contractNumber, designation, leavingDate, backDate, isClose, type, totalAmountHT, amount}) => (
	<tr>
		<td style={{textAlign: "left"}}>{`${designation} - ${contractNumber}`}</td>
		<td style={{textAlign: "center"}}>{moment(leavingDate).format("DD/MM/YYYY")}</td>
		<td style={{textAlign: "center"}}>{moment(backDate).format("DD/MM/YYYY")}</td>
		{
			amount && (
				<td style={{textAlign: "center"}}>{totalAmountHT} €</td>

			)
		}
		<td style={{textAlign: "center"}}>{getBookingStatus(type, isClose)}</td>
	</tr>
)

const DeliveryTableItem = ({ item }) => (
	<tr>
		<td style={{textAlign: "left"}}>{item.description}</td>
		<td style={{textAlign: "center"}}><Address address={item.deliveryAddress} /></td>
		<td style={{textAlign: "center"}}><Address address={item.pickupAddress} /></td>
		<td style={{textAlign: "center"}}>{item.totalAmountHT} €</td>
	</tr>
)

const BillingTableItem = ({ index, date, subscriptionAmount, outSubscriptionAmount, deliveryAmount, bookingCount, totalAmount }) => (
	<tr>
		<th scope="row">{date}</th>
		<td style={{textAlign: "center"}}>{subscriptionAmount.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}>{outSubscriptionAmount.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}>{deliveryAmount.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}>{parseInt(bookingCount)}</td>
		<td style={{textAlign: "center"}}>{totalAmount.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}><Link to={`/account/billings/${index}`}>Voir les factures</Link></td>
	</tr>
)

const InvoiceTableItem2 = ({invoice, index}) => (
	<tr>
		<th scope="row">{invoice.description}</th>
		<td style={{textAlign: "center"}}>{invoice.totalAmount.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}><Link to={`/account/billings/${invoice.id}`}>Voir les factures</Link></td>
	</tr>
)

class CurrentSubscription extends React.Component {
	componentDidMount = () => {
		//Accordeon
        const toggleChevron = e => {
            window.$(e.target)
                .prev('.panel-heading')
                .find("a")
                .toggleClass('plus minus');
        }

        window.$('#accordion').on('hide.bs.collapse', toggleChevron)
        window.$('#accordion').on('show.bs.collapse', toggleChevron)
	}


	render = () => (
		<div className="container relative" style={{color: "#000"}}>
			<div className="row mb-20">
				<div className="col-md-12">
					<h2 className="heading uppercase">
						Consommation du mois en cours
					</h2>
				</div>
			</div>
			<div className="panel-group accordion mb-50" id="accordion">
				<div className="panel">
					<div className="panel-heading">
						<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" className="minus">
							{
								this.props.includeItems && (
									`${this.props.includeItems.length} réservation${this.props.includeItems.length > 1 ? "s" : ""} incluse${this.props.includeItems.length > 1 ? "s" : ""} dans le forfait`
								)
							}
							<span>&nbsp;</span>
						</a>
					</div>
					<div id="collapseOne" className="panel-collapse collapse">
						<div className="panel-body">
							{
								this.props.includeItems && (
									<Table>
										<thead> 
											<tr>
												<th style={{fontWeight: "bold", textAlign: "left"}}>Article</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Date de début</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Date de fin</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Statut</th>
											</tr>
										</thead>
										<tbody>
											{
												this.props.includeItems.map((item, index) => <BookingTableItem key={index} {...item} />)
											}
										</tbody>
									</Table>
								)
							}
						</div>
					</div>
					<div className="panel-heading">
						<a data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="minus">
							{
								this.props.excludeItems && (
									`${this.props.excludeItems.length} Réservation${this.props.excludeItems.length > 1 ? "s" : ""} hors forfait`
								)
							}
							<span>&nbsp;</span>
						</a>
					</div>
					<div id="collapse2" className="panel-collapse collapse">
						<div className="panel-body">
							{
								this.props.excludeItems && (
									<Table>
										<thead> 
											<tr>
												<th style={{fontWeight: "bold", textAlign: "left"}}>Article</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Date de début</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Date de fin</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Montant HT</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Statut</th>
											</tr>
										</thead>
										<tbody>
											{
												this.props.excludeItems.map((item, index) => <BookingTableItem key={index} {...item} amount />)
											}
										</tbody>
									</Table>
								)
							}
						</div>
					</div>
					<div className="panel-heading">
						<a data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="minus">
							{
								this.props.shippingItems && (
									`${this.props.shippingItems.length} livraison${this.props.shippingItems.length > 0 ? "s" : ""}`
								)
							}
							
							<span>&nbsp;</span>
						</a>
					</div>
					<div id="collapse3" className="panel-collapse collapse">
						<div className="panel-body">
							{
								this.props.shippingItems && (
									<Table>
										<thead> 
											<tr>
												<th style={{fontWeight: "bold", textAlign: "left"}}>Drescription</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Adresse de retrait</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Adresse de destination</th>
												<th style={{fontWeight: "bold", textAlign: "center"}}>Montant HT</th>
											</tr>
										</thead>
										<tbody>
											{
												this.props.shippingItems.map((item, index) => <DeliveryTableItem key={index} index={index} item={item} />)
											}
										</tbody>
									</Table>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


const InvoiceList = ({ invoices }) => (
	<div className="container relative" style={{color: "#000"}}>
		<div className="row mb-20">
			<div className="col-md-12">
				<h2 className="heading uppercase">
					Suivi conso pour les mois précédents
				</h2>
			</div>
		</div>
		{
			invoices.length > 0 ? (
				<Table>
					<thead> 
						<tr>
							<th style={{fontWeight: "bold"}}>Date</th>
							<th style={{fontWeight: "bold", textAlign: "center"}}>Montant Total</th>
							<th style={{fontWeight: "bold", textAlign: "center"}}>Action</th>
						</tr>
					</thead>
					<tbody>
						{
							invoices.map((invoice, index) => <InvoiceTableItem2 key={index} index={index} invoice={invoice} />)
						}
					</tbody>
				</Table>
			) : (
				<p>Vous n'avez pas de consommations pour les mois précédents</p>
			)
		}
		
	</div>
)


const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/account">Votre compte</Link>
		</li>
		<li className="active">
			Votre suivi conso
		</li>
	</ol>
)


class Subscription extends React.Component {
	state = {
		loading: true,
		items: null,
		error: false
	}

	getBookings = items => items.filter(item => (item.type === 'RI' || item.type === 'VR') && item.equipmentNumber !== "0990103" && item.equipmentNumber !== "0990104")

	getDeliveries = items => items.filter(item => item.type === 'XD')


	// getIncludeItems = items => items.filter(item => item.isClose === false && item.designation !== null && item.totalAmountVTA === 0 && item.equipmentNumber !== "0990103" && item.equipmentNumber !== "0990104")

	// getExcludeItems = items => items.filter(item => item.isClose === false && item.designation !== null && item.totalAmountVTA > 0 && item.equipmentNumber !== "0990103" && item.equipmentNumber !== "0990104")

	// getCloseItems = items => items.filter(item => item.isClose === true && item.designation !== null && item.totalAmountVTA > 0 && item.equipmentNumber !== "0990103" && item.equipmentNumber !== "0990104")

	componentDidMount = async () => {
		try {
			// const includeItems = this.getIncludeItems(items.productItems)
			// const excludeItems = this.getExcludeItems(items.productItems)
			// const closeItems = this.getCloseItems(items.productItems)
			
			// //Get items
			const startDate = moment().startOf('month').format('YYYY-MM-DD')
			const endDate = moment().endOf('month').format('YYYY-MM-DD')

			const items = await getItems(startDate, endDate)

			const bookings = this.getBookings(items.productItems)
			const shippingItems = this.getDeliveries(items.productItems).map(item => {
				//Get the contract
				const contract = items.contracts.filter(contract => contract.contractNumber === item.contractNumber.toString())[0]


				if (item.lineType === "PK") {
					item.description = "Livraison retour"
					item.pickupAddress = contract.agencyAddress
					item.deliveryAddress = contract.projectAddress
				}

				if (item.lineType === "DL") {
					item.description = "Livraison aller"
					item.pickupAddress = contract.projectAddress
					item.deliveryAddress = contract.agencyAddress
				}

				return item
			})

			// const includeItems = this.getIncludeItems(items.productItems)
			// const excludeItems = this.getExcludeItems(items.productItems)
			// const closeItems = this.getCloseItems(items.productItems)


			//Get invoices
			const invoices = await getAll(`/invoices/${this.props.user.uid}`)

			this.setState({
				loading: false,
				includeItems: bookings.filter(item => item.totalAmountHT === 0),
				excludeItems: bookings.filter(item => item.totalAmountHT > 0),
				shippingItems: shippingItems,
				invoices: invoices.docs
			})
		} catch (error) {
			this.setState({
				loading: false,
				error: true
			})
		}
	}

	render = () => {
		this.props.setTitle("Votre suivi conso - Choose")
		this.props.setDescription()

		return (
			<Layout>
				<PageTitle title="Votre suivi conso" />
				{
					this.state.loading ? <Loading /> : this.state.error ? (
							<div className="content-wrapper oh">
								<div className="container mt-10">
									<Breadcrumb />
								</div>
								<div className="container mt-30" style={{height: "300px"}}>
									<p>Impossible d'afficher votre suivi conso ({this.state.error})</p>
								</div>
							</div>
						) : (
							<div className="content-wrapper oh">
								<div className="container mt-10">
									<Breadcrumb />
								</div>
								<section className="section-wrap checkout pb-70">
									<CurrentSubscription includeItems={this.state.includeItems}  excludeItems={this.state.excludeItems} closeItems={this.state.closeItems} shippingItems={this.state.shippingItems} />
									<InvoiceList invoices={this.state.invoices} />
								</section>
							</div>
						)
				}
			</Layout>
		)
	}

}
	

export default connectAuth(Subscription)