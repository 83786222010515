import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { connectCart } from '../../order'

const CartItem = ({ item , removeItem }) => {
	const startDate = moment(item.startDate).format('D/MM/YYYY à HH:mm')
	const endDate = moment (item.endDate).format('D/MM/YYYY à HH:mm')
	return (
		<div className="nav-cart-item clearfix">
			<div className="nav-cart-img">
				<Link to={item.slug} ><img src={item.picture} alt="" /></Link>
			</div>
			<div className="nav-cart-title">
				<Link to={item.slug} >{item.name}</Link>
				<div className="nav-cart-price" style={{fontSize: "12px", lineHeight: "16px"}}>
          			Du {startDate}<br />
          			Au {endDate}
        		</div>
        		<div className="nav-cart-price">
        			<span>{item.quantity} {item.quantity > 1 ? "articles" : "article"}</span>
        		</div>
			</div>
			<div className="nav-cart-remove">
				<a href="#removeItem" onClick={e => {e.preventDefault(); removeItem(item.id); }} className="remove"><i className="ui-close"></i></a>
			</div>
		</div>
	)
}

//TODO: handle remove function and checkout
const CartItems = ({ cart, removeItem, handleCheckoutRedirection }) => (
	<React.Fragment>
		<div className="nav-cart-items">
			{ cart.items.map((item, index) => <CartItem key={index} item={item} removeItem={removeItem} />) }
		</div>
		<div className="nav-cart-summary">
			<span>Sous-total</span>
			<span className="total-price">{cart.total ? cart.total.toFixed(2) : "Calcul en cours"}€</span>
		</div>
		<div className="nav-cart-actions mt-20">
			<Link to="/panier" className="btn btn-md btn-dark"><span>Modifier le panier</span></Link>
			<a href="#cart-checkout" onClick={handleCheckoutRedirection} className="btn btn-md btn-color mt-10"><span>Passer la commande</span></a>
		</div>
	</React.Fragment>
)

class Cart extends React.Component {
	state = {
		redirectToCheckout: false
	}

	handleCheckoutRedirection = e => {
		e.preventDefault()

		this.setState({ redirectToCheckout: true })
	}

	render = () => {
		const { cart, removeItem, quantity } = this.props
		const amountUpdated = cart && cart.items && cart.items.length > 0 ? this.props.getTotalAmount() === cart.total : false

		return (
			this.state.redirectToCheckout === true && amountUpdated === true ? <Redirect to="/checkout/1" /> : (
				<li className="nav-cart">
					<div className="nav-cart-outer">
						<div className="nav-cart-inner">
							<Link to="/panier" className="nav-cart-icon">
								{quantity}
							</Link>
						</div>
					</div>
					<div className={`nav-cart-container be-loading ${this.state.redirectToCheckout ? "be-loading-active" : ""}`}>
						{quantity > 0 ? <CartItems cart={cart} removeItem={removeItem} handleCheckoutRedirection={this.handleCheckoutRedirection} /> : "Votre panier est vide."}
					</div>
				</li>
			)
		)
	}
}


export default connectCart(Cart)