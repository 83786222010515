import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Field, FormSection, reduxForm, getFormValues, formValueSelector, change, isDirty, getFormSyncErrors, SubmissionError, reset, destroy, submit, isSubmitting } from 'redux-form'
import { Link, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

import { Layout } from '../containers'
import { PageTitle, Loading, Form, Modal, ModalBody, ModalHeader, ModalFooter, OrderItems, PaymentMethodModal } from '../components'
import { connectCheckout} from '../../order'
import { RadioField, SelectField, TextField } from '../fields'



const shippingRangeChoices = [
	{range: {from: 9, to: 11}, label: '09:00 - 11:00', value: '09:00 - 11:00'}, 
	{range: {from: 10, to: 12}, label: '10:00 - 12:00', value: '10:00 - 12:00'}, 
	{range: {from: 11, to: 13}, label: '11:00 - 13:00', value: '11:00 - 13:00'}, 
	{range: {from: 12, to: 14}, label: '12:00 - 14:00', value: '12:00 - 14:00'}, 
	{range: {from: 13, to: 15}, label: '13:00 - 15:00', value: '13:00 - 15:00'}, 
	{range: {from: 14, to: 16}, label: '14:00 - 16:00', value: '14:00 - 16:00'}, 
	{range: {from: 15, to: 17}, label: '15:00 - 17:00', value: '15:00 - 17:00'}, 
	{range: {from: 16, to: 18}, label: '16:00 - 18:00', value: '16:00 - 18:00'}, 
	{range: {from: 17, to: 19}, label: '17:00 - 19:00', value: '17:00 - 19:00'}, 
	{range: {from: 18, to: 20}, label: '18:00 - 20:00', value: '18:00 - 20:00'}, 
	{range: {from: 19, to: 21}, label: '19:00 - 21:00', value: '19:00 - 21:00'}, 
]

class AddressModalForm extends React.Component {

	normalizeIdField = value => {
		const address = this.props.addresses.filter(addr => addr.id === value)[0]
		return address ? address.id : 'new'
	}

	render = () => (
		<Form {...this.props} saveButton={false}>
			<ModalBody>
				<fieldset >
					<SelectField name="id" label="Adresses sauvegardées" normalize={this.normalizeIdField}>
						{this.props.addresses.map((addr, index) => <option key={index} value={addr.id}>{addr.firstName} {addr.lastName} - {addr.company && (` ${addr.company}`)} {addr.address.address1}...</option>)}
						<option value='new'>Ajouter une nouvelle adresse</option>
					</SelectField>
				</fieldset>
				<fieldset>
					<div className="row">
						<div className="col-sm-6">
							<TextField label="Prénom" name="firstName" required />
						</div>
						<div className="col-sm-6">
							<TextField label="Nom" name="lastName" required />
						</div>
					</div>
					<TextField label="Entreprise" name="company" />
					<FormSection name="address">
						<TextField label="Adresse" name="address1" required />
						<TextField label="Complément d'adresse" name="address2" />
						<div className="row">
							<div className="col-sm-6">
								<TextField label="Code Postal" name="zipcode" required />
							</div>
							<div className="col-sm-6">
								<TextField label="Ville" name="city" required />
							</div>
						</div>
						<SelectField label="Pays" name="country" required>
							<option value="FR">France</option>
						</SelectField>
					</FormSection>
				</fieldset>
			</ModalBody>
			<ModalFooter>
				<button type="submit" className="btn btn-primary" disabled={this.props.submitting}>Enregistrer</button>
			</ModalFooter>
		</Form>
	)
}

AddressModalForm = reduxForm({
	form: 'addressModalForm',
	enableReinitialize: true
})(AddressModalForm)

class AddressModal extends React.Component {
	state = {
		address: this.props.address
	}

	handleSubmit = values => {
		if (this.props.isDirty) {
			if (values.id === 'new') {
				this.props.addAddress(values).then(address => {
					this.props.change(this.props.addressField, address)
					this.props.toggle()
				})
			} else {
				this.props.updateAddress(values.id, values).then(address => {
					this.props.change(this.props.addressField, address)
					this.props.toggle()
				})
			}
		} else {
			this.props.change(this.props.addressField, values)
			this.props.toggle()
		}
		
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (prevState.address && (nextProps.addressId !== prevState.address.id)) {
			//Get the new address selected in the form
			if (nextProps.addressId === 'new') {
				return { address: {id: 'new', address: { country: 'FR'} } }
			} else {
				return { address: Object.assign({}, nextProps.addresses.filter(addr => addr.id === nextProps.addressId)[0]) }
			}
		} else {
			return null
		}
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		if (JSON.stringify(prevProps.address) !== JSON.stringify(this.props.address)) {
			this.setState({address: this.props.address})
		}
	}

	render = () => (
		<Modal show={this.props.show}>
			<ModalHeader title="Modifier ou ajouter une adress" toggle={this.props.toggle} />
			<AddressModalForm initialValues={this.state.address} addresses={this.props.addresses} onSubmit={this.handleSubmit}/>
		</Modal>
	)
}

AddressModal = connect(state => ({
	isDirty: isDirty('addressModalForm')(state),
	addressId: formValueSelector('addressModalForm')(state, 'id'),
}), dispatch => ({
	change: (field, value) => {
		dispatch(change('checkoutForm', field, value))
	}
}))(AddressModal)


const OrderItemsComponent = connect(state => ({
	items: formValueSelector('checkoutForm')(state, 'items'),
	deliveryToGo: formValueSelector('checkoutForm')(state, 'deliveryToGo'),
	deliveryBack: formValueSelector('checkoutForm')(state, 'deliveryBack'),
	discounts: formValueSelector('checkoutForm')(state, 'discounts'),
	total: formValueSelector('checkoutForm')(state, 'total')
}), null)(OrderItems)


class ShippingAddressFieldComponent extends React.Component {
	//Display the address
	displayAddress = address => `${address.firstName} ${address.lastName} - ${address.address.address1}, ${address.address.zipcode} ${address.address.city}`

	render = () => {
		const { input, label, deliveryField } = this.props
		return (
			input.value && 
			<p><strong>{`${label} : ${this.displayAddress(input.value)}`}</strong> (<a href="#shippingAddressModal" onClick={e => { e.preventDefault(); this.props.toggle(deliveryField) }} >Modifier</a>)</p>
		)
	}
}

const ShippingAddressField = props => <Field component={ShippingAddressFieldComponent} deliveryField={props.deliveryField} {...props} />


class DeliverySection extends React.Component {
	state = {
		isUpdating: false,
		storeId: null,
		store: null
	}

	//Display the address
	displayAddress = address => `${address.firstName} ${address.lastName} - ${address.address.address1}, ${address.address.zipcode} ${address.address.city}`

	//Get store information
	getStore = () => {
		if (this.state.storeId) {
			this.props.getStore(this.state.storeId).then(store => {this.setState({ store })})
		}
	}

	//Get shipping range
	getShippingRangeChoices = () => {
		const item = this.props.values.items[0]
		if (this.props.name === 'deliveryToGo') {
			const startTime = parseInt(moment(item.startDate).format('h'), 10)
			
			return shippingRangeChoices.filter(choice => choice.range.from >= startTime)
		}
		if (this.props.name === 'deliveryBack') {
			const endTime = parseInt(moment(item.endDate).format('h'), 10)

			return shippingRangeChoices.filter(choice => choice.range.from <= endTime )
		}
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (nextProps.values && (nextProps.values.storeId !== prevState.storeId)) {
			return { storeId: nextProps.values.storeId, store: null }
		}

		return null
	}

	componentDidMount = () => {
		this.getStore()
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		if (this.state.storeId !== prevState.storeId) {
			this.getStore()
		}

		let delivery = Object.assign({}, this.props.values[this.props.name])
		const prevDelivery = prevProps.values[this.props.name]

		//Get the new price if the destination change
		if (prevDelivery[this.props.shippingAddressName] && delivery[this.props.shippingAddressName] && (prevDelivery[this.props.shippingAddressName].id !== delivery[this.props.shippingAddressName].id || JSON.stringify(delivery[this.props.shippingAddressName].address) !== JSON.stringify(prevDelivery[this.props.shippingAddressName].address))) {
			this.setState({ isUpdating: true })

			const originAddress = `${delivery.origin.address.address1}, ${delivery.origin.address.zipcode} ${delivery.origin.address.city}`
			const destinationAddress = `${delivery.destination.address.address1}, ${delivery.destination.address.zipcode} ${delivery.destination.address.city}`

			this.props.getDeliveryAmount(delivery.type, originAddress, destinationAddress).then(amount => {
				this.props.change(`${this.props.name}.amount`, amount)
				this.setState({isUpdating: false})
			}).catch(err => {
				this.props.change(`${this.props.name}.amount`, -1)
				this.setState({isUpdating: false}) 
			})
		}

		//Update child field of the delivery field when delivery type change
		if (prevDelivery.type !== delivery.type) {
			this.setState({ isUpdating: true })

			if (delivery.type === 'shipping') {
				const origin = this.props.shippingAddressName === 'origin' ? this.props.getDefaultAddress() : this.state.store
				const destination = this.props.shippingAddressName === 'destination' ? this.props.getDefaultAddress() : this.state.store

				//Get amount
				const originAddress = `${origin.address.address1}, ${origin.address.zipcode} ${origin.address.city}`
				const destinationAddress = `${destination.address.address1}, ${destination.address.zipcode} ${destination.address.city}`

				delivery.destination = destination
				delivery.origin = origin
				delivery.storeId = null
				delivery.amount = null

				this.props.change(this.props.name, delivery)

				this.props.getDeliveryAmount(delivery.type, originAddress, destinationAddress).then(amount => {
					this.props.change(`${this.props.name}.amount`, amount)
					this.setState({isUpdating: false})
				}).catch(err => { 
					this.props.change(`${this.props.name}.amount`, -1)
					this.setState({isUpdating: false}) 
				})
			}

			if (delivery.type === "pickup") {
				delivery.destination = null
				delivery.origin = null
				delivery.storeId = this.state.storeId
				delivery.timeRange = null
				delivery.amount = null

				this.props.change(this.props.name, delivery)

				this.props.getDeliveryAmount(delivery.type).then(amount => {
					
					this.props.change(`${this.props.name}.amount`, amount)
					this.setState({isUpdating: false})

				}).catch(err => {
					this.props.change(`${this.props.name}.amount`, -1)
					this.setState({isUpdating: false}) 
				})
			}
		}
	}

	render = () => {
		const deliveryError = this.props.syncErrors && this.props.syncErrors[this.props.name] && this.props.syncErrors[this.props.name][this.props.shippingAddressName]
		const content = {
			deliveryToGo: {
				pickup: {
					icon: 'fa fa-map-marker',
					label: "Retirer en agence",
					description: "Vous pouvez récupérer votre matériel loué dans notre agence à partir de l’heure de début de location. Afin de retirer le matériel, une pièce d’identité vous sera demandée, c’est tout."
				},
				shipping: {
					icon: 'fa fa-truck',
					label: "Livrer à une adresse",
					shippingAddressFieldLabel: "Adresse de livraison"
				}
			},
			deliveryBack: {
				pickup: {
					icon: 'fa fa-map-marker',
					label: "Rendre en agence",
					description: "Vous pouvez rendre votre matériel loué dans notre agence avant l’heure de fin de location."
				},
				shipping: {
					icon: 'fa fa-truck',
					label: "Retirer à une adresse",
					shippingAddressFieldLabel: "Adresse de retrait"
				}
			}
		}

		const pickupLabel = <React.Fragment><i className={content[this.props.name].pickup.icon}></i> {content[this.props.name].pickup.label}</React.Fragment>

		const shippingLabel = <React.Fragment><i className={content[this.props.name].shipping.icon}></i> {content[this.props.name].shipping.label}</React.Fragment>

		return (
			<FormSection name={this.props.name}>
				<ul className="payment_methods radio-buttons mb-40">
					<li>
						<RadioField name="type" value="pickup" label={pickupLabel}/>
						<div className={this.props.values[this.props.name].type === 'pickup' ? 'show' : 'hide'}>
							{
								this.state.isUpdating ? 
								<p>Chargement...</p> : 
								<p><strong>{this.state.store && `Notre agence est situé ${this.state.store.address.address1}, ${this.state.store.address.zipcode} ${this.state.store.address.city}`}</strong> <br />
									{content[this.props.name].pickup.description}
								</p>
							}
						</div>
					</li>
					<li>
						<RadioField name="type" value="shipping" label={shippingLabel} />
						<div className={this.props.values[this.props.name].type === 'shipping' ? 'show' : 'hide'}>
							{
								this.state.isUpdating ?
								<p>Chargement...</p> :
								<React.Fragment>
									<ShippingAddressField name={this.props.shippingAddressName} toggle={this.props.toggle} deliveryField={this.props.name} label={content[this.props.name].shipping.shippingAddressFieldLabel} />
									{
										deliveryError ? 
										<p className="text-danger">{deliveryError}</p> :
										<SelectField name="timeRange" label="Tranche horraire">
											<option>Selectionner une tranche horraire</option>
											{
												shippingRangeChoices.map(
													(range, index) => <option value={range.value} key={index} >{range.label}</option>
												)
											}
										</SelectField>
									}
								</React.Fragment>
							}
						</div>
					</li>
				</ul>
			</FormSection>
		)
	}
}

DeliverySection = connect(state => ({
	values: getFormValues('checkoutForm')(state),
	syncErrors: getFormSyncErrors('checkoutForm')(state)
}), dispatch => ({
	change: (field, value) => {
		dispatch(change('checkoutForm', field, value))
	}
}))(DeliverySection)

class WizardCheckoutShippingForm extends React.Component {
	state = {
		addressModal: {
			show: false,
			addressField: null,
			address: null
		}
	}

	changeTotal = (prevAmount, amount) => {
		if (amount >= 0 && prevAmount >= 0) {
			const total = this.props.total - prevAmount + amount
			this.props.change('total', total)
		}
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		//Update total amount when delivery amount change
		if (this.props.deliveryToGo.amount !== prevProps.deliveryToGo.amount) {
			this.changeTotal(prevProps.deliveryToGo.amount, this.props.deliveryToGo.amount)
		}
		if (this.props.deliveryBack.amount !== prevProps.deliveryBack.amount) {
			this.changeTotal(prevProps.deliveryBack.amount, this.props.deliveryBack.amount)
		}		
	}

	toggle = (name=null) => {
		switch (name) {
			case 'deliveryToGo':
				this.setState({
					addressModal: {
						show: !this.state.addressModal.show,
						address: this.props.deliveryToGo.destination,
						addressField: 'deliveryToGo.destination'
					}
				})
				break
			case 'deliveryBack':
				this.setState({
					addressModal: {
						show: !this.state.addressModal.show,
						address: this.props.deliveryBack.origin,
						addressField: 'deliveryBack.origin'
					}
				})
				break
			default:
				this.setState({
					addressModal: {
						show: false,
						address: null,
						addressField: null
					}
				})
				break
		}
	}

	render = () => {
		return (
			<div className="col-md-8">
				<Form {...this.props} saveButton={false} >
					<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-arrow-right"></i> Livraison aller</h2>
					<DeliverySection name="deliveryToGo" shippingAddressName="destination" getStore={this.props.getStore} getDeliveryAmount={this.props.getDeliveryAmount} toggle={this.toggle} getDefaultAddress={this.props.getDefaultAddress} />
					<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-arrow-left"></i> Livraison retour</h2>
					<DeliverySection name="deliveryBack" shippingAddressName="origin" getStore={this.props.getStore} getDeliveryAmount={this.props.getDeliveryAmount} toggle={this.toggle} getDefaultAddress={this.props.getDefaultAddress} />
				</Form>
				<AddressModal show={this.state.addressModal.show} toggle={this.toggle} addresses={this.props.customer.addresses} address={this.state.addressModal.address} addressField={this.state.addressModal.addressField} updateAddress={this.props.updateAddress} addAddress={this.props.addAddress} />
			</div>
		)		
	}
}

const checkoutShippingFormValidate = values => {
	const errors = {
		deliveryToGo: {},
		deliveryBack: {}
	}

	//Shipping is not possible if the amount of the shipping is equal to -1
	if (values.deliveryToGo.type === 'shipping' && values.deliveryToGo.amount === -1) {
		errors.deliveryToGo.destination = "Livraison impossible à cette adresse. Veuillez modifier l'adresse de livraison"
	}

	if (values.deliveryBack.type === 'shipping' && values.deliveryBack.amount === -1) {
		errors.deliveryBack.origin = "Récupération impossible à cette adresse. Veuillez modifier l'adresse de retrait"
	}


	//Time range if delivery is possible
	if (values.deliveryToGo.type === 'shipping' && values.deliveryToGo.amount > -1 && values.deliveryToGo.timeRange === null) {
		errors.deliveryToGo.timeRange = "Veuillez renseigner une tranche horraire de livraison"
	}
	if (values.deliveryBack.type === 'shipping' && values.deliveryBack.amount > -1 && values.deliveryBack.timeRange === null) {
		errors.deliveryBack.timeRange = "Veuillez renseigner une tranche horraire de récupération"
	}

	if (values.deliveryToGo.amount === null) {
		errors.deliveryToGo.amount = "Le montant de la livraison est obligatoire"
	}

	if (values.deliveryBack.amount === null) {
		errors.deliveryBack.amount = "Le montant de la livraison est obligatoire"
	}

	return errors
}


WizardCheckoutShippingForm = reduxForm({
	form: 'checkoutForm',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate: checkoutShippingFormValidate,
})(WizardCheckoutShippingForm)

WizardCheckoutShippingForm = connect(state => ({
	deliveryToGo: formValueSelector('checkoutForm')(state, 'deliveryToGo') || {},
	deliveryBack: formValueSelector('checkoutForm')(state, 'deliveryBack') || {},
	total: formValueSelector('checkoutForm')(state, 'total'),
}), dispatch => ({
	change: (field, value) => { dispatch(change('checkoutForm', field, value)) },
}))(WizardCheckoutShippingForm)


class CheckoutPaymentForm extends React.Component {
	state = {
		show: false
	}

	toggle = () => {
		this.setState({show: !this.state.show})
	}

	getCardInfo = source => `${source.card.brand} xxxx xxxx xxxx ${source.card.last4} ${source.card.exp_month}/${source.card.exp_year}`


	render = () => {
		const sources = this.props.customer.sources ? this.props.customer.sources.filter(source => source.status === "chargeable") : null

		return (
			<div className="mb-40">
				<Form {...this.props} saveButton={false}>
				{
					sources ? (
						<React.Fragment>
							<ul className="radio-buttons mb-10">
								{
									sources.map((source, index) => <li key={index}><RadioField name="paymentMethod" value={source.id} label={this.getCardInfo(source)} /></li>)
								}
							</ul>
							<a href="#paymentMethodModal" onClick={e => {e.preventDefault(); this.toggle();}}>{sources ? "Ajouter une nouvelle carte de paiement" : "Ajouter une carte de paiement"}</a>
						</React.Fragment>
					) : (
						<p>
							Afin de procéder au paiement merci d'ajouter une carte de paiement<br />
							<a href="#paymentMethodModal" onClick={e => {e.preventDefault(); this.toggle();}}>Ajouter une carte de paiement</a>
						</p>
					)
				}
				</Form>
				<PaymentMethodModal show={this.state.show} toggle={this.toggle} createSource={this.props.createSource} paymentForm="checkoutForm" />
			</div>
		)
	}

}

CheckoutPaymentForm = reduxForm({
	form: 'checkoutForm',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(CheckoutPaymentForm)


let DiscountForm = props => (
	<div className="row">
		<Form {...props} saveButton={false}>
			<div className="col-md-8">
				<TextField name="code" className="input-text form-control" placeholder="Code de réduction" required />
			</div>
			<div className="col-md-4">
				<button type="submit" className="btn btn-lg btn-stroke">Appliquer</button>
			</div>
		</Form>
	</div>	
)

DiscountForm = reduxForm({
	form: 'discountForm'
})(DiscountForm)


class Discount extends React.Component {

	handleSubmit = values => {
		const discounts = this.props.discounts || []

		return this.props.getDiscount(values.code).then(discount => {
			if (discounts.length > 0) {
				const id = discount.id
				//Check if the discount is already added
				const result = discounts.filter(discount => discount.id === id)
				if (result.length > 0) {
					throw new SubmissionError({_error: 'Le coupon a déjà été ajouté'})
				}
			}

			//Add the discount
			discounts.push(discount)
			this.props.change('discounts', discounts)

			//Update the total amount
			if (discount.valueType === 'fixedAmount') {
				const total = this.props.total - discount.value
				this.props.change('total', total < 0 ? 1 : total)
			} else if (discount.valueType === 'percentage') {
				const total = this.props.total - this.props.total * (discount.value/100)
				this.props.change('total', total < 0 ? 1 : total)
			}

			this.props.resetDiscountForm()
		}).catch(error => {
			throw new SubmissionError({code: error.message})
		})
	}

	render = () => <DiscountForm onSubmit={this.handleSubmit} />
}

Discount = connect(state => ({
	discounts: formValueSelector('checkoutForm')(state, 'discounts'),
	total: formValueSelector('checkoutForm')(state, 'total'),
}), dispatch => ({
	change: (field, value) => { dispatch(change('checkoutForm', field, value)) },
	resetDiscountForm: () => { dispatch(reset('discountForm'))}
}))(Discount)



class WizardCheckoutPaymentForm extends React.Component {
	
	render = () => {
		return (
			<div className="col-md-8">
				<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-credit-card"></i> Paiement sécurisé par carte bancaire</h2>
				<CheckoutPaymentForm {...this.props} />
				<h2 className="heading uppercase bottom-line full-grey mb-20"><i className="fa fa-tag"></i> Coupon de réduction</h2>
				<Discount getDiscount={this.props.getDiscount} />
			</div>
		)
	}
}


class Checkout extends React.Component {
	handleSubmit = values => {
		// if (!values.paymentMethod) {
		// 	throw new SubmissionError({_error: "Veuillez ajouter un moyen de paiement avant de procéder au paiement"})
		// }
		
		const deliveries = [values.deliveryToGo, values.deliveryBack]
		//const source = values.paymentMethod ? this.props.customer.sources.filter(source => source.id === values.paymentMethod)[0] : null

		const newOrder = {
			items: values.items,
			deliveries: deliveries,
			billingAddress: values.billingAddress,
			customerId: values.customerId,
			storeId: values.storeId,
			total: values.total
		}

		console.log(newOrder)

		//Create the order
		return this.props.createOrder(newOrder).then(order => {
			ReactGA.event({
				category: 'Checkout',
				action: 'Order created',
				label: order.id,
				value: order.total
			})

			this.props.history.push(`/orders/${order.id}/create`)
		})
	}

	componentWillUnmount = () => {
		this.props.destroyCheckoutForm()
	}

	nextStep = values => {
		const validationPromise = new Promise((resolve, reject) => {
			if (values.deliveryToGo.amount === null || values.deliveryBack.amount === null) {
				reject('Calcul de la livraison en cours')
			} else {
				resolve()
			}
		})

		return validationPromise.then(() => {
			//Go to the next step of the checkout 
			this.props.history.push(`/checkout/${this.props.step + 1}`) 
		}).catch(err => { throw new SubmissionError(err) })
	}

	render = () => {
		this.props.setTitle('Passer commande - Choose by Loxam')
		this.props.setDescription()
		
		return (
			this.props.step ? 
				this.props.isLoading ? <Loading /> : 
					<Layout navLeftComponent={<div></div>} header={false}>
						<PageTitle>
							<h1 className="uppercase">Passer commande</h1>
							<ol className="breadcrumb">
		              			<li>
		                			<Link to="/panier">Panier</Link>
		              			</li>
		              			{this.props.step === 1 ? <li className="active">Livraisons</li> : <li><Link to="/checkout/1">Livraisons</Link></li>}
		            		</ol>
						</PageTitle>
						<section className="section-wrap checkout pb-70 mt-40">
							<div className="container relative">
								<div className="row">
									<div className="ecommerce col-xs-12">
										<div className="checkout ecommerce-checkout row">
											{this.props.step === 1 && (
												<WizardCheckoutShippingForm 
													initialValues={this.props.initialValues} 
													customer={this.props.customer} 
													getStore={this.props.getStore} 
													getDeliveryAmount={this.props.getDeliveryAmount} 
													updateAddress={this.props.updateAddress} 
													addAddress={this.props.addAddress} 
													getDefaultAddress={this.props.getDefaultAddress}
													onSubmit={this.handleSubmit} />)
											}
											<div className="col-md-4">
												<div className="order-review-wrap ecommerce-checkout-review-order" id="order_review">
													{this.props.values && <OrderItemsComponent />}
													<div id="payment" className="ecommerce-checkout-payment">
														<div className="form-row place-order">
															<button className="btn btn-lg btn-dark" style={{width: "100%"}} disabled={this.props.checkoutSubmitting} onClick={this.props.submitCheckoutForm}><span>Valider la commande</span></button>
															{this.props.step === 2 && (<p style={{textAlign: "center", fontSize: "12px", marginTop: "20px"}}><i className="fa fa-lock"></i> Paiement 100% sécurisé</p>)}
								                  		</div>
								                	</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</Layout>

			 : <Redirect to="/checkout/1" push={false} />
		)
	}
}

Checkout = connect(state => ({
	checkoutSubmitting: isSubmitting('checkoutForm')(state),
	values: getFormValues('checkoutForm')(state)
}), dispatch => ({
	destroyCheckoutForm: () => { 
		dispatch(destroy('checkoutForm')) 
	},
	submitCheckoutForm: () => {
		dispatch(submit('checkoutForm'))
	}
}))(Checkout)



export default connectCheckout(Checkout)