import React from 'react'
import PropTypes from 'prop-types'

const Form = ({
	handleSubmit,
	children, 
	error,
	submitting,
	pristine,
	saveButton,
	buttonName
}) => (
	<form onSubmit={handleSubmit}>
		{error && (<div className="alert alert-danger" role="alert">{error}</div>)}
		
		<fieldset disabled={submitting}>
			{children}
		</fieldset>
		{
			saveButton && (
				<button type="submit" className="btn btn-primary" disabled={pristine || submitting}>{buttonName ? buttonName : "Enregistrer"}</button>
			)
		}
	</form>
)

Form.defaultProps = {
	saveButton: true
}

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	error: PropTypes.node,
	submitting: PropTypes.bool.isRequired,
	pristine: PropTypes.bool.isRequired,
	saveButton: PropTypes.bool.isRequired
}

export default Form