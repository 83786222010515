import { ADD_CURRENT_USER } from '../actions'

const auth = (previousState = {user: null}, {type, payload}) => {
	switch (type) {
		case ADD_CURRENT_USER:
			return Object.assign({}, previousState, {
				user: payload.user
			})
		default:
			return previousState
	}
}

export default auth