import React from 'react'
import { connect } from 'react-redux'
import algoliasearch from 'algoliasearch'
import { Link } from 'react-router-dom'
import recombee from 'recombee-js-api-client'


import settings from '../../settings'
import { Layout } from '../containers'
import { AddToCart, Loading, HowToDo, ProductWidgetSlider, Advantage } from '../components'
import { ErrorPage, NotMatchPage } from '../views'
import { FlickityGallery } from '../../gallery'

import chooseIcon from '../assets/img/choosebyloxam-80.png'


const algoliaConfig = settings.algolia


const ContactSupport = props => (
	<div className="call-center">
		<h2>Besoin d'aide ? Un spécialiste répond à toutes vos questions</h2>
		<div className="call-center--container row">
			<div className="col-sm-2 col-xs-12 company-logo">
				<img src="/img/logo_dark2.png" className="img-responsive" alt="Choose by Loxam Logo" />
			</div>
			<div className="col-sm-5 col-xs-12 company-phone-number">
				<p>{props.store.phone}</p>
			</div>
			<div className="col-sm-5 col-xs-12 company-times">
				<p>{props.store.openDescription}</p>
			</div>
		</div>
	</div>
)


const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/">Accueil</Link>
		</li>
		<li>
			<Link to="/recherche">Annonces</Link>
		</li>
		<li>
			<Link to={`/recherche?menu%5Bcategory.name%5D=${encodeURIComponent(props.item.category.name)}`}>{props.item.category.name}</Link>
		</li>
		<li className="active">
			Location {props.item.name}
		</li>
	</ol>
)


const TrustPilotBadge = () => (
	<div className="row trust-pilote">
		<div className="col-sm-6 badge-icon">
			<i className="fa fa-calendar"></i> Disponible à la location<br />
			<i className="fa fa-umbrella"></i> Assurance casse & vol incluse
		</div>
		<div className="col-sm-6">
			<a href="https://fr.trustpilot.com/review/express.e-loue.com" target="_blank">
				<img src="/img/trustpilot.jpg" alt="trust pilot" />
			</a>
		</div>
	</div>
)

const Prices = ({prices}) => {
	//Remove the start at price
	const gridPrices = prices.filter(price => price.time !== -2)

	return (
		<React.Fragment>
			{
				gridPrices.map((price, index) => (<span key={index} className="price"><ins><span className="amount">{price.amount.toFixed(2)}€ /jour</span></ins><br />{price.period}</span>))
			}
		</React.Fragment>
	)
}


const ItemMeta = props => (
	<div className="product_meta">
		<span className="brand_as">Categorie: <Link to={`/recherche?menu%5Bcategory.name%5D=${props.item.category.name}`}>{props.item.category.name}</Link></span>
	</div>
)


class ItemInfo extends React.Component {
	componentDidMount = () => {
		//Accordeon
        const toggleChevron = e => {
            window.$(e.target)
                .prev('.panel-heading')
                .find("a")
                .toggleClass('plus minus');
        }

        window.$('#accordion').on('hide.bs.collapse', toggleChevron)
        window.$('#accordion').on('show.bs.collapse', toggleChevron)

        window.$('#collapseOne').collapse('show')
	}

	getShippingInstruction = () => {
		return {__html: this.props.store.shippingInstruction.replace(/\n/g, "<br />")}
	}

	getShippingPrice = () => {
		if (this.props.user.subscription === "099-0103") {
			return "30€HT/transport"
		}

		if (this.props.user.subscription === "099-0104") {
			return "incluse"
		}
	}

	getShippingTerms = () => {
		if (this.props.user.subscription === "099-0103") {
			return (
				<p>
					* Dans la limite de 10 transports/mois et pour une distance inférireur à 35km. Au delas de 10 transports et pour des distances inférieures à 35 km, un tarif de 30€HT par transport sera appliqué.
					Pour des trajets au-delà de 35 km et pour tout transport de matériel hors gamme Choose by LOXAM, la grille de tarifs transport Loxam s'applique.
				</p>
			)
		}

		if (this.props.user.subscription === "099-0104") {
			return (
				<p>
					* Dans la limite de 10 transports par mois. Si l'opération de transport dépasse 35 km ou au-delà de 10 transports par mois, l'opération sera facturé selon la grille de transport de Loxam affiché en agence et dans le catalogue "tarifs professionnels" disponible sur le site loxam.fr
				</p>
			)
		}
	}

	render = () => {
		return (
			<div className="panel-group accordion mb-50" id="accordion">
				<div className="panel">
					<div className="panel-heading">
						<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" className="minus">Description<span>&nbsp;</span></a>
					</div>
					<div id="collapseOne" className="panel-collapse collapse">
						<div className="panel-body" dangerouslySetInnerHTML={{__html: this.props.item.description}}></div>
					</div>
					<div className="panel-heading">
						<a data-toggle="collapse" data-parent="#accordion" href="#collapse4" className="minus">Livraison<span>&nbsp;</span></a>
					</div>
					<div id="collapse4" className="panel-collapse collapse">
						{
							this.props.item.include === "Gamme Choose" ? (
								<div className="panel-body">
									{this.props.store && <p dangerouslySetInnerHTML={this.getShippingInstruction()} />}
									<p>Tarif livraison* : {this.getShippingPrice()}</p>
									{this.getShippingTerms()}
								</div>
							) : (
								<div className="panel-body">
									{this.props.store && <p dangerouslySetInnerHTML={this.getShippingInstruction()} />}
									<p>
										L'opération sera facturé selon la grille de transport de Loxam affiché en agence et dans le catalogue "tarifs professionnels" disponible sur le site loxam.fr
									</p>
								</div>
							)
							
						}
					</div>
				</div>
			</div>
		)
	}
}


const FollowUs = () => (
	<div className="follow-us">
		<span>Nous suivre:</span>
		<Link className="btn-facebook" to="https://www.facebook.com/eloueexpress" target="blank"><i className="fa fa-facebook"></i></Link>
		<Link className="btn-twitter" to="https://twitter.com/eloue_express" target="blank"><i className="fa fa-twitter"></i></Link>
		<Link className="btn-instagram" to="https://www.instagram.com/eloue_express/" target="blank"><i className="fa fa-instagram"></i></Link>
	</div>
)

const Item = props => (
	<div className="content-wrapper oh single-product-wrapper">
		<section className="section-wrap pb-40 pt-0 single-product">
			<HowToDo />
			<div className="container-fluid semi-fluid">
				<div className="row">
					<div className="product-gallery">
						<div className="col-md-6 col-xs-12 product-slider mb-60">
							<FlickityGallery pictures={props.item.pictures} />
						</div>
						{props.item.include === "Gamme Choose" && <img className="choose-icon" src={chooseIcon} alt="Inclus dans la gamme Choose" />}
					</div>
					<div className="col-md-6 col-xs-12 product-description-wrap">
						<Breadcrumb item={props.item} />
						<h1 className="product-title">{props.item.name}</h1>
						{
							props.item.include === "Hors gamme Choose" && (
								<React.Fragment>
									<Prices prices={props.item.prices} />
									<div className="product-actions">										
										<a className="btn btn-lg btn-dark" style={{width: "100%"}} href="https://agence.loxam.fr/" target="_blank"><span>Réserver directement en agence</span></a>
									</div>
								</React.Fragment>
							)
						}
						{props.item.include === "Gamme Choose" && (
								<React.Fragment>
									<span className="price"><ins><span className="amount">Inclus dans le forfait</span></ins><br />Si moins de 3 réservations simultanées</span>
									<span className="price"><ins><span className="amount">15€ /jour</span></ins><br />Si plus de 3 réservations simultanées</span>
									<AddToCart item={props.item} />
								</React.Fragment>
							)
						}
						<ItemMeta item={props.item} />
						<ItemInfo item={props.item} store={props.store} user={props.user} />
					</div>
				</div>
				<ProductWidgetSlider title={"Annonces de location associées"} item={props.item} user={props.user} />
			</div>
		</section>
		<Advantage />
	</div>
)


class Single extends React.Component {
	state = {
		isLoading: true
	}

	componentWillReceiveProps = nextProps => {
		//if the location change, we get the new item
		if (this.props.location !== nextProps.location) {
			//Reset item in the state and get the new item
			this.setState({ item: null, isLoading: true}, () => {
				this.getObject(this.props.match.params.rentItemId)
			})
		}
	}

	componentDidMount = () => {
		//Init Algolia client and index
		const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apikey)
		this.index  = client.initIndex('rentItems')

		//Init recombee
		if (settings.recombee) {
			this.recombeeClient = new recombee.ApiClient(settings.recombee.id, settings.recombee.token)
		}
		

		//Get object
		this.getObject(this.props.match.params.rentItemId)
	}

	componentDidUpdate = (prevProps, prevState) => {
		//Check if the item is availleble in the current store.
		if (this.state.item && this.props.user && this.props.user.store) {
			const stores = this.state.item.stores.filter(store => store.id === this.props.user.store.id)

			if (stores.length === 0) {
				alert(`Cet article n'est pas disponible dans la boutique de ${this.props.user.store.address.city}`)
			}
		}
	}


	getObject = id => {
		this.index.getObject(id).then(item => {
			//If the item is found but the path not correct we revice the path
			if (this.props.match.params.category !== item.category.slug) {
				this.props.history.push(`/${item.category.slug}/location-${item.objectID}`)
			}

			this.setState({ item: item, isLoading: false }, () => {
				if (this.recombeeClient) {
					this.recombeeClient.send(new recombee.AddDetailView(this.props.user.id, item.objectID))
				}
			})
		}).catch(error => {
			//Add error in state if item not found
			//Error object contain statusCode
			this.setState({isLoading: false, error: error})
		})
	}

	renderErrorComponent = () => {
		return this.state.error.statusCode === 404 ? <NotMatchPage /> : <ErrorPage />
	}

	render = () => {
		const store = this.props.user && this.props.user.store ? this.props.user.store : null
		if (this.state.item) {
			const title = this.state.item.titleTag || `Location ${this.state.item.name} -  Loxam`
			this.props.setTitle(title)
			this.props.setDescription(this.state.item.descriptionTag || null)
		}
		return (
			this.state.isLoading ? (
				<Layout><Loading /></Layout>
			) : (
				this.state.error ? (
					this.renderErrorComponent()
				) : ( 
					<Layout>
						<Item item={this.state.item} user={this.props.user} store={store} />
					</Layout> 
				)
			)
		)
	}
}

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps)(Single)