import React from 'react'
import { Link } from 'react-router-dom'
import recombee from 'recombee-js-api-client'


import settings from '../../settings'


const products = [
  {
    "id": "-Kx2K1FMUrpzRZHfOQAy",
    "category": "Maison",
    "slug": "/maison/location--Kx2K1FMUrpzRZHfOQAy",
    "price": 5.5,
    "title": "Destructeur de documents",
    "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-ef6e02e7-7920-4f96-819d-ebbb8da3e7c7%2FDestructeur%20Fellowes%20Powershred%20W-1C.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=QhQ8hIDJEPA8JSBMx8hDh86Aq0Pduxs%2FgEuOr5nbUoM1efkcn86Pq9ZecqD5hgfpZQVpd%2FbkS2AdmluHlKfa027lJH2tlabCBS3QCoX9u04Qkx0cwcJTb%2B27okmbwf3zs9pUdAxgy9Gm3Fz1cBKRzUX7h0Jdq%2B333xtC58Xx7p1y1o%2F50zXFwl877OxYpyRNjnGdAB5sT22WPRTMQJGQ76IxqDHNO5tPdQndvq30WBAEnsscOAytgAJfSts1WivFlrP6kG4fEMYycvdb6DTdDYqFe2g%2FBa08Pn5D568ANqL0sya7VTEybfbYYwRBwacfKMYDJEGDUS9imSjOgkwXtg%3D%3D"
  },
  {
    "id": "-KyAR7LbgFH7BC7a9Dcm",
    "category": "Maison",
    "slug": "/maison/location--KyAR7LbgFH7BC7a9Dcm",
    "price": 29.99,
    "title": "Cimex Eradicator Polti - nettoyeur vapeur",
    "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F1-632568a3-dbcd-4a06-9f49-ec791f1da800%2Fcimex_eradicator_2016.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=UeR1o8JsKgX%2FXoFrgRk3ITQa6e4%2FWu6snK6Cnoj2qoZ4LSCPg5m3fVlW6vjj5LlPFdskHUYm8NLkRyPFzXNJ65x0z5qf9PLCPquTuNP4po%2BgQb7aDPLuZjiEcvDshOVqSlDGtYnsI%2F7zmNHBtN63TOevxU86sV2WPedvQXlz4qYCVoLMb9CYaBKwBrsd3Y4JZDnjJEdeolR%2FbzI5br%2F55xG8uRfH4DpKgFEZTGPbkyWTE15zGDQVb0Nwn0Mbd9NjDopQFziRDnm8u2VdamVAQHDs4giwBEUbkZyBVL0X5LCM5o8KQN6YN8c2yGj2SlDUMTy%2BVj6i1WtEq3T7BcrIfA%3D%3D"
  },
  {
    "id": "-KlY7HuwIgJH_iC3bPui",
    "category": "Maison",
    "slug": "/maison/location--KlY7HuwIgJH_iC3bPui",
    "price": 12.5,
    "title": "Nettoyeur Vapeur 901-1600 Watts",
    "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-91994f5d-bd36-4713-8518-77d606f5d044%2Fkarch.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=UV%2FWe4nWh%2BEeE9andeQkwaQMC%2F2YHO%2BPA1Xfbr0oFs2cUigbF9Ame56t9SJLjlzeQJjYYvxblxWOtMCd2A6q5xrmaARjlxbEu6O0SD5wy2szu9p1eE1%2FbpvvYF3zsNGmx%2F0BRxhSSNYPiRBRwWAjaL%2FYMnXwxIMurLsEyjzZxLqIDNtorl28H%2Fye1gRRp18YtyQunFkUWDn1qagYsiXUZAhr9SIR1AgHf%2BgvuAWl0i60HlJagyOn%2FoGZBloBV9rT1yV7dk9h6%2F8hWEZJQt%2FaUGCk8YRWeLhAb1nF3D8eI%2F1bvXn%2BBhoO7uI38VYT5v6JsrJwMTleTrWW9o0YvR%2Bq9Q%3D%3D"
  },
  {
    "id": "-L2KP_UsIYV5f-CPXq2n",
    "category": "Maison",
    "slug": "/maison/location--L2KP_UsIYV5f-CPXq2n",
    "price": 7.5,
    "title": "Déshumidificateur De'longhi AriaDry Compact 20L - 410W",
    "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0--L2KP_UsIYV5f-CPXq2o%2F9788e564-8060-4913-a0f6-1f5badc184e8.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=BjKWieIZFO6DmbxApO9L9lcztX6v%2Fun4m%2FfF9cc5296uFDRPKIr9L4KfHdM6NkLboHXA5pdPfsd6PPlt6OH0m6%2FmtX6ITMYrqG8YWe1RXfjfvNr60fKw68tUmj1C9oFKyXQhxNU1TybejKDXn5%2BiAnuwRMsOosIRQb1CEcuiQtoZj2M2oC1o2hssRku004%2BU46VJPrrc0ed0tXHhSpFJLa85LICCKhNwTrnGR2rHIoMeb1d%2FUvO%2Fc7MwVhf%2BfyYmtWHy6QyZ6p7ryv9J%2FHj90NczAGytjzWI7YjLqU8QRuCHJ3l7iXMKEHwkabT2LyaapP1aro9Loq0xC7LX63UdEg%3D%3D"
  },
  {
    "id": "-KograEtiXs-Zw4KVELy",
    "category": "Maison",
    "slug": "/maison/location--KograEtiXs-Zw4KVELy",
    "price": 8.2,
    "title": "Machine à coudre Singer",
    "picture": "https://storage.googleapis.com/rexpress-prod.appspot.com/thumb%2F0-24048dd4-c5d2-465b-ac87-1098a89b2f51%2Fsewing-machine.jpg?GoogleAccessId=firebase-adminsdk-498kl@rexpress-prod.iam.gserviceaccount.com&Expires=16447017600&Signature=Jq4hO7lL6qiEPYyzl0MCcPENxVZbFvxtmlQ1FJi49xWOcGpXmWcg9mhrokO1vPpQBMHfF7thOZtUu8DE%2FsGWFIEhY2lctjTtn8fT%2F%2FczbhsJnNP5VG6te%2B0wK2SnVometTdnY%2B1yLYy15t1JXi7TepwZ3AAeevHHC74CEmCVEdtVYXmpFLz%2BXdHpqFfr2HAEI9EosPS%2FZj4Gi7qv%2BnRbPUbguKHVDTS%2BCGh%2FcftbhnarP0xl42e1QcbV1j1xtrl8cXLNfMLvXdY0TaXj7%2FWuBNim7ypwVeRLgdjYDFghTV8kVrL6jjscENeww0kjsYKTE%2B%2BgsB6dcjr2taGo%2BswOJA%3D%3D"
  }
]


const Product = ({ product }) => (
	<div className="product">
		<div className="product-item hover-trigger">
			<div className="product-img">
				<Link to={product.slug}>
					<img src={product.picture} alt="" />
				</Link>
			</div>
			<div className="product-details" style={{maxWidth: "100%"}}>                      
				<h3 className="product-title">
					<Link to={product.slug}>{product.title}</Link>
				</h3>
				<span className="category">
					<Link to={product.slug}>{product.category}</Link>
				</span>
			</div>
			<span className="price" style={{float: "left"}}>
				<ins>
					<span className="amount">{product.price}€ /jour</span>
				</ins>
			</span>
		</div>
	</div>
)

const ProductList = ({ products }) => (
	<div className="row">
		<div id="owl-related-items" className="owl-carousel owl-theme">
			{products && products.map((product, index) => <Product key={index} product={product} />)}
		</div>
	</div>
)

const ProductHeader = ({ title }) => (
	<div className="row heading-row">
		<div className="col-md-12 text-center">
			<h2 className="heading bottom-line">
				{title}
			</h2>
		</div>
	</div>
)

class ProductWidgetSlider extends React.Component {
	state = {}

	getRecommendations = () => {
		if (this.props.user && this.props.user.store && this.props.user.store.id) {
			this.recombeeClient.send(new recombee.RecommendItemsToItem(
				this.props.item.id,
				this.props.user.id,
				8,
				{
					returnProperties: true,
					includedProperties: ['title', 'category', 'price', 'picture', 'slug'],
					filter: `'category' == "${this.props.item.category.name}" AND 'online' AND "${this.props.user.store.id}" in 'stores'`,
					scenario: 'detailbookingpage'
				}
			)).then(recommendation => {
				const products = recommendation.recomms.map(recomm => Object.assign({id: recomm.id}, recomm.values))

				this.setState({products})
			}).catch(err => {console.log(err, 'error')})
		} else {
			this.setState({products: []})
		}

	}


	componentDidMount = () => {
      	if (settings.recombee) {
      		this.recombeeClient = new recombee.ApiClient(settings.recombee.id, settings.recombee.token)
      		this.getRecommendations()
      	} else {
      		this.setState({products: []})
      	}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (!prevState.products && this.state.products) {
			//init carousel when products are loaded
			window.$("#owl-related-items").owlCarousel({
				autoPlay: 2500,
				pagination: false,
				navigation: true,
				navigationText: ["<i class='ui-left-arrow'></i>", "<i class='ui-right-arrow'></i>"],
				itemsCustom: [
	          		[0, 1],
	          		[370, 2],
	          		[550, 3],
	          		[700, 4],
	          		[1000, 4]
	        	],
	      	})
		}
	}

	render = () => (
		<section className="section-wrap pt-0 shop-items-slider mt-40">
			{this.state.products && this.state.products.length > 0 && (
				<div className="container">
					<ProductHeader title={this.props.title} />
					<ProductList products={this.state.products} />
				</div>
			)}
		</section>
	)
}

export default ProductWidgetSlider