import React from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import 'airbnb-js-shims'
import { SingleDatePicker } from 'react-dates'

import moment from 'moment'


import Field from './Field'

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },

    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
})

const OPEN_HOUR = 9
const CLOSE_HOUR = 19

const timeChoices = [
	{label:"09:00", value: "9:0"},
	{label:"09:30", value: "9:30"},
	{label:"10:00", value: "10:0"},
	{label:"10:30", value: "10:30"},
	{label:"11:00", value: "11:0"},
	{label:"11:30", value: "11:30"},
	{label:"12:00", value: "12:0"},
	{label:"12:30", value: "12:30"},
	{label:"13:00", value: "13:0"},
	{label:"13:30", value: "13:30"},
	{label:"14:00", value: "14:0"},
	{label:"14:30", value: "14:30"},
	{label:"15:00", value: "15:0"},
	{label:"15:30", value: "15:30"},
	{label:"16:00", value: "16:0"},
	{label:"16:30", value: "16:30"},
	{label:"17:00", value: "17:0"},
	{label:"17:30", value: "17:30"},
	{label:"18:00", value: "18:0"},
	{label:"18:30", value: "18:30"},
	{label:"19:00", value: "19:0"}
]


/*
 * Return true if the date is today
 */
const isToday = (date) => {
	const today = moment().startOf('day')
	return date.isSame(today, 'd') && date.isSame(today, 'month') && date.isSame(today, 'year')
}


/*
 * Return the date with the correct day and hours
 */
const getDateTime = (date, openHour=OPEN_HOUR, closeHour=CLOSE_HOUR) => {
	if (isToday(date)) {
		const today = moment()
		let hour = today.get('hour')
		let minute = today.get('minute')

		if (minute >= 30) {
			hour += 2
			minute = 0
		} else if (minute < 30) {
			hour += 1
			minute = "30"
		}

		if (hour <= closeHour) {
			date.hour(hour).minute(minute)
		} else {
			date.add(1, 'days').hour(openHour).minute(0)
		}

	} else {
		date.hours(openHour).minutes(0)
	}

	return date
}


class DateTimePicker extends React.Component {
	state = {
		focused: false,
	}

	timeChoiceIsActive = time => {
		let isActive = true

		if (this.props.input.value && this.props.openHours) {
			//Unactivate hour out of open store
			let currentTime = time.split(":")
			currentTime = currentTime.map(item => parseInt(item, 10))
			const dateTime = moment(this.props.input.value).hours(currentTime[0]).minutes(currentTime[1])
			const day = dateTime.day()

			//Get the openHours of the dateTime
			let dayOpenHours = this.props.openHours.filter(openHour => {
				return openHour.openDays.indexOf(day) === -1 ? false : true
			})

			if (dayOpenHours.length > 0) {
				
				dayOpenHours = dayOpenHours.map(dayOpenHour => {
					//Split hour and minute for close and open hour
					const closeHour = dayOpenHour.closeHour.match(/.{1,2}/g)
					const openHour = dayOpenHour.openHour.match(/.{1,2}/g)

					//Create close and open datetTime
					const closeDateTime = dateTime.clone().hours(parseInt(closeHour[0], 10)).minutes(parseInt(closeHour[1], 10))
					const openDateTime = dateTime.clone().hours(parseInt(openHour[0], 10)).minutes(parseInt(openHour[1], 10))


					return [openDateTime.valueOf(), closeDateTime.valueOf()]
				})

				dayOpenHours = dayOpenHours.filter(dayOpenHour => {
					return dayOpenHour[0] <= dateTime.valueOf() && dateTime.valueOf() <= dayOpenHour[1]
				})

				isActive = dayOpenHours.length > 0
			}

			//Unactive past hour
			if (isToday(moment(this.props.input.value)) && isActive) {
				const now = moment()

				isActive = now.diff(dateTime) < 0
			}
		}

		return isActive

		// if (this.props.input.value && isToday(moment(this.props.input.value))) {
		// 	//Check diff between now and the date with the given time
		// 	let currentTime = time.split(":")
		// 	currentTime = currentTime.map(item => parseInt(item, 10))
		// 	const dateTime = moment().hours(currentTime[0]).minutes(currentTime[1])

		// 	const now = moment()

		// 	if (dateTime.diff(now) < 0) {
		// 		return false
		// 	} else {
		// 		return true
		// 	}
		// } else {
		// 	return true
		// }
		
	}

	getTime = date => {
		if (date) {
			return `${date.get('hours')}:${date.get('minutes')}`
		} else {
			return ""
		}
		
	}

	onTimeChange = event => {
		let time = event.target.value.split(":")
		time = time.map(item => parseInt(item, 10))
		const dateTime = moment(this.props.input.value).hours(time[0]).minutes(time[1])
		this.props.input.onChange(dateTime.valueOf())
	}

	onDateChange = dateTime => {
		if (this.props.input.value) {
			const currentDateTime = moment(this.props.input.value)
			dateTime.hours(currentDateTime.hours()).minutes(currentDateTime.minutes())

			if (isToday(dateTime) && dateTime.valueOf() < moment().valueOf()) {
				this.props.input.onChange(getDateTime(dateTime).valueOf())
			} else {
				this.props.input.onChange(dateTime.valueOf())
			}
		} else {
			this.props.input.onChange(getDateTime(dateTime).valueOf())
		}
		
		
	}

	render = () => {
		const dateTime = this.props.input.value ? moment(this.props.input.value) : null
		const time = dateTime ? this.getTime(dateTime) : ""
		return (
			<div className={`col-sm-6 col-xs-12 dateContainer ${this.props.input.value ? '' : 'emptyField'}`}>
				<div className="col-xs-6 datepickup">
					<SingleDatePicker
						numberOfMonths={1}
					  	date={dateTime}
					  	onDateChange={this.onDateChange}
					  	focused={this.state.focused}
					  	onFocusChange={({ focused }) => this.setState({ focused: focused })} 
					  	isOutsideRange={this.props.isOutsideRange} 
					  	disabled={this.props.disabled}
					  	placeholder={this.props.placeholder} />
				</div>
				{this.props.input.value && (
					<div className="col-xs-6 hourpickup">
						{this.props.input.value && (
							<select 
								ref={input => this.timeInput = input}
								onChange={this.onTimeChange} 
								value={time} 
								disabled={this.props.disabled} >
								{!this.props.input.value && <option value="">Heure</option>}
								{timeChoices.map((choice, index) => (
									<option key={index} value={choice.value} disabled={!this.timeChoiceIsActive(choice.value)}>{choice.label}</option>
								))}
							</select>
						)}
					</div>
				)}
				<div className="help-block">{this.props.touched && this.props.error}</div>
			</div>
		)
	}
}


DateTimePicker.propTypes = {
	disabled: PropTypes.bool,
	placeholder: PropTypes.string
}

const DateTimeField = props => (
	<Field {...props} component={DateTimePicker} />
)

export default DateTimeField
