import React from 'react'
import PropTypes from 'prop-types'

import { getAll } from '../clients/restClient'
const queryString = require('query-string')

// parsed parameters
// Pagination : page=[num of the page], perPage=[num per page]
// Order: orderBy=[name of the field], sort=[direction to sort (asc or desc)]
// Filter: [fieldName]=[value of the filter],[operation string (<, <=, ==, >, >=)]
//TODO: add validation
//TODO: Redirect to the 404 if collection not found
export class QueryStringList {
	constructor(queryParams) {
		this.queryParams = queryParams
	}

	addPage = (numPage) => {
		this.queryParams.pagination.page = numPage
		return new QueryStringList(this.queryParams)
	}

	addOrder = (field, sort) => {
		this.queryParams.orderBy.field = field
		this.queryParams.orderBy.sort = sort
		return new QueryStringList(this.queryParams)
	}

	addFilters = (filters) => {
		//Reset filters
		this.queryParams.filters = []
		filters.forEach(filter => {
			if (filter.operator) {
				this.queryParams.filters.push({field: filter.field, operator: filter.operator, value: filter.value})
			} else {
				this.queryParams.filters.push({field: filter.field, operator: "==", value: filter.value})
			}
		})
		return new QueryStringList(this.queryParams)
	}

	get = () => {
		const query = []
		//Generate filter queryString ([fieldName]=[value of the filter],[operation string (<, <=, ==, >, >=)])
		this.queryParams.filters.forEach(filter => {
			query.push(`${filter.field}=${filter.value},${filter.operator ? filter.operator : "=="}`)
		})

		//Generate order and sort queryString (orderBy=[name of the field], sort=[direction to sort (asc or desc)])
		if (this.queryParams.orderBy && this.queryParams.orderBy.field) {
			query.push(`orderBy=${this.queryParams.orderBy.field}`)

			if (this.queryParams.orderBy.sort) {
				query.push(`sort=${this.queryParams.orderBy.sort}`)
			}
		}

		// Pagination : page=[num of the page]
		if (this.queryParams.pagination && this.queryParams.pagination.page) {
			query.push(`page=${this.queryParams.pagination.page}`)
		}

		// Pagination :	perPage=[num per page]
		if (this.queryParams.pagination && this.queryParams.pagination.perPage) {
			query.push(`perPage=${this.queryParams.pagination.perPage}`)
		}

		return query.join("&")
	}
}

const connectList = (List) => {
	class ConnectList extends React.Component {
		state = {
			docs: [],
			count: 0,
			isLoading: true,
			queryParams: {
				pagination: {perPage: 25, page: 1},
				filters: [],
				orderBy: {sort: "asc"}
			}
		}

		updateQueryParams = (location) => {
			//Get query string from the url
			const parsedParams = queryString.parse(location.search)

			const queryParams = {
				pagination: {perPage: 25, page: 1},
				filters: [],
				orderBy: {sort: "asc"}
			}

			//Set queryParams from parsedParams
			Object.keys(parsedParams).forEach(key => {
				const value = parsedParams[key]

				if (value) {
					switch (key) {
						case "page":
							queryParams.pagination.page = parseInt(value, 10)
							break
						case "perPage":
							queryParams.pagination.PerPage = parseInt(value, 10)
							break
						case "orderBy":
							queryParams.orderBy.field = value
							break
						case "sort":
							queryParams.orderBy.sort = value
							break
						default:
							const parameters = value.split(',')
							if (parameters[1]) {
								queryParams.filters.push({field: key, operator: parameters[1], value: parameters[0]})
							} else {
								queryParams.filters.push({field: key, operator: "==", value: parameters[0]})
							}
					}
				}
			})

			// //By default the first field is use for order if it's not set
			// if (!queryParams.orderBy.field) {
			// 	//Get the first cell
			// 	const firstCell = this.props.children[0]
			// 	queryParams.orderBy.field = firstCell.props.name
			// }

			return queryParams
		}

		loadData = (location) => {
			this.setState({isLoading: true})

			const queryParams = this.updateQueryParams(location)

			this.setState({queryParams: queryParams})

			getAll(this.props.name, queryParams).then(response => {
				this.setState({docs: response.docs, count: response.count , isLoading:false, queryParams: queryParams})
			}).catch(error => {
				console.log(error)
				this.setState({isLoading: false, queryParams: queryParams})
			})
		}

		//Update the location to get data
		getData = (values) => {
			return new Promise((resolve, reject) => {
				const filters = []

				const filterKeys = Object.keys(values)
				if (filterKeys) {
					//Create filters list
					filterKeys.forEach(key => {
						if (values[key]) {
							filters.push({field: key, value: values[key]})
						}
					})

					//Init queryString with the current location
					let queryString = new QueryStringList(this.state.queryParams)
					//Add filters values and reset pagination
					queryString = queryString.addFilters(filters).addPage(1)
				
					//Update the location and push it
					let location = this.props.location
					location.search = queryString.get()
					this.props.history.push(location)
				}

				resolve()
			})
			
		}

		componentWillReceiveProps = (nextProps) => {
			this.loadData(nextProps.location)
		}

		componentDidMount = () => {
			this.loadData(this.props.location)
		}

		render = () => (<List {...this.props} docs={this.state.docs} getData={this.getData} count={this.state.count} isLoading={this.state.isLoading} queryParams={this.state.queryParams} {...this.props} />)
	}

	ConnectList.propTypes = {
		location: PropTypes.object.isRequired
	}

	return ConnectList
}

export default connectList