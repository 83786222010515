import auth from './auth'
import notification from './notification'

import { combineReducers } from 'redux'

//Redux Form
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  auth,
  notification,
  form: formReducer
})