/*
 * action types
 */
export const ADD_CURRENT_USER = 'ADD_CURRENT_USER'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'


/*
 * action creators
 */

//User actions
export const addCurrenUser = (user) => {
	return {
		type : ADD_CURRENT_USER,
		payload: { user }
	}
}


//Notification actions
export const showNotification = (text, type = 'info') => ({
    type: SHOW_NOTIFICATION,
    payload: { text, type }
})
