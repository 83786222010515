import React from 'react'


import axaLogo  from '../assets/img/axa-logo-white.svg'
import economyIcon from '../assets/img/economy.svg'
import flexIcon from '../assets/img/flex.svg'
import choiceIcon from '../assets/img/choice.svg'
import serenityIcon from '../assets/img/serenity.svg'


const Advantage = () => (
	<section className="el-panel call-to-action bg-light">
		<div className="container">
			<div className="row">
				<div className="col-md-3" style={{textAlign: "center", margin: "15px 0"}}>
					<img className="mb-10" height="60" src={choiceIcon} alt="+ d'economie" />
					<h4><span style={{color: "#e40115"}}>+</span> de choix</h4>
					<p>Un matériel récent, contrôlé, de grande marque, compact, léger et maniable.</p>
				</div>
				<div className="col-md-3" style={{textAlign: "center", margin: "15px 0"}}>
					<img className="mb-10" height="60" src={economyIcon} alt="+ d'economie"/>
					<h4><span style={{color: "#e40115"}}>+</span> d'économie</h4>
					<p>Bénéficiez d'un prix de location attractif, fixe, mensualisé quel que soit le matériel de la gamme Choose by Loxam.</p>
				</div>
				<div className="col-md-3" style={{textAlign: "center", margin: "15px 0"}}>
					<img className="mb-10" height="60" src={flexIcon} alt="+ d'economie" />
					<h4><span style={{color: "#e40115"}}>+</span> de flexibilité</h4>
					<p>1 prix unique, 78 références au choix, pour vos besoins récurrents et ponctuels de matériels.</p>
				</div>
				
				<div className="col-md-3" style={{textAlign: "center", margin: "15px 0"}}>
					<img className="mb-10" height="60" src={serenityIcon} alt="+ d'economie" />
					<h4><span style={{color: "#e40115"}}>+</span> de sérénité</h4>
					<p>Profitez de tous les avantages de la location : prix compétitif, entretien, dépannage, remplacement.</p>
				</div>
			</div>
		</div>
	</section>
)

export default Advantage