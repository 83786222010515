import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import recombee from 'recombee-js-api-client'

import { PageTitle, Loading } from '../components'
import { Layout } from '../containers'
import { ErrorPage, NotMatchPage } from '../views'

import { remove } from '../../core/clients/restClient'
import { connectCharge } from '../../order'

import settings from '../../settings'



class ChargeInfo extends React.Component {

	getParams = () => {
		let params = this.props.location.search

		if (params) {
			//delete ? character
			params = params.substring(1)

			//convert array in object
			params = params.split("&").reduce((acc, item) => {
				const arr = item.split('=')
				acc[arr[0]] = arr[1]
				return acc
			}, {})

			return params
		} else {
			return {}
		}
	}

	getChargeStatus = () => {
		let state = {
			title: "Paiement en cours...", 
			description: "Votre paiement est en cours de traitement.",
			redirect: false,
			orderLink: false
		}

		if (this.props.charge) {
			const charge = this.props.charge
			const params = this.getParams()

			if (charge.error || charge.status === 'failed') {
				state = {
					title: "Echec du paiement",
					description: `Nous n'avons pas pu effectuer le paiement. Essayer de refaire le paiement en accédant au détail de votre commande.`,
					redirect: false,
					orderLink: true,
					error: charge.error
				}
			} else {
				//3d source not created yet
				if (charge.status === undefined && charge.source.type === 'card') {
					state = {
						title: "Paiement en cours...",
						description: "D'ici quelques secondes, vous allez être redirigé pour vérifier l'identité du porteur de la carte.",
						redirect: false,
						orderLink: false,
						error: null
					}
				}


				//3d source is created and pending 3D secure validation
				if (charge.status === 'pending' && charge.source.type === 'three_d_secure' && charge.source.redirect.status === 'pending') {
					state = {
						title: "Paiement en cours...",
						description: "D'ici quelques secondes, vous allez être redirigé pour vérifier l'identité du porteur de la carte.",
						redirect: true,
						orderLink: false,
						error: null
					}
				}


				//3d source is created and 3d secure validation done
				if ((charge.status === 'pending' && charge.source.type === 'three_d_secure' && charge.source.id === params.source && charge.source.status === 'pending') || (charge.status === 'pending' && charge.type === 'three_d_secure' && charge.source.status === 'chargeable')) {
					state = {
						title: "Paiement en cours...",
						description: "Paiement en cours... Merci de patienter quelques secondes pour la confirmation.",
						redirect: false,
						orderLink: false,
						error: null
					}
				}


				//Payment succeed
				if (charge.status === 'succeeded' && charge.captured === true && charge.paid === true) {
					state = {
						title: "Paiement effectué avec succès.",
						description: "Votre commande a bien été payé. Cliquez sur le lien ci-dessous pour voir le détail de la commande",
						redirect: false,
						orderLink: true,
						error: null
					}
				}
			}
		}

		return state
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		const params = this.getParams()

		if (this.props.charge && this.props.charge.status === 'pending' && this.props.charge.source.type === 'three_d_secure' && this.props.charge.source.redirect.status === 'pending' && params.source === undefined) {
			window.location.replace(this.props.charge.source.redirect.url)
		}
	}

	render = () => {
		const content = this.getChargeStatus()

		return (
			<div className="content-wrapper oh">
				<section className="section-wrap checkout pb-70">
					<div className="container relative">
						<div className="row">

							<div className="ecommerce col-xs-12">

	              				<div className="checkout ecommerce-checkout row">

	                				<div className="col-md-6 col-md-offset-3">
	                  					<div className="ecommerce-checkout-payment">
	                   		 				<h2 className="heading uppercase bottom-line full-grey mb-20 text-center">{content.title} {content.error && `(${content.error})`}</h2>
	                    					<p>{content.description}</p>
	                    					{content.redirect && <a href={this.props.charge.source.redirect.url}>Cliquez ici, si vous n'etes pas rediriger automatiquement</a>}
	                    					{content.orderLink && <Link to={`/orders/${this.props.charge.orderId}`}>Accéder aux détails de la commande</Link>}
	                  					</div>
	                				</div>
	              				</div>
	            			</div>
	         			</div>
	        		</div>
	      		</section>
			</div>
		)
	}
}


class Payment extends React.Component {
	componentDidMount = () => {
		this.props.getCharge(this.props.match.params.paymentId)

		//Init recombee
		if (settings.recombee) {
			this.recombeeClient = new recombee.ApiClient(settings.recombee.id, settings.recombee.token)
		}
	}

	renderError = () => this.props.error.statusCode === 404 ? <NotMatchPage /> : <ErrorPage />

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		const charge = this.props.charge
		if (charge && charge.status === 'succeeded' && charge.captured === true && charge.paid === true && this.props.user.orderPending && this.props.user.orderPending === charge.orderId) {
			this.props.getOrder(charge.orderId).then(order => {
				ReactGA.event({
					category: 'Checkout',
					action: 'Order paid',
					label: order.id,
					value: order.total
				})

				ReactGA.plugin.execute(
					'ecommerce',
					'addTransaction',
					{
						id: order.id,
						affiliation: settings.stores[order.storeId].city,
						revenue: order.total.toFixed(2)
					}
				)

				order.items.forEach(item => {

					ReactGA.plugin.execute(
						'ecommerce',
						'addItem',
						{
							id: order.id,
							name: item.name,
							sku: item.id,
							category: item.category,
							price: item.totalAmount.toFixed(2),
							quantity: `${item.quantity}`
						}
					)
				})

				ReactGA.plugin.execute('ecommerce', 'send')
	    		ReactGA.plugin.execute('ecommerce', 'clear')

	    		//Remove the pending order when the order is paid
	    		remove('orderPending', `/users/${this.props.user.uid}`).then()

	    		if (this.recombeeClient) {   			
		    		order.items.forEach(item => {
		    			this.recombeeClient.send(new recombee.AddPurchase(this.props.user.id, item.objectId))
		    		})
		    	}


			})
		}
	}

	render = () => {
		this.props.setTitle("Paiement - Choose by Loxam")
		this.props.setDescription()
		return (
			this.props.isLoading ? <Loading /> : this.props.error ? this.renderError() : (
				<Layout hideBanner={true}>
					<PageTitle title="Paiement" />
					<ChargeInfo charge={this.props.charge} location={this.props.location} />
				</Layout>
			)
		)
	}
}

export default connectCharge(Payment)