import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { SearchBox } from 'react-instantsearch/dom'

import settings from '../../settings'
import NavMenuLinks from './NavMenuLinks'
import { InstantSearchRoute } from '../../algolia'

import logo from '../assets/img/logo_choose_green.svg'

const algoliaConfig = settings.algolia

const Header = props => (
    <header className="nav-type-1">
        <nav className="navbar navbar-static-top" style={{marginBottom: "0px"}}>
            <div className="navigation" id="sticky-nav">
                <div className="container relative">
                    <div className="nav-menu">
                        <div className="nav-menu-logo navbar-header">
                            <div className="logo-container">
                                <div className="logo-wrap">
                                    <Link to="/">
                                        <img className="logo-dark" src={logo} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="nav-menu-text nav-left nav-cart-express">
                        {
                            props.navLeftComponent ? props.navLeftComponent : (
                                <InstantSearchRoute
                                    appId={algoliaConfig.appId}
                                    apiKey={algoliaConfig.apikey}
                                    indexName="rentItems"
                                    searchAsYouType={false} 
                                    {...props} >
                                    <SearchBox translations={{placeholder: 'Que recherchez-vous ?'}} searchAsYouType={false} />
                                </InstantSearchRoute>
                            )
                        }
                        </div>
                        <div className="nav-menu-links nav-right nav-cart-express">
                            <NavMenuLinks />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
)

Header.propTypes = {
  navLeftComponent: PropTypes.element
}

export default withRouter(Header)