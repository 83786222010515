import React from 'react'
import { Link } from 'react-router-dom'
import { Hits, Configure, ScrollTo, Pagination, Menu, ClearAll, SearchBox } from 'react-instantsearch/dom'
import { connect } from 'react-redux'

import settings from '../../settings'
import { InstantSearchRoute } from '../../algolia'
import { Layout } from '../containers'
import { HowTo, HowToDo, PressAboutUs } from '../components'

import chooseIcon from '../assets/img/choosebyloxam-80.png'

const algoliaConfig = settings.algolia

const ProductItem = ({hit}) => {
	//Get price by day
	const price = hit.prices.filter(price => price.time === -2)[0]

	return (
		<div className="col-xs-4 product product-grid">
			<div className="product-item clearfix">
				<div className="product-img hover-trigger">
					<Link to={`/${hit.category.slug}/location-${hit.objectID}`}>
						<img src={hit.pictures[0].thumb_url} alt="" />
					</Link>
					<Link to={`/${hit.category.slug}/location-${hit.objectID}`} className="product-quickview">En savoir plus</Link>
					{hit.include === "Gamme Choose" && <img className="choose-icon" src={chooseIcon} alt="Inclus dans la gamme Choose" />}
				</div>
				<div className="product-details">
					<h3 className="product-title">
						<Link to={`/${hit.category.slug}/location-${hit.objectID}`} className="product-quickview">{hit.name}</Link>
					</h3>
					<span className="category">
						{hit.category.name}
					</span>
					<br />
					<span className="price">
						<ins>
							<span className="amount">{hit.include === "Gamme Choose" ? "Inclus dans le forfait" : `A partir de ${price.amount}€ /jour`}</span>
						</ins>
					</span>
				</div>
			</div>
		</div>
	)
}

const ProductFilterSection = () => (
	<React.Fragment>
		<div className="navbar-header">
			<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
				<span className="sr-only">Toggle navigation</span>
				<span className="navbar-toggle-label">Filtres</span>
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
			</button>
		</div>
		<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
			<div className="nav navbar-nav">
				<div className="widget categories">
					<h3 className="widget-title heading uppercase relative bottom-line full-grey" >Gammes</h3>
					<Menu attributeName="include" />
				</div>
				<div className="widget categories">
					<h3 className="widget-title heading uppercase relative bottom-line full-grey" style={{marginTop: "150px"}}>Categories</h3>
					<Menu attributeName="category.name" />
				</div>
				<div className="widget">
					<ClearAll translations={{ reset: "Supprimer les filtres"}} />
				</div>
			</div>
		</div>
	</React.Fragment>
)

const getIncludeTitle = include => {
	if (include === undefined || include === "") {
		return <React.Fragment><span style={{color: "#00A583"}}>Gamme Choose</span> <img className="choose-icon" src={chooseIcon} alt="Gamme Choose" /> et <span style={{color: "#e40115"}}>Hors gamme Choose</span></React.Fragment>
	} else if (include === "Gamme Choose") {
		return <span style={{color: "#00A583"}}>Gamme Choose <img className="choose-icon" src={chooseIcon} alt="Gamme Choose" /></span>
	} else if (include === "Hors gamme Choose") {
		return <span style={{color: "#e40115"}}>Hors gamme Choose</span>
	}
}

const ProductListTitle = props => {
	const query = props.searchState.query
	const category = props.searchState.menu && props.searchState.menu['category.name'] ? props.searchState.menu['category.name'] : undefined
	const include = props.searchState.menu && props.searchState.menu.include ? props.searchState.menu.include : undefined

	console.log()

	return (
		<React.Fragment>
			<h3 className="mb-40">
				{
					query ? `Résultat(s) de recherche pour "${query}"` : category ? `Location ${category}` : "Résultat(s) de recherche"	
				}
				{
					<small style={{fontSize: "12px"}}> ({getIncludeTitle(include)})</small>
				}
			</h3>
		</React.Fragment>
	)

}	

const ProductListSection = () => (
	<div className="shop-catalogue grid-view">
		<div className="row items-grid row-eq-height">
			<Hits hitComponent={ProductItem} defaultRefinement={21} />
		</div>
	</div>
)

const PaginationSection = () => (
	<div className="pagination-wrap clearfix">
		<Pagination showLast={true} />
	</div>
)


class Search extends React.Component {
	state = {
		searchState: {}
	}

	handleSearchStateChange = searchState => {
		this.setState({ searchState })
	}

	render = () => {
		const name = this.props.user && this.props.user.store ? this.props.user.store.name : "e-loue express Paris"
		this.props.setTitle('Recherche - Choose')
		return (
			<InstantSearchRoute
				appId={algoliaConfig.appId}
				apiKey={algoliaConfig.apikey}
				indexName="rentItems"
				searchStateChange={this.handleSearchStateChange}
				{...this.props} >
				<ScrollTo>
					<Layout navLeftComponent={<SearchBox translations={{placeholder: 'Chercher par mot clé'}} />}>
						<Configure hitsPerPage={21} filters={`stores.name:"${name}"`} />
						<section className="el-panel catalogue" style={{paddingTop: "30px"}}>
							<div className="container relative">
								<div className="row">
									<nav className="navbar hidden-xs-down sidebar left-sidebar col-md-3">
										<ProductFilterSection />
									</nav>
									<div className="col-md-9 col-xs-12 catalogue-col right mb-50">
										<ProductListTitle searchState={this.state.searchState} />
										<ProductListSection />
										<PaginationSection />
									</div>
								</div>
							</div>
						</section>
						{this.props.user && this.props.user.store && <HowToDo />}
					</Layout>
				</ScrollTo>
			</InstantSearchRoute>
		)
	}
}

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}


export default connect(mapStateToProps)(Search)
