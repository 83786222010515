import React from 'react'
import { Layout } from '../containers'
import { Loading, PageTitle } from '../components'

import { getAll } from '../../core/clients/restClient'


const StoreItem = ({ store, index }) => (
	<React.Fragment>
		<div className="panel-heading">
			<a data-toggle="collapse" data-parent="#accordion" href={`#collapse${index}`} className="minus">Boutique {store.address.city}<span>&nbsp;</span></a>
		</div>
		<div id={`collapse${index}`} className="panel-collapse collapse">
			<div className="panel-body">
				<div className="contact-item">
					<h6>Addresse</h6>
					<address>
						E-loue Express<br />
						{store.address.address1}<br />
						{store.address.address2}{store.address.address2 && <br />}
	                	{store.address.zipcode} {store.address.city}
	                </address>
				</div>

				<div className="contact-item">
					<h6>Information</h6>
					<ul>
						<li>
							<i className="fa fa-envelope"></i><a href="mailto:contact@e-loue.com">contact@e-loue.com</a>
						</li>
						<li>
							<i className="fa fa-phone"></i> {store.phone}
						</li>
					</ul>
				</div>

				<div className="contact-item">
					<h6>Heures d'ouverture</h6>
					<div>
						{store.openDescription}
					</div>               
				</div>
			</div>
		</div>
	</React.Fragment>
)


class StoresInfo extends React.Component {
	componentDidMount = () => {
		//Accordeon
        const toggleChevron = e => {
            window.$(e.target)
                .prev('.panel-heading')
                .find("a")
                .toggleClass('plus minus');
        }

        window.$('#accordion').on('hide.bs.collapse', toggleChevron)
        window.$('#accordion').on('show.bs.collapse', toggleChevron)

        //window.$('#collapseOne').collapse('show')
	}

	render = () => (
		<div className="panel-group accordion mb-50" id="accordion">
			<div className="panel">
				{this.props.stores.map((store, index) => <StoreItem key={index} store={store} index={index} />)}
			</div>
		</div>
	)
}

class ContactUs extends React.Component {
	state =  {
		isLoading: true,
		stores: null
	}

	componentDidMount = () => {
		getAll('/stores').then(stores => {
			this.setState({isLoading: false, stores: stores.docs.splice(0,1)})
		})
	}

	render = () => {
		this.props.setTitle("Contactez-nous - E-loue Express")
		this.props.setDescription()
		return (
			this.state.isLoading ? <Layout><Loading /></Layout> :
			<Layout>
				<PageTitle title="Contactez-nous" />
				<section className="section-wrap contact pb-40">
					<div className="container">
						<div className="row">
							<div className="col-md-8 mb-40">
								<form id="contact-form" onSubmit={this.handleSubmit}>
									<div className="contact-name">
										<input name="name" id="name" type="text" placeholder="Nom*" />
									</div>

									<div className="contact-email">
										<input name="mail" id="mail" type="email" placeholder="E-mail*" />
									</div>

									<div className="contact-subject">
										<input name="subject" id="subject" type="text" placeholder="Sujet" />
									</div>                

									<textarea name="comment" id="comment" placeholder="Message" rows="9"></textarea>

									<input type="submit" className="btn btn-lg btn-dark btn-submit" value="Envoyer le message" id="submit-message" />
								</form>
							</div>

							<div className="col-md-3 col-md-offset-1 col-sm-5 mb-40">
								<StoresInfo stores={this.state.stores} />
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default ContactUs