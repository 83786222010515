import React from 'react'
import PropTypes from 'prop-types'

import Flickity from 'flickity-imagesloaded'
import 'flickity-as-nav-for'
import 'flickity-bg-lazyload'

class FlickityGallery extends React.Component {
	componentDidMount = () => {
		//Flickity Slider
		const arrowShape = this.props.pictures.length > 1 ? 'M 25,50 L 65,90 L 70,90 L 30,50  L 70,10 L 65,10 Z' : ''

		this.mainGallery = new Flickity(this.refs.galleryMain, {
      		cellAlign: 'center',
      		contain: true,
			wrapAround: true,
			autoPlay: false,
			prevNextButtons: true,
			percentPosition: true,
			imagesLoaded: true,
			lazyLoad: 1,
			pageDots: false,
			selectedAttraction : 0.1,
			friction: 0.6,
			rightToLeft: false,
			arrowShape: arrowShape,
    	})

		this.thumbsGallery = new Flickity(this.refs.galleryThumbs, {
			asNavFor: this.refs.galleryMain,
			contain: true,
			cellAlign: 'left',
			wrapAround: false,
			autoPlay: false,
			prevNextButtons: false,
			percentPosition: true,
			imagesLoaded: true,
			pageDots: false,
			selectedAttraction : 0.1,
			friction: 0.6,
			rightToLeft: false
		})

		//magnificPopup
		const $gallery = window.$('.mfp-hover');

		$gallery.on( 'dragStart.flickity', function( event, pointer ) {
			window.$(this).addClass('is-dragging');
		})

		$gallery.on( 'dragEnd.flickity', function( event, pointer ) {
			window.$(this).removeClass('is-dragging');
		})

		$gallery.magnificPopup({
			delegate: '.lightbox-img, .lightbox-video',
			callbacks: {
				elementParse: function(item) {
       				if(item.el.context.className === 'lightbox-video') {
            			item.type = 'iframe';
          			} else {
            			item.type = 'image';
          			}
        		}
      		},    
      		type: 'image',
      		closeBtnInside:false,
      		gallery:{
        		enabled:true
      		}
   		});
  	}

  	/**
   	 * Render Gallery Cell
   	 * @params {object} image
     * @params {int} index
    */
	renderGalleryCell = (image, index) => {
		return (
			<div key={index} className="gallery-cell">
				<a href={image.large_url} className="lightbox-img">
					<img src={image.large_url} alt="" />
					<i className="ui-zoom zoom-icon"></i>
				</a>
			</div>
    	)
	}

	/**
     * Render Gallery Thumb
     * @params {object} image
     * @params {int} index
     */
	renderGalleryThumb = (image, index) => {
		return (
			<div key={index} className="gallery-cell">
				<img src={image.little_url} alt="" />
			</div>
		)
	}

	render = () => {
	    return (
			<div>
				<div className="flickity flickity-slider-wrap mfp-hover" id="gallery-main" ref="galleryMain">
					{ this.props.pictures.map(this.renderGalleryCell) }
				</div>
				<div className="gallery-thumbs" ref="galleryThumbs">
					{ this.props.pictures.map(this.renderGalleryThumb) }
				</div>
			</div>
		)
	}
}

FlickityGallery.propTypes = {
	pictures: PropTypes.array.isRequired
}

export default FlickityGallery