import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { getInvoices } from '../../loxamapi'

import { get } from '../../core/clients/restClient'

import { Layout } from '../containers'
import { PageTitle, Loading } from '../components'


const Table = ({ children }) => (
	<table className="table">
		{ children }
	</table>
)

const InvoiceTableItem = ({ index, invoice }) => (
	<tr>
		<td style={{textAlign: "left"}}>{moment(invoice.createdAt).format("DD/MM/YYYY")}</td>
		<td style={{textAlign: "center"}}>{invoice.id}</td>
		<td style={{textAlign: "center"}}>{invoice.bdc}</td>
		<td style={{textAlign: "center"}}>{invoice.companyName}</td>
		<td style={{textAlign: "center"}}>{invoice.agencyName}</td>
		<td style={{textAlign: "center"}}>{invoice.totalTtc.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}>{invoice.restToPay.toFixed(2)} €</td>
		<td style={{textAlign: "center"}}><a href={`#${index}`}>Télécharger la facture</a></td>
	</tr>
)


const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/account">Votre compte</Link>
		</li>
		<li>
			<Link to="/account/subscription">Votre suivi conso</Link>
		</li>
		<li className="active">
			{props.title}
		</li>
	</ol>
)


class Invoice extends React.Component {
	state = {
		loading: true,
		invoices: null,
		error: true
	}

	componentDidMount = async () => {
		try {
			const invoiceHistory = await get(this.props.match.params.billingId, '/invoices')
			const invoices = await getInvoices(invoiceHistory.startDate, invoiceHistory.endDate)

			this.setState({
				loading: false,
				invoices: invoices,
				invoiceHistory: invoiceHistory
			})
		} catch (error) {
			this.setState({
				loading: false,
				error: true
			})
		}
		
	}

	render = () => (
		<Layout>
			{
				this.state.loading ? <Loading /> : this.state.error ? (
						<React.Fragment>
							<PageTitle title="Mes Factures" />
							<div className="content-wrapper oh">
								<div className="container mt-10">
									<Breadcrumb title="Mes factures" />
								</div>
								<section className="section-wrap checkout pb-70">
									<div className="container">
										<p>Impossible d'afficher vos factures</p>
									</div>
								</section>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<PageTitle title={`Mes Factures : ${this.state.invoiceHistory.description}`} />
							<div className="content-wrapper oh">
								<div className="container mt-10">
									<Breadcrumb title={`Mes Factures : ${this.state.invoiceHistory.description}`} />
								</div>
								<section className="section-wrap checkout pb-70">
									<div className="container">
										{
											this.state.loading ? <Loading  /> : (
												<Table>
													<thead>
														<tr>
															<th style={{fontWeight: "bold", textAlign: "left"}}>Date</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Ref facture</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Ref bon de commande</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Société</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Agence Loxam</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Montant total (TTC)</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Montant à payer (TTC)</th>
															<th style={{fontWeight: "bold", textAlign: "center"}}>Action</th>
														</tr>
													</thead>
													<tbody>
														{this.state.invoices.map((item, index) => <InvoiceTableItem key={index} invoice={item} />)}
													</tbody>
												</Table>
											)
										}
									</div>
								</section>
							</div>
						</React.Fragment>
				)
			}
		</Layout>
	)
}

export default Invoice