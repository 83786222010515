import React from 'react'
import { Field } from '../../core/fields'

const renderSelectField = ({ input, label, helpText, meta: { touched, error }, required, children, ...custom }) => {
	return (
		<div className={"form-group" + (touched && error ? " has-error" : "")}>
			{label && <label className="control-label">{label} {required && (<span className="required">*</span>)}</label>}
			<select 
				{...input}
				{...custom} style={{marginBottom: "0px"}} >
				{children}
			</select>
			{helpText && (<div className="help-block">{helpText}</div>)}
			<div className="help-block">{touched && error}</div>
		</div>
	)
}

export default props => <Field component={renderSelectField} {...props} />