import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError, reset } from 'redux-form'

import { Layout } from '../containers'
import { connectAuth } from '../../core/connectors'

import { connectCustomer } from '../../core/connectors'

import { Loading, PageTitle, Form } from '../components'
import { TextField } from '../fields'

const Table = ({ children }) => (
	<table className="table">
		{ children }
	</table>
)

const TableItem = ({ address }) => (
	<tr>
		<td style={{textAlign: "left"}}>
			{address.firstName} {address.lastName}<br />
			{address.address.address1}<br />
			{address.address.address2} {address.address.address2 && (<br />)}
			{address.address.zipcode} {address.address.city}<br />
		</td>
	</tr>
)

let MyInfoForm = (props) => (
	<Form {...props} saveButton={false}>
		<fieldset>
			<legend>Mes informations</legend>
			<TextField label="Entreprise" name="company" disabled required />
			<TextField label="Siret" name="companyId" disabled required />
			<TextField label="Forme juridique" name="companyLegalForm" disabled required />
			<TextField label="Prénom" name="firstName" disabled required />
			<TextField label="Nom" name="lastName" disabled required />
			<TextField label="Fonction" name="office" disabled required />
			<TextField label="Email" name="email" disabled required />
			<TextField label="Téléphone" name="phone" disabled required />
		</fieldset>
		{
			props.customer.addresses && (
				<fieldset>
					<legend>Adresses</legend>
					<Table>
						<tbody>
						{props.customer.addresses.map((address, index) => (
							<TableItem key={index} address={address} />
						))}
						</tbody>
					</Table>
				</fieldset>
			)
		}
		
	</Form>
)

MyInfoForm = reduxForm({
	form: 'myInfoForm',
	enableReinitialize: true
})(MyInfoForm)


let EditPasswordForm = props => (
	<Form {...props}>
		<fieldset>
			<legend>Mise à jour de votre mot de passe</legend>
			<TextField name="currentPassword" label="Mot de passe actuel" type="password" required />
			<TextField name="newPassword" label="Nouveau mot de passe" type="password" required />
			<TextField name="checkNewPassword" label="Confirmation du nouveau mot de passe" type="password" required />
		</fieldset>
	</Form>
)


EditPasswordForm = reduxForm({
	form: "editPasswordForm",
	enableReinitialize: true,
	onSubmitSuccess: (result, dispatch) => dispatch(reset('editPasswordForm'))
})(EditPasswordForm)




const Breadcrumb = props => (
	<ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
		<li>
			<Link to="/account">Votre compte</Link>
		</li>
		<li className="active">
			Vos informations
		</li>
	</ol>
)


class UserInfo extends React.Component {
	handleSubmit = values => {
		return this.props.updateCustomer(values)
	}

	handleEditPasswordSubmit = values => {
		const currentPassword = values.currentPassword
		const newPassword = values.newPassword
		const checkNewPassword = values.checkNewPassword

		if (newPassword !== checkNewPassword) {
			throw new SubmissionError({ _error: "Les mots de passe ne correspondent pas." })
		}

		return this.props.editPassword(currentPassword, newPassword).then(() => {

		}).catch(error => {
			throw new SubmissionError({_error: error.message})

			return null
		})
	}

	render = () => {
		this.props.setTitle("Vos informations - Chooses")
		this.props.setDescription()

		const store = this.props.store

		return (
			<Layout>
				<PageTitle title="Vos informations" />
				{
					this.props.isLoading ? <Loading /> : this.props.error ? (
						<div className="content-wrapper oh">
							<div className="container mt-10">
								<Breadcrumb />
							</div>
							<div className="container mt-30" style={{height: "300px"}}>
								<p>Impossible d'afficher d'accéder aux informations</p>
							</div>
						</div>
						) : (
						<div className="content-wrapper oh my-account">
							<div className="container mt-10">
								<Breadcrumb />
							</div>
							<div className="section-wrap checkout pb-70">
								<div className="container">
									<div className="row">
										<div className="col-sm-12">
											<MyInfoForm initialValues={this.props.customer} onSubmit={this.handleSubmit} customer={this.props.customer} />
											<p style={{fontWeight: "bold"}}>Merci de contacter votre agence de référence pour modifier vos informations :</p>
											<p style={{fontWeight: "bold"}}>
												{store.name}<br />
												{store.address.address1}<br />
												{store.address.zipcode} {store.address.city}<br />
												{store.phone}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
				<div className="content-wrapper oh">
					<div className="container">
						<div className="row">
							<div className="col-sm-12 mb-40">
								<EditPasswordForm onSubmit={this.handleEditPasswordSubmit} />
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default connectCustomer(UserInfo)