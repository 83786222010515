import React from 'react'
import { reduxForm } from 'redux-form'
import Cookies from 'js-cookie'

import { Modal, ModalBody, ModalHeader, ModalFooter, Form } from '../components'
import { CheckField } from '../fields'

let GdprForm = (props) => (
	<Form {...props} saveButton={false}>
		<ModalBody>
			<div className="media">
				<div className="media-left">
				    <CheckField name="functionalCookie" label="" disabled />
				</div>
				<div className="media-body">
					<h6 className="media-heading" style={{fontSize: "14px"}}>Cookies fonctionnels</h6>
					<p>Ces cookies sont nécessaires au fonctionnement du site, ils sont donc toujours activés.</p>
				</div>
			</div>
			<div className="media">
				<div className="media-left">
				    <CheckField name="gaCookie" label="" />
				</div>
				<div className="media-body">
					<h6 className="media-heading" style={{fontSize: "14px"}}>Cookies d'analyse du trafic et de performance</h6>
					<p>Ces cookies permettent à Loxam d'améliorer l'ergonomie du site et l'intérêt de ses fonctionnalités, en mesurant l'activité des utilisateurs de manière anonyme.</p>
				</div>
			</div>
		</ModalBody>
		<ModalFooter>
			<p>Vous pouvez à tout moment modifier vos préférences en vous rendant dans la section « Paramètres de cookies » en bas de page.</p>
			<button type="submit" className="btn btn-primary">Enregistrer</button>
		</ModalFooter>
	</Form>
)

GdprForm = reduxForm({
	form: 'gdprForm',
	enableReinitialize: true
})(GdprForm)

class GdprModal extends React.Component {
	render = () => (
		<Modal show={this.props.show}>
			<ModalHeader title="Paramétrer vos cookies" toggle={this.props.toggle} showCloseButton={false} />
			<GdprForm initialValues={this.props.initialValues} onSubmit={this.props.handleSubmit} />
		</Modal>
	)
}

class GdprBanner extends React.Component {
	state = {
		show: false,
		showModal: false
	}

	initialValues = {
		functionalCookie: true,
		gaCookie: true,
	}

	componentDidMount = () => {
		if (Cookies.get('choosebyloxamrgdpr') === undefined) {
			this.setState({show: true})
		}
	}

	closeBannner = () => {
		this.setState({show: false})
	}

	toggle = () => {
		this.setState({showModal: !this.state.showModal})
	}

	setCookies = values => {
		if (values) {
			Cookies.set('choosebyloxamrgdpr', values, {expires: 395})
		} else {
			Cookies.set('choosebyloxamrgdpr', this.initialValues, {expires: 395})
		}
	}

	handleSubmit = values => {
		this.setCookies(values)
		this.setState({showModal: false}, () => {
			this.setState({show: false })
		})
	}

	render = () => (
		this.state.show ? (
			<div>
				<div className="alert fade in alert-dismissible" role="alert" style={{marginBottom: "0px"}}>
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.toggle}><span aria-hidden="true" style={{color: "#000"}}>×</span></button>
					<p data-toggle="modal" data-target="#offerModal" style={{textAlign: "center", marginBottom: "20px"}}>
						ChoosebyLoxam utilise des cookies pour le fonctionnement du site, l'analyse de son utilisation et à des fin marketing. Pour en savoir plus, consultez notre <a style={{color: "#000"}} href="https://medias.loxam.com/media/documents/h97/Politique_de_Protection_des_Donnees_Personnelles_de_LOXAM_VF.pdf" target="_blank">politique de gestion des données personnelles</a>.
		            </p>
		            <p style={{textAlign: "center"}}>
		            	<a href="#cookieModal" onClick={e => { e.preventDefault(); this.toggle() }} style={{color: "#8c8c8c"}} >Paramétrer les cookies</a> <button type="button" className="btn" style={{marginLeft: "20px"}} onClick={(e) => {this.handleSubmit(this.initialValues)}}>Accepter les cookies</button>
					</p>
				</div>
				<GdprModal toggle={this.toggle} show={this.state.showModal} handleSubmit={this.handleSubmit} initialValues={this.initialValues} />
			</div>
		) : null
		
	)
}

export default GdprBanner