import React from 'react'
import { Field } from '../../core/fields'

const setValue = (e, input, disabled) => {
	e.preventDefault()
	input.onChange(disabled ? input.value : !input.value)
}

const renderCheckField = ({ disabled, input, label, helpText, meta: { touched, error }, required, ...custom }) => {
	return (
		<div className={"form-group" + (touched && error ? " has-error" : "")}>
			<div onClick={(e) => {setValue(e, input, disabled)}}>
				<input 
					type="checkbox"
					className="input-checkbox"
					{...input}
	  				{...custom} 
	  				checked={input.value}
	  			/>&nbsp;
	  				
				<label>
					{label}
		  		</label>
	  		</div>
	  		<div className="help-text">{touched && error}</div>
	  	</div>
	)
}

export default props => <Field component={renderCheckField} {...props} />