import React from 'react'
import { Link } from 'react-router-dom'

import { connectAuth } from '../../core/connectors'
import { Cart } from './'


class NavMenuLinks extends React.Component {
	// handleLogout = (e) => {
	// 	e.preventDefault()
	// 	this.props.logout()
	// }

	render = () => {
		return (
			<ul>
				{
					this.props.user && this.props.user.hasPerm() ? (
						<React.Fragment>
							<li className="nav-register">
								<Link to="/account">Votre compte</Link>
							</li>
						</React.Fragment>
					) : (
						<React.Fragment>
							<li className="nav-register">
								<Link to="/login">Se connecter</Link>
							</li>
					 	</React.Fragment>
					)
				}
		 	 	{this.props.user && this.props.user.isAnonymous === false && <Cart />}
			</ul>
		)
	}
}

export default connectAuth(NavMenuLinks)