import {
    SHOW_NOTIFICATION,
} from '../actions'

const defaultState = {
    text: '',
    type: 'info', // one of 'info', 'error', 'warning', 'success'
}

export default (previousState = defaultState, { type, payload }) => {
    switch (type) {
        case SHOW_NOTIFICATION:
            return Object.assign({}, previousState, {
				text: payload.text, type: payload.type, time: new Date().getTime()
			})
        default:
            return previousState
    }
}
