import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormSection, change } from 'redux-form'
import moment from 'moment'

import { DateTimeField } from './'

const OPEN_HOUR = 9
const CLOSE_HOUR = 19

const closeDays = [
	moment("2019-05-28").hours(12).minutes(0).valueOf(),
	moment("2019-05-29").hours(12).minutes(0).valueOf(),
	moment("2019-05-30").hours(12).minutes(0).valueOf(),
	moment("2019-05-31").hours(12).minutes(0).valueOf(),
	moment("2019-06-01").hours(12).minutes(0).valueOf(),
	moment("2019-06-02").hours(12).minutes(0).valueOf(),
	moment("2019-06-03").hours(12).minutes(0).valueOf()
]

/*
 * Return the date with the correct day and hours
 */
const getDateTime = (date) => {
	if (isToday(date)) {
		const today = moment()
		let hour = today.get('hour')
		let minute = today.get('minute')

		if (minute >= 30) {
			hour += 2
			minute = 0
		} else if (minute < 30) {
			hour += 1
			minute = "30"
		}

		if (hour <= CLOSE_HOUR) {
			date.hour(hour).minute(minute)
		} else {
			date.add(1, 'days').hour(OPEN_HOUR).minute(0)
		}

	} else {
		date.hours(OPEN_HOUR).minutes(0)
	}

	return date
}

/*
 * Return true if the date is today
 */
const isToday = (date) => {
	const today = moment().startOf('day')
	return date.isSame(today, 'd') && date.isSame(today, 'month') && date.isSame(today, 'year')
}


class DateTimeRangeField extends React.Component {
	startDate = null
	endDate = null

	nextAvaillableDate = date => {
		while (this.isOutsideRange(date)) {
			date = date.add(1, 'days').hours(OPEN_HOUR)
		}

		return date
	}

	prevAvaillableDate = date => {
		while (this.isOutsideRange(date)) {
			date = date.subtract(1, 'days').hours(CLOSE_HOUR)
		}

		return date
	}

	startDateOnChange = (event, newValue, prevValue) => {
		//Set the new startDate
		this.startDate = newValue
		
		//Change the value of endDate if endDate is null or startDate is after endDate or startDate === endDate
		if (this.endDate === null || (this.endDate !== null && this.startDate > this.endDate) || this.startDate === this.endDate) {
			const startDate = moment(this.startDate)

			//Handle when startDate === endDate
			if (this.startDate === this.endDate) {
				//Add one hour more
				let endDate = startDate.clone().add(1, 'hours')

				//Check if the hours is after close hour
				if (endDate.hours() > CLOSE_HOUR) {

					endDate.add(1, 'days').hours(OPEN_HOUR)
					endDate = this.nextAvaillableDate(endDate)
				}
				this.endDate = endDate.valueOf()
			} else {
				let endDate = startDate.clone().add(1, 'days')
				endDate = this.nextAvaillableDate(endDate)

				this.endDate = endDate.valueOf()
			}
			
			
			this.props.change({startDate: this.stateDate, endDate: this.endDate})
		}
	}

	endDateOnChange = (event, newValue, prevValue) => {
		//Set the new endDate
		this.endDate = newValue

		//Change the value of startDate if startDate is null or endDate is before startDate or endDate === startDate
		if (this.startDate === null || (this.startDate !== null && this.startDate > this.endDate) || this.startDate === this.endDate) {
			const endDate = moment(this.endDate)

			let startDate

			//Handle when startDate === endDate
			if (this.startDate === this.endDate) {
				startDate = endDate.clone().subtract(1, 'hours')

				//Check if the hours is before the open hour
				if (startDate.hours() < OPEN_HOUR) {
					startDate.subtract(1, 'days').hours(CLOSE_HOUR)
					startDate = this.prevAvaillableDate(startDate)
					this.startDate = startDate.valueOf()
				}

				//Check if the hours is not in the past
				if (startDate.valueOf() > moment().valueOf()) {
					this.startDate = startDate.valueOf()
				}
			} else {
				startDate = endDate.clone().subtract(1, 'days')
				startDate = this.prevAvaillableDate(startDate)

				//Check if the hours is not in the past
				if (startDate.valueOf() < moment().valueOf()) {
					startDate = getDateTime(moment())
				}

				if (startDate > this.endDate) {
					this.startDate = this.endDate.valueOf()
				} else {
					this.startDate = startDate.valueOf()
				}
			}
			

			this.props.change({startDate: this.startDate, endDate: this.endDate})
		}
	}

	isOutsideRange = date => {
		let isInclude = true

		const openHours = this.props.openHours || []

		if (openHours && openHours.length > 0) {
			const day = date.day()
			let openDays = []


			//Test each open day and check if the current day is include
			openHours.forEach(openHour => {
				openDays = openDays.concat(openHour.openDays)
			})

			isInclude = openDays.indexOf(day) === -1 ? false : true

		}
		

		const isCloseDay = closeDays.filter(closeDay => closeDay === date.valueOf()).length > 0


		const out = date.diff(moment().hours(12).minutes(0), 'days') < 0 || !isInclude || isCloseDay

		return out 
	}

	render = () => {
		return (
			<FormSection name={this.props.name}>
				<div className="form-group row">
					<div className="col-xs-12">
						<label>Période</label>
					</div>
					<div className="col-xs-12">
						<DateTimeField name="startDate" placeholder="Date de début" onChange={this.startDateOnChange} isOutsideRange={this.isOutsideRange} openHours={this.props.openHours} />
						<DateTimeField name="endDate" placeholder="Date de fin" onChange={this.endDateOnChange} isOutsideRange={this.isOutsideRange} openHours={this.props.openHours} />	
					</div>
				</div>
			</FormSection>
		)
	}
}

DateTimeRangeField.propTypes = {
	form: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	change: (value) => {
		dispatch(change(ownProps.form, ownProps.name, value))
	}
})

export default connect(null, mapDispatchToProps)(DateTimeRangeField)