import React from 'react'
import { connect } from 'react-redux'


import axaLogo from '../../assets/img/axa_logo_blue.svg'

const getStoreAddress = address => `${address.address1}, ${address.zipcode} ${address.city}`


// const HowToDo = props => (
// 	<section className="el-panel how-to-blocs">
// 		<div className="container">
// 			<div className="row">
// 				<p className="col-md-3">
// 					<i className="ion-android-checkmark-circle"></i> Retrait dans votre magasin {props.user && props.user.store && <span className="line-return">{props.user.store.name}</span>}
// 				</p>
// 				<p className="col-md-3">
// 					<i className="ion-android-checkmark-circle"></i> Livraison et reprise dans la {props.user && props.user.store && <span className="line-return">{props.user.store.shippingArea}</span>}
// 				</p>
// 				<p className="col-md-3">
// 					<i className="ion-android-checkmark-circle"></i> Réservation en ligne <span className="line-return">100% sécurisée</span>
// 				</p>
// 				<p className="col-md-3">
// 					<i className="ion-android-checkmark-circle"></i> Enchange à tout moment <span className="line-return">du matériel</span>
// 				</p>
// 			</div>
// 		</div>
// 	</section>
// )

const HowToDo2 = props => (
	<section className="el-panel how-to-blocs">
		<div className="container">
			<div className="row">
				<div className="col-md-3">
					<div className="media">
						<div className="media-left">
							<i className="ion-android-checkmark-circle"></i>
						</div>
						<div className="media-body">
							Retrait dans votre magasin {props.user && props.user.store && props.user.store.name}
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="media">
						<div className="media-left">
							<i className="ion-android-checkmark-circle"></i>
						</div>
						<div className="media-body">
							Livraison et reprise dans la {props.user && props.user.store && props.user.store.shippingArea}
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="media">
						<div className="media-left">
							<i className="ion-android-checkmark-circle"></i>
						</div>
						<div className="media-body">
							Réservation en ligne 100% sécurisée
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="media">
						<div className="media-left">
							<i className="ion-android-checkmark-circle"></i>
						</div>
						<div className="media-body">
							Echange à tout moment du matériel
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}


export default connect(mapStateToProps)(HowToDo2)