import React from 'react'
import { Link } from 'react-router-dom'

import { Layout } from '../containers'
import { PageTitle } from '../components'

import { connectOrder } from '../../order'


const NoOrders = () => (
	<div className="row">
		<div className="col-xs-12" style={{textAlign: "center"}}>
			<p>Pas encore de commande.</p>
			<Link  to="/recherche" className="btn btn-lg btn-color"><span>Accéder aux annonces de location</span></Link>
		</div>
	</div>
)

const Orders = ({setTitle, setDescription}) => {
	setTitle("Détail de la commande - Choose by Loxam")
	setDescription()
	
	return (
		<Layout>
			<PageTitle title="Mes commandes" />
			<div className="content-wrapper oh">
				<section className="section-wrap shopping-cart">
					<div className="container relative">
						<NoOrders />
					</div>
				</section>
			</div>
		</Layout>
	)
}

export default connectOrder(Orders)