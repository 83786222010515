import React from 'react'
import { Link } from 'react-router-dom'

import { Layout } from '../containers'
import { PageTitle } from '../components'
import { connectAuth } from '../../core/connectors'


import subscriptionIcon from '../assets/img/subscirption.svg'
import orderlistIcon from '../assets/img/orderlist.svg'
import userinfoIcon from '../assets/img/userinfo.svg'


const MenuAccountItem = ({ name, to, img="img/grey.png" }) => (
	<div className="col-xs-4 col-xxs-12 mb-30 promo-banner style-2">
		<Link to={to}>
			<img src={img} alt="" />
			<div className="promo-inner">
				<h2>{name}</h2>
			</div>
		</Link> 
	</div>
)


const MenuAccount = ({ children }) => (
		<div className="container">
			<div className="row">
				{children}
			</div>
		</div>
)


class YourAccount extends React.Component {
	handleLogout = (e) => {
		e.preventDefault()
		this.props.logout()
	}

	render = () => {
		this.props.setTitle("Mon compte - Choose")
		this.props.setDescription()
		return (
			<Layout>
				<PageTitle title="Votre compte" />
				<div className="content-wrapper oh" style={{margin: "20px 0 40px 0"}}>
					<section className="section-wrap promo-banners pb-50">
						<MenuAccount>
							<MenuAccountItem to="/account/orders" name="Mes commandes en ligne" img={subscriptionIcon} />
							<MenuAccountItem to="/account/subscription" name="Mon suivi conso" img={orderlistIcon} />
							<MenuAccountItem to="/account/user-info" name="Mes informations" img={userinfoIcon} />
						</MenuAccount>
						<div className="container relative">
							<p className="text-right">
								<a href="#logout" onClick={this.handleLogout}>Se déconnecter</a>
							</p>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

export default connectAuth(YourAccount)