import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, isSubmitting } from 'redux-form'
import { Link, Redirect } from 'react-router-dom'

import { connectAuth } from '../../core/connectors'
import { create, update } from '../../core/clients/restClient'
import { Form } from '../components'
import { TextField, SelectField, CheckField } from '../fields'
import { Layout } from '../containers'

import axaLogo from '../assets/img/axa_logo_blue.svg'

const user = {
	country: "FR",
	receivePromotional: true,
}

let SignupForm = props => (
	<Form {...props}>
		<fieldset>
			<TextField label="Email" name="email" type="email" required />
			<TextField label="Mot de passe" name="password" type="password" required />
		</fieldset>
		<fieldset>
			<legend>Coordonnées</legend>
			<div className="row">
				<div className="col-sm-6">
					<TextField label="Prénom" name="firstName" required />
				</div>
				<div className="col-sm-6">
					<TextField label="Nom" name="lastName" required />
				</div>
			</div>
			<TextField label="Entreprise" name="company" />
			<TextField label="Adresse" name="address1" required />
			<TextField label="Complément d'adresse" name="address2" />
			<div className="row">
				<div className="col-sm-6">
					<TextField label="Code Postal" name="zipcode" required />
				</div>
				<div className="col-sm-6">
					<TextField label="Ville" name="city" required />
				</div>
			</div>
			<SelectField label="Pays" name="country" required>
				<option value="FR">France</option>
			</SelectField>
			<TextField name="phone" label="Téléphone" required />
		</fieldset>
		<fieldset style={{marginBottom: "1.5rem"}}>
			<legend>Communication</legend>
			<CheckField name="receivePromotional" label="Je souhaite recevoir les actualités et offres promotionnelles de la part de E-loue Express et ses partenaires (envoi peu fréquent)" />
		</fieldset>
	</Form>
)

SignupForm = reduxForm({
	form: 'signupForm'
})(SignupForm)


class SignUp extends React.Component {
	handleSubmit = values => {
		const userData = {
			firstName: values.firstName, 
			lastName:values.lastName, 
			email: values.email
		}

		return this.props.signUpWithEmailPassword(values.email, values.password, userData).then(user => {
			
			const customerData = {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				phone: values.phone,
				receivePromotional: values.receivePromotional,
				company: values.company ? values.company : null,
				userId: user.uid,
				createdAt: user.createdAt
			}

			const addressData = {
				address: {
					address1: values.address1,
					address2: values.address2 ? values.address2 : null,
					zipcode: values.zipcode,
					city: values.city,
					country: values.country
				}, 
				defaultAddress: true, 
				firstName: values.firstName, 
				lastName: values.lastName,
				company: values.company ? values.company : null
			}

			return create('/customers', customerData).then(customer => {
				const addAddressToCustomer = create(`/customers/${customer.id}/addresses`, addressData)
				const addCustomerIdToUser = update(customer.userId, '/users', {customerId: customer.id})

				return Promise.all([addAddressToCustomer, addCustomerIdToUser])
			})
		})
	}

	render = () => {
		this.props.setTitle("Inscription - Choose by Loxam")
		this.props.setDescription()
		const from = (this.props.location.state && this.props.location.state.from) || { pathname: '/' }

		return (
			this.props.user && this.props.user.hasPerm() && !this.props.isSubmitting ? (
				<Redirect to={from} />
			) : (
				<Layout navLeftComponent={<p className="hidden-xs">Le premier site pour tout louer, réservation instantanée,<br/>
	                        livraison express, vente de consommables</p>} footer={false}>
					<div className="container mt-40 mb-40">
						<div className="row">
							<div className="col-xs-12">
								<h4>Créer un compte</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-8 col-xs-12 mb-20">
								<div style={{padding: "10px", border: "1px solid #e5e5e5"}}>
									<SignupForm onSubmit={this.handleSubmit} buttonName="Inscription" initialValues={user} />
									<p style={{marginTop: "20px"}}>En cliquant sur Inscription, j'accepte <Link to="/cgv" target="_blank">les conditions générales</Link>, de e-loue Express.</p>
								</div>
							</div>
							<div className="col-sm-4 col-xs-12">
								<div style={{padding: "10px", border: "1px solid #e5e5e5"}}>
									<div className="contact-item">
                					<ul>
                						<li>
                    						Réservation en ligne
                  						</li>
                 				 		<li>
                    						Vos locations sont assurées par <img width="53" height="53" src={axaLogo} alt="Réservations assurées par AXA" style={{width: "53px", marginLeft: "5px"}}/>
                  						</li>
                  						<li>
                    						Paiement 100% sécurisé
                  						</li>
                					</ul>
                					</div>
								</div>
								<p className="mt-20">Déjà membre ? <Link to={{pathname: "/login", state: {...this.props.location.state}}}>Identifiez-vous</Link></p>
							</div>
						</div>
					</div>
				</Layout>
			)
		)
	}
}

const mapStateToProps = state => ({
	isSubmitting: isSubmitting('signupForm')(state)
})

export default connectAuth(connect(mapStateToProps)(SignUp))