import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { reduxForm, isSubmitting, hasSubmitSucceeded, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'



import { connectAuth } from '../../core/connectors'
import { TextField } from '../fields'
import { Form, GdprBanner } from '../components'
import { Layout } from '../containers'

import logoWhite from '../assets/img/logo_choose_white.svg'

const headerStyle = {
    position: "relative",
    padding: "2rem 1.25rem 0.5rem",
    width: "100%",
    backgroundColor: "#00a483",
    color: "#fff",
    textAlign: "center",
    backgroundSize: "cover"
}


let ForgotPasswordForm = props => (
	<Form {...props}>
		<fieldset>
			<TextField label="Email" type="email" name="email" required />
		</fieldset>
	</Form>
)

ForgotPasswordForm = reduxForm({
	form: 'forgotPasswordForm'
})(ForgotPasswordForm)


class ForgotPassword extends React.Component {
	state = {
		submitSucceeded: false
	}

	handleSubmit = values => {
		return this.props.sendPasswordResetEmail(values.email).catch(error => {
			throw new SubmissionError({_error: error.message})
		})
	}

	componentDidUpdate = prevProps => {
		if (prevProps.submitSucceeded === false && this.props.submitSucceeded === true) {
			this.setState({
				submitSucceeded: true
			})
		}
	}

	render = () => {
		this.props.setTitle("Mot de passe oublié - Choose by Loxam")
		this.props.setDescription()

		const from = (this.props.location.state && this.props.location.state.from) || { pathname: '/' }
		
		return (
			this.props.user && this.props.user.hasPerm() && !this.props.isSubmitting ? (
				<Redirect to={from} />
			) : (
				<div>
					<GdprBanner />
					<header id="auth-header" style={headerStyle}>
						<h1 style={{color: "#fff"}}>
							<img src={logoWhite} alt="logo" style={{width: "300px"}}/>
						</h1>
					</header>
					<div className="container mt-40 mb-40">
						<div className="row">
							<div className="col-sm-offset-3 col-sm-6 col-xs-12 mb-20">
								<h4>Mot de passe oublié ?</h4>
								<div className="login-form" style={{padding: "10px", border: "1px solid #e5e5e5", width: "100%"}}>
									{	
										this.state.submitSucceeded ?
										(
											<div className="alert alert-success" style={{marginBottom: "0px"}}>
												<p>Merci ! Nous avons bien pris en compte votre demande de réinitialisation de mot de passe.</p>
												<p>Des instructions vous ont été envoyés par mail.</p>
											</div>
										) : (
											<ForgotPasswordForm onSubmit={this.handleSubmit} buttonName="M'envoyer des instructions" />
										)
									}
								</div>
								{
									!this.state.submitSucceeded	&& (
										<div className="mt-20 mb-20" style={{textAlign: "center"}}>
											<Link to="/login" style={{color: "#7f7f7f"}}>Se connecter ?</Link>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			)
		)
	}
}

const mapStateToProps = state => ({
	isSubmitting: isSubmitting('forgotPasswordForm')(state),
	submitSucceeded: hasSubmitSucceeded('forgotPasswordForm')(state)
})

export default connectAuth(connect(mapStateToProps)(ForgotPassword))
