import React from 'react'
import { connect } from 'react-redux'
import { editPassword } from '../clients/authClient'
import { getCustomer, updateCustomer } from '../../order'


const connectCustomer = CustomerComponent => {
	class ConnectCustomer extends React.Component {
		state = {
			isLoading: true,
			error: null,
			customer: null
		}


		editPassword = (currentPassword, newPassword) => {
			return editPassword(currentPassword, newPassword)
		}

		componentDidMount = async () => {
			try {
				const customer = await getCustomer(this.props.customerId)

				this.setState({
					isLoading: false,
					customer: customer
				})
			} catch (error) {
				this.setState({
					isLoading: false,
					error: error
				})
			}
			
		}

		render = () => <CustomerComponent
							isLoading={this.state.isLoading}
							customer={this.state.customer}
							error={this.state.error}
							store={this.props.store}
							editPassword={this.editPassword}
							{...this.props} />
	}


	const mapStateToProps = state => {
		return {
			customerId: state.auth.user.customerId,
			store: state.auth.user.store		}
	}

	return  connect(mapStateToProps)(ConnectCustomer)
}

export default connectCustomer

