import React from 'react'
import { Field } from '../../core/fields'


const renderRadioField  = props => {
		//console.log(props)
		const { input, label, helpText, meta: { touched, error }, isChecked, data } = props
		return (
			<React.Fragment>
				<input type="radio" {...input} value={data} checked={isChecked ? isChecked(props.input.value, data) : props.input.value === data} />
				<label onClick={() => input.onChange(data)}>{label}</label>
				{helpText && (<div className="help-block">{helpText}</div>)}
				<div className="help-block">{touched && error}</div>
			</React.Fragment>
		)	
}

export default props => {
	return <Field component={renderRadioField} {...props} data={props.value} />
}