import React from 'react'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router'
import recombee from 'recombee-js-api-client'

import settings from '../../settings'
import { Form } from '../components'
import { SelectField } from '../fields'
import { DateTimeRangeField } from '../../core/fields'

import { connectAddRentItemToCart } from '../../order'

let AddToCartForm = props => {
	return (
		<Form {...props} saveButton={false}>
			<DateTimeRangeField name="period" form={props.form} openHours={props.user && props.user.store ? props.user.store.openHours : null} />
			<SelectField label="Quantité" name="quantity">
				<option value={1}>1</option>
				<option value={2}>2</option>
				<option value={3}>3</option>
				<option value={4}>4</option>
				<option value={5}>5</option>
				<option value={6}>6</option>
				<option value={7}>7</option>
				<option value={8}>8</option>
				<option value={9}>9</option>
				<option value={10}>10</option>
			</SelectField>
			<div className="row" style={{marginTop: "2em"}}>
				<div className="col-xs-12 price" style={{textAlign: "left"}}><ins>Total : {props.totalAmount != null  ? props.totalAmount === 0 ? "Inclus dans le forfait" : `${props.totalAmount.toFixed(2)}€` : props.inProgress ? "Calcul en cours..." : "--"} </ins></div>
			</div>
			<div className="row product-add-to-cart">
				<div className="col-xs-12">
					<button type="submit" className="btn btn-dark btn-lg add-to-cart" style={{width: "100%"}} disabled={props.pristine || props.submitting}><span>{props.submitting ? "Enregistrement en cours" : "Ajouter au panier"}</span></button>
				</div>
			</div>
		</Form>
	)
}


AddToCartForm = reduxForm({
	form: 'addToCartForm'
})(AddToCartForm)

class AddToCart extends React.Component {

	handleSubmit = values => {
		let item = Object.assign({}, values)
		item.startDate = values.period.startDate
		item.endDate = values.period.endDate
		item.type = 'booking'
		item.quantity = parseInt(values.quantity, 10)
		item.currency = 'EUR'
		item.tax = 0.2

		delete item.period

		console.log(item)

		return this.props.addItem(item).then(item => {
			if (settings.recombee) {
				const recombeeClient = new recombee.ApiClient(settings.recombee.id, settings.recombee.token)
				recombeeClient.send(new recombee.AddCartAddition(this.props.user.id, item.objectId))	
			}
						
			this.props.history.push('/panier')
		})
	}

	render = () => (
		<div className="product-actions">
			<AddToCartForm onSubmit={this.handleSubmit} initialValues={this.props.initialValues} totalAmount={this.props.totalAmount} inProgress={this.props.inProgress} user={this.props.user} />
		</div>
	)
}

export default connectAddRentItemToCart(withRouter(AddToCart))