import React from 'react'

export default ({ input, label, helpText, meta: { touched, error }, required, ...custom }) => {
	return (
		<div className={"form-group" + (touched && error ? " has-error" : "")}>
			{label && <label className="control-label">{label} {required && (<span className="required">*</span>)}</label>}
			<input 
				className="form-control"
				type="text"
				{...input}
				{...custom} />
			<span className="help-block">{touched && error}</span>
		</div>
	)
}