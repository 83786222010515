import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, isSubmitting } from 'redux-form'

import { connectAuth } from '../../core/connectors'
import { update } from '../../core/clients/restClient'
import { TextField } from '../fields'
import { Form, GdprBanner } from '../components'
import { Layout } from '../containers'


import logoWhite from '../assets/img/logo_choose_white.svg'


const headerStyle = {
    position: "relative",
    padding: "2rem 1.25rem 0.5rem",
    width: "100%",
    backgroundColor: "#00a483",
    color: "#fff",
    textAlign: "center",
    backgroundSize: "cover"
}


let LoginForm = props => (
	<Form {...props}>
		<fieldset>
			<TextField label="Email" type="email" name="email" required />
			<TextField label="Mot de passe" type="password" name="password" required />
		</fieldset>
	</Form>
)


LoginForm = reduxForm({
	form: 'loginForm'
})(LoginForm)

class Login extends React.Component {
	handleSubmit = values => {
		let cartId = null

		if (this.props.user && this.props.user.cartId) {
			cartId = this.props.user.cartId
		}
 
		return this.props.login(values.email, values.password).then(user => {
			//Migrate the cart to the logged user
			if (cartId) {
				const userUpdatePromise = update(user.uid, '/users', { cartId })
				const cartUpdatePromise = update(cartId, '/carts', {userId: user.uid})

				return Promise.all([userUpdatePromise, cartUpdatePromise])
			}
		})
	}

	render = () => {
		this.props.setTitle("Connexion - Choose by Loxam")
		this.props.setDescription()
		const from = (this.props.location.state && this.props.location.state.from) || { pathname: '/' }
		return (
			this.props.user && this.props.user.hasPerm() && !this.props.isSubmitting ? (
				<Redirect to={from} />
			) : (
				<div>
					<GdprBanner />
					<header id="auth-header" style={headerStyle}>
						<h1 style={{color: "#fff"}}>
							<img src={logoWhite} alt="logo" style={{width: "300px"}}/>
						</h1>
					</header>
					<div className="container mt-40 mb-40">
						<div className="row">
							<div className="col-xs-12">
								<h4>Identifiez-vous</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6 col-xs-12 mb-20">
								<div className="login-form" style={{padding: "10px", border: "1px solid #e5e5e5", width: "100%"}}>
									<LoginForm onSubmit={this.handleSubmit} buttonName="Connexion" />
								</div>
								<p className="mt-20 pb-20 text-center"><Link to="/forgot-password" style={{color: "#424242"}}>Mot de passe oublié ?</Link></p>
							</div>
							<div className="col-sm-6 col-xs-12">
								<div style={{padding: "10px"}}>
									<div className="contact-item">
                						<p style={{
                							fontSize: "28px",
                							color: "#00a483",
                							textAlign: "center",
                							lineHeight: 1.2
                						}}>Le <span style={{color: "#e40115"}}>forfait location</span> malin pour les <span style={{color: "#e40115"}}>artisans</span></p>
                					</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		)
	}
}

const mapStateToProps = state => ({
	isSubmitting: isSubmitting('loginForm')(state)
})

export default connectAuth(connect(mapStateToProps)(Login))