import firebase from 'firebase/app'
import 'firebase/database'

const normalizeString = str => str.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")

/*
Params format
queryParam
{
	pagination: {page: Int, perPage: Int}, 
	filters [{field: String, operator: String, value: String}], 
	orderBy: {sort: 'asc or desc', field: String}
}

path String
subCollection Array

Response format
{
	docs: [Object] list of docs
	count: [Int]
}
//TODO handle sort
*/

export const getAll = (path, queryParam = {}) => {
	const { pagination, filters } = queryParam

	let ref = firebase.database().ref(path)

	return ref.once('value').then(snap => {

		let docs = []

		snap.forEach(childSnap => {
			let item = childSnap.val()
			item.id = childSnap.key
			docs.push(item)
		})

		let count = snap.numChildren()

		//Filter the list
		if (filters) {
			docs = docs.filter(doc => {
				return filters.every(filter => {
					//TODO: Modify the operator by >=
					if (filter.operator === "==") {
						return doc[filter.field] ? normalizeString(doc[filter.field]).indexOf(normalizeString(filter.value)) >= 0 : false
					} else {
						return doc[filter.field] ? normalizeString(doc[filter.field]) === normalizeString(filter.value) : false
					}
				})
			})

			count = docs.length
		}

		//Paginate the docs
		if (pagination) {
			const {page, perPage} = pagination
			const start = (page - 1) * perPage
			const end = page * perPage
			docs = docs.slice(start, end)
		}

		return {docs: docs, count: count}
	})
}

/*
Params format
id [Int] id of the object
path [String] Path of the database
subCollections [Array] List of sub collections

Response format
doc: [Object] Data of the doc
*/
export const get = (key, path, subCollections = []) => {
	const refPath = `${path}/${key}`
	const ref = firebase.database().ref(refPath)
	
	return ref.once('value').then(snap => {
		let item = snap.val()
		if (item) {
			if (!item.id) {
				item.id = snap.key
			} else {
				item.pk = snap.key
			}

			if (subCollections.length > 0) {
				subCollections.forEach( collection => {
					if (item[collection]) {
						item[collection] = Object.keys(item[collection]).map(key => {
							return Object.assign({id: key}, item[collection][key])
						})
					}
				})
			}

			return item
		} else {
			return  {}
		}
	})
}


/*
Params format
path [String] Path of the database
subCollections [Array] List of sub collections
callback [Function] to listen the update the item

Response format
doc: [Object] Data of the doc
*/
export const on = (path, callback, subCollections= []) => {
	const refPath = `${path}`
	
	const ref = firebase.database().ref(refPath)

	ref.on('value', snap => {
		let item = snap.val()

		if (item) {
			if (typeof item === 'object') {
				if (!item.id) {
					item.id = snap.key
				} else {
					item.pk = snap.key
				}

				if (subCollections.length > 0) {
					subCollections.forEach( collection => {
						if (item[collection]) {
							item[collection] = Object.keys(item[collection]).map(key => {
								return Object.assign({id: key}, item[collection][key])
							})
						}
					})
				}
			}
			callback(item)
		} else {
			callback(null)
		}
	})

	return ref
}


/*
Params format
path [String] Path of the database
doc [Object] Data of the object

Response format
doc: [Object] Data of the doc


TODO: Handle subcollection creation
*/
export const create = (path, doc, subCollections = []) => {
	let data = Object.assign({}, doc)

	//Create a reference and then set data
	const ref = firebase.database().ref(path).push()

	return update(ref.key, path, data, subCollections)
}


/*
Params format
id [Int] id of the object
path [String] Path of the database

Response format
Promise
*/
export const remove = (id, path) => {
	const refPath = `${path}/${id}`
	const ref = firebase.database().ref(refPath)
	
	return ref.remove()
}


/*
Params format
id [String] id of the object
path [String] Path of the database
doc [Object] Data of the object
subCollection [Array] 

Response format
doc: [Object] Data of the object
*/
export const update = (key, path, doc, subCollections = []) => {
	const refPath = `${path}/${key}`
	const ref = firebase.database().ref(refPath)
	let data = Object.assign({}, doc)
	
	if (subCollections.length > 0) {
		subCollections.forEach(collection => {
			if (data[collection]) {
				const items = data[collection].reduce((acc, item, index) => {
					const newItem = Object.assign({}, item)
					let id
					if (newItem.id) {
						id = newItem.id
						delete newItem.id
					} else {
						id = `${index}-${ref.child(collection).push().key}`
					}
					if (Object.keys(newItem).length === 0) {
						acc[id] = true
					} else {
						acc[id] = newItem
					}
					return acc
				}, {})
				data[collection] = items
			}
		})
	}

	delete data.id
	
	return ref.update(data).then(() => {
		return get(key, path, subCollections)
	})
}

/*
Params format
id [String] id of the object
path [String] Path of the database
doc [Object] Data of the object
subCollection [Array] 

Response format
doc: [Object] Data of the object
*/
export const set = (key, path, doc, subCollections = []) => {
	const refPath = `${path}/${key}`
	const ref = firebase.database().ref(refPath)
	let data = Object.assign({}, doc)

	if (subCollections.length > 0) {
		subCollections.forEach(collection => {
			if (data[collection]) {
				const items = data[collection].reduce((acc, item, index) => {
					const newItem = Object.assign({}, item)
					let id
					if (newItem.id) {
						id = newItem.id
						delete newItem.id
					} else {
						id = `${index}-${ref.child(collection).push().key}`
					}
					if (Object.keys(newItem).length === 0) {
						acc[id] = true
					} else {
						acc[id] = newItem
					}
					return acc
				}, {})
				data[collection] = items
			}
		})
	}

	delete data.id

	return ref.set(data).then(() => {
		return get(key, path, subCollections)
	})
}