import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import MainRoute from './MainRoute'

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
])

//Check if the user is authenticate and if it's authorize to access to this location
//Check also if we ask to reset the password
const MainPrivateRoute = ({ component: Component, render, children, user, ...props }) => {
	return (
		<MainRoute {...props} render={props => {
				//If the user is not authenticate or not authorize, He's redirect to the login page
				if (user && user.isAnonymous === false) {
					if (user.resetPassword) {
						if (props.match.path !== '/update-password') {
							return (<Redirect to={{
								pathname: '/update-password'
							}} />)
						}						
					}
					if (user.hasPerm()) {
						//Handle the 3 ways to render sommething with <Route>
						if (Component) {
							return (<Component {...props} />)
						}
						if (render) {
							return render(props)
						}
						if (children) {
							return children(props)
						}
					} else {
						return (<Redirect to={{
							pathname: '/notMatch'
						}} />)
					}
				} else {
					return (<Redirect to={{
						pathname: '/login',
						state: { from: props.location }
					}} />) 
				}
			}
		} />
	)
}

MainPrivateRoute.propTypes = {
	component: componentPropType,
	render: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node
	]),
	user: PropTypes.object,
}

//Get user information from the store
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
} 

export default connect(mapStateToProps)(MainPrivateRoute)