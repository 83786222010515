import React from 'react'
import PropTypes from 'prop-types'

import Wrapper from './Wrapper'

import { Header, Footer, GdprBanner } from '../components'

const Layout = props => (
	<Wrapper>
		{
			props.header === true && (
				<Header navLeftComponent={props.navLeftComponent} />
			)
		}
		<GdprBanner />
		<div className="">
			{props.children}
		</div>
		{
			props.footer === true && (
				<Footer />
			)
		}
		
	</Wrapper>
)

Layout.defaultProps = {
	footer: true,
	hideBanner: false,
	header: true
}

Layout.propTypes = {
	footer: PropTypes.bool,
	hideBanner: PropTypes.bool,
	header: PropTypes.bool	
}

export default Layout