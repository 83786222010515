import React from 'react'

import { connect } from 'react-redux'

import { getCart, getCustomer, getDeliveryAmount, getDiscount } from '../'
import { get, update, create, on } from '../../core/clients/restClient'

const connectCheckout = Checkout => {
	class ConnectCheckout extends React.Component {
		state = {
			isLoading: true
		}

		getStore = storeId => {
			return get(storeId, '/stores', ["openHours"])
		}

		getDeliveryAmount = (type, origin, destination) => {
			return getDeliveryAmount(type, origin, destination)
		}

		getDiscount = code => {
			return getDiscount(code)
		}

		getDefaultAddress = () => {
			//Get default address
			let defaultAddress = this.state.customer.addresses.filter(address => address.defaultAddress === true)[0]
			return defaultAddress ? defaultAddress : this.state.customer.addresses[0]
		}

		addAddress = data => {
			return create(`/customers/${this.props.customerId}/addresses`, data).then(address => {
				return getCustomer(this.props.customerId).then(customer => {
					return new Promise((resolve, reject) => {
						this.setState({customer: customer}, () => {
							resolve(address)
						})
					})
				})
			})
		}

		updateAddress = (id, data) => {
			return update(id, `/customers/${this.props.customerId}/addresses`, data).then(address => {
				return getCustomer(this.props.customerId).then(customer => {
					return new Promise((resolve, reject) => {
						this.setState({customer: customer}, () => {
							resolve(address)
						})
					})
				})
			})
		}

		createSource = token => {
			return create(`/customers/${this.props.customerId}/sources`, { token }).then(source => {
				//Waitin Stripe source creation
				return new Promise((resolve, reject) => {
					this.sourceRef = on(`/customers/${this.props.customerId}/sources/${source.id}`, source => {
						
						//return the source if the source is chargeable
						if (source.status === "chargeable") {
							return getCustomer(this.props.customerId).then(customer => {
								this.setState({customer: customer}, () => {
									this.sourceRef.off()
									resolve(source)
								})
							}) 
						} 

						if (source.error) {
							return getCustomer(this.props.customerId).then(customer => {
								this.setState({customer: customer}, () => {
									this.sourceRef.off()
									reject(source.error)
								})
							})
						}
					})
				})
			})
		}

		createOrder = values => {
			return create('/orders', values, ["items", "charges", "deliveries", "discounts"]).then(order => {
				return  update(this.props.userId, '/users', {orderPending: order.id}).then(() => {
					return order
				})
			})
		}

		createCharge = order => {
			return create('/charges', {orderId: order.id, amount: parseInt(order.total*100, 10)}).then(charge => {
				const data = {
					source: order.paymentMethod,
					returnUrl: `${window.location.protocol}//${window.location.host}/payments/${charge.id}`
				}

				return update(charge.id, `/charges`, data).then(charge => charge)

			}) 
		}

		componentDidMount = () => {
			this.setState({isLoading: true})

			const cartPromise = getCart(this.props.cartId)
			const customerPromise = getCustomer(this.props.customerId)

			Promise.all([cartPromise, customerPromise]).then(result => {
				const cart = result[0]
				const customer = result[1]

				//Get default address
				let defaultAddress = customer.addresses.filter(address => address.defaultAddress === true)[0]
				defaultAddress = defaultAddress ? defaultAddress : customer.addresses[0]

				//Get default payment method
				const defaultPaymentMethod = customer.sources ? customer.sources.filter(source => source.status === "chargeable")[0] : null

				//Get period
				const startDate = cart.items[0].startDate
				const endDate = cart.items[0].endDate

				const initialValues = {
					items: cart.items,
					deliveryToGo: {
						type: 'pickup',
						code: 'togo',
						storeId: cart.storeId,
						amount: 0,
						origin: null,
						destination: null,
						timeRange: null,
						dateTime: startDate
					},
					deliveryBack: {
						type: 'pickup',
						code: 'back',
						storeId: cart.storeId,
						amount: 0,
						origin: null,
						destination: null,
						timeRange: null,
						dateTime: endDate
					},
					billingAddress: defaultAddress,
					total: cart.total,
					paymentMethod: defaultPaymentMethod ? defaultPaymentMethod.id : null,
					customerId: this.props.customerId,
					storeId: cart.storeId
				}

				this.setState({isLoading: false, initialValues: initialValues, customer: customer})
			})
		}

		render = () => <Checkout
							initialValues={this.state.initialValues}
							customer={this.state.customer}
							isLoading={this.state.isLoading}
							step={parseInt(this.props.match.params.step, 10)}
							history={this.props.history}
							getStore={this.getStore}
							getDeliveryAmount={this.getDeliveryAmount}
							getDiscount={this.getDiscount}
							updateAddress={this.updateAddress}
							addAddress={this.addAddress}
							getDefaultAddress={this.getDefaultAddress}
							createSource={this.createSource}
							createOrder={this.createOrder}
							createCharge={this.createCharge} 
							{...this.props} />
	}

	const mapStateToProps = state => {
		return {
			cartId: state.auth.user.cartId,
			customerId: state.auth.user.customerId,
			userId: state.auth.user.uid
		}
	}

	return  connect(mapStateToProps)(ConnectCheckout)

}

export default connectCheckout