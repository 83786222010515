import React from 'react'
import PropTypes from 'prop-types'

//AuthClient
import { onCurrentUser, createAnonymousUser } from './clients/authClient'

//Router
import {
	Router,
	Switch
} from 'react-router-dom'

import createBrowserHistory from 'history/createBrowserHistory'


//Redux
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import appReducders from './reducers'
import { addCurrenUser } from './actions'
import { ScrollTop } from './utils'

import firebase from 'firebase/app'

import MainRoute from './MainRoute'

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
])


class Main extends React.Component {
	state = {
		initializing: true
	}

	componentWillMount = () => {
		//Create the store
		if (this.props.middlewares) {
			this.store = createStore(appReducders, 
				applyMiddleware(...this.props.middlewares)
			)
		} else {
			this.store = createStore(appReducders)
		}
		

		//Create history
		this.history = createBrowserHistory()

		//Init firebase
		firebase.initializeApp(this.props.settings.firebase)

		//Handle session of the user
		//TODO: improve that initializing should done even if there is no user
		onCurrentUser(user => {
			if (this.props.onCurrentUser) {
				this.props.onCurrentUser(user)	
			}
			if (user) {
				this.store.dispatch(addCurrenUser(user))
				this.setState({initializing: false})
			} else {
				createAnonymousUser()
			}
		})
	}

	componentDidMount = () => {
		if (this.props.handleHistory) {
			this.props.handleHistory(this.history)
			this.history.listen((location, action) => this.props.handleHistory(this.history, this.store))
		}
	}

	render = () => {
		//Get all pages required
		const { loading, loginPage, forgotPasswordPage, resetPasswordPage, signupPage, errorPage, notMatchPage, children } = this.props

		return (
			this.state.initializing ? (React.createElement(loading)) : (
				<Provider store={this.store}>
					<Router history={this.history}>
						<ScrollTop>
							<Switch>
								<MainRoute exact path="/login" component={loginPage} />
								{forgotPasswordPage && <MainRoute exact path="/forgot-password" component={forgotPasswordPage} />}
								{resetPasswordPage && <MainRoute exact path="/reset-password" component={resetPasswordPage} />}
								<MainRoute exact path="/signup" component={signupPage} />
								<MainRoute exact path="/error" component={errorPage} />
								{children}
								<MainRoute component={notMatchPage} />
							</Switch>
						</ScrollTop>
					</Router>
				</Provider>
			)
		)
	}
}

Main.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node
	]).isRequired,
	loginPage: componentPropType.isRequired,
	forgotPasswordPage: componentPropType,
	resetPasswordPage: componentPropType,
	signupPage: componentPropType.isRequired,
	notMatchPage: componentPropType.isRequired,
	errorPage: componentPropType.isRequired,
	loading: componentPropType.isRequired,
	settings: PropTypes.object.isRequired,
	handleHistory: PropTypes.func,
	middleware: PropTypes.array,
	onCurrentUser: PropTypes.func
}

export default Main