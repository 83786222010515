import React from 'react'
import PropTypes from 'prop-types'
import { Field as ReduxField } from 'redux-form'


const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
])

// Exemple of renderTextField function
// const renderTextField = ({ input, label, meta: { touched, error }, required, ...custom }) => (
//   <div className={"form-group" + (touched && error ? " has-error" : "")}>
//   	{label && <label className="control-label">{label} {required && (<span className="required">*</span>)}</label>}
//   	<input 
//   		className="form-control"
//       type="text"
//   		{...input}
//   		{...custom} />
//   		<span className="help-block">{touched && error}</span>
//   </div>
//  )

const requiredValidation = value => (value ? undefined : 'Le champ est obligatoire')

const Field = ({required, validate, component, ...props}) => {
  const v = validate ? validate : []
  
  if (required) {
    v.push(requiredValidation)
  }
  
  return (
    <ReduxField component={component} required={required} {...props} validate={v} />
  )
}

Field.propTypes = {
	required: PropTypes.bool,
	validate: PropTypes.array,
	component: componentPropType.isRequired
}

export default Field