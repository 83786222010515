import React from 'react'

import { reduxForm, hasSubmitSucceeded, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { update } from '../../core/clients/restClient'
import { connectAuth } from '../../core/connectors'
import { Form, GdprBanner, Loading } from '../components'
import { TextField } from '../fields'

import logoWhite from '../assets/img/logo_choose_white.svg'


const headerStyle = {
    position: "relative",
    padding: "2rem 1.25rem 0.5rem",
    width: "100%",
    backgroundColor: "#00a483",
    color: "#fff",
    textAlign: "center",
    backgroundSize: "cover"
}


let ResetPasswordForm = props => (
	<Form {...props}>
		<fieldset>
			<TextField label="Nouveau mot de passe" type="password" name="password" required />
		</fieldset>
	</Form>
)

ResetPasswordForm = reduxForm({
	form: 'resetPasswordForm'
})(ResetPasswordForm)



class UpdatePassword extends React.Component {
	state = {
		isLoading: true,
		submitSucceeded: false
	}

	updateUser = () => {
		return update(this.props.user.uid, '/users', {resetPassword: false})
	}

	componentDidMount = () => {
		console.log(this.props)
		this.setState({
			isLoading: false
		})
	}

	handleSubmit = values => {
		return this.props.updatePassword(values.password).then(() => {
			return this.updateUser()
		}).catch(error => {
			throw new SubmissionError({_error: error.message})
		})
	}

	skipStep = e => {
		e.preventDefault()

		this.updateUser().then(user => {
			this.props.history.push('/')
		})
	}

	componentDidUpdate = prevProps => {
		//Reset password succeeded
		if (prevProps.submitSucceeded === false && this.props.submitSucceeded === true) {
			this.setState({
				submitSucceeded: true
			})
		}
	}

	render = () => {
		this.props.setTitle("Enregistrement définitif du mot de passe")
		this.props.setDescription()

		return (
			<div>
				<GdprBanner />
				<header id="auth-header" style={headerStyle}>
					<h1 style={{color: "#fff"}}>
						<img src={logoWhite} alt="logo" style={{width: "300px"}}/>
					</h1>
				</header>
				{
					this.state.isLoading ? <Loading /> : (
						<div className="container mt-40 mb-40">
							<div className="row">
								<div className="col-sm-offset-3 col-sm-6 col-xs-12 mb-20">
									<h4>Enregistrement du mot de passe</h4>
									<div className="login-form" style={{padding: "10px", border: "1px solid #e5e5e5", width: "100%"}}>
										{
											this.state.submitSucceeded ? (
												<div className="alert alert-success" style={{marginBottom: "0px"}}>
													<p>Merci ! Votre nouveau mot de passe a bien été enregistré.</p>
													<p className="text-center" style={{"marginTop": "40px"}}><Link className="btn btn-lg btn-color" to="/"><span>Accéder à la page d'accueil</span></Link></p>
												</div>
											) : (
												<React.Fragment>
													<p>Bonjour,</p>
													<p>Nous vous remercions d'avoir souscrit à l'offre Choose et nous sommes ravis de vous compter désormais parmi nos clients.</p>
													<p>Veuillez renseigner un mot de passe définitif, celui que vous avez reçu par mail étant temporaire</p>
													<ResetPasswordForm onSubmit={this.handleSubmit} />
												</React.Fragment>
											)
										}
									</div>
									<div className="mt-20 mb-20" style={{textAlign: "center"}}>
										<a href="#skip-step" onClick={this.skipStep} style={{color: "#7f7f7f"}}>Passer cet étape</a>
									</div>
								</div>
							</div>
						</div>
					)
				}	
			</div>
		)
	}
}

const mapStateToProps = state => ({
	submitSucceeded: hasSubmitSucceeded('resetPasswordForm')(state)
})


export default connectAuth(connect(mapStateToProps)(UpdatePassword))