import React from 'react'
import PropTypes from 'prop-types'


class Modal extends React.Component {
	render = () => {
		this.props.show ? window.$(this.refs.modal).modal("show") : window.$(this.refs.modal).modal("hide")
		return (
			<div ref="modal" className="modal fade" role="dialog">
				{
					this.props.show === true && 
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							{this.props.children}
						</div>
					</div>
				}
			</div>
		)
	}
}

Modal.defaultProps =  {
	show: false
}

Modal.propTypes = {
	show: PropTypes.bool.isRequired,
}

class ModalHeader extends React.Component {
	render = () => {
		const {toggle, title, showCloseButton} = this.props
		return (
			<div className="modal-header">
		    	{showCloseButton && <button type="button" onClick={toggle} className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>}
		    	<h4 className="modal-title">{title}</h4>
		  	</div>
		)
	}
}

ModalHeader.defaultProps = {
	showCloseButton: true
}

const ModalBody = ({ children }) => (
	<div className="modal-body">
		{children}
	</div>
)

const ModalFooter = ({ children }) => (
	<div className="modal-footer">
		{children}
	</div>
)

export { Modal, ModalHeader, ModalBody, ModalFooter }