import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { isAvaillableChoose, addToCart } from '../'


const connectAddRentItemToCart = AddToCart => {
	class ConnectAddRentItemToCart extends React.Component {
		state = {
			isAvaillable: null,
			inProgress: false
		}

		//Check the price and the availlablelity of the rentItem
		_isAvaillable = (startDate, endDate, quantity, type) => {
			this.setState({inProgress: true})
			return isAvaillableChoose(this.props.item.objectID, startDate, endDate, quantity).then(response => {
				this.setState({ inProgress: false})
				return response.data
			}).catch(error => {
				this.setState({ error: error, inProgress: false })
			})
		}

		addItem = item => {
			return this._isAvaillable(item.startDate, item.endDate, item.quantity, "pack").then(data => {
				if (data.amount) {
					item.amount = data.amount
				}
				item.totalAmount = data.totalAmount
				return addToCart(this.props.user.cartId, this.props.user.uid, item, this.props.user.store.id)
			})
		}


		componentWillReceiveProps = nextProps => {
			const nextValues = nextProps.values

			//Update the amount when values of the AddToCart form changed
			if (nextValues.quantity && nextValues.period) {

				let quantity = nextValues.quantity
				const startDate = nextValues.period.startDate
				const endDate = nextValues.period.endDate


				if (typeof quantity === "string") {
					quantity = parseInt(quantity, 10)
				}

				this._isAvaillable(startDate, endDate, quantity, "pack").then(data => {
					this.setState({isAvaillable: data})
				})
			}
		}

		render = () => {
			const totalAmount = this.state.isAvaillable ? this.state.isAvaillable.totalAmount : null
			const initialValues = {
				objectId: this.props.item.objectID,
				name: this.props.item.name,
				picture: this.props.item.pictures[0].thumb_url,
				slug: `/${this.props.item.category.slug}/location-${this.props.item.objectID}`,
				quantity: 1,
				category: this.props.item.category.name
			}
			return <AddToCart initialValues={initialValues} addItem={this.addItem} totalAmount={totalAmount} inProgress={this.state.inProgress} user={this.props.user} {...this.props} />
		}
	}

	const mapStateToProps = state => ({
		user: state.auth.user,
		values: getFormValues('addToCartForm')(state)
	})

	ConnectAddRentItemToCart.propTypes = {
		item: PropTypes.object
	}

	return connect(mapStateToProps)(ConnectAddRentItemToCart)
}

export default connectAddRentItemToCart